import React, { useState } from 'react';
import {
  Checkbox,
  Container,
  Grid,
  FormControlLabel,
} from '@mui/material';
import { Button, Typography, TextField } from 'shared/components';
import { Link } from '../../shared';
import { ErrorDisplay, FormProps, useInput, useForm } from '../../shared/forms';
import { LoginForm } from '../../state/users';
import LogoImage from '../../assets/BT-Recruit-Horizontal-Dark.png';

export interface SignInFormFields {
  username: string;
  password: string;
  staySignedIn: boolean;
}

export const SignInForm: React.FC<FormProps<LoginForm>> = ({
  error,
  onSubmit,
  onResetFeedback,
}) => {
  const email = useInput<string>('');
  const password = useInput<string>('');
  const staySignedIn = useInput<string>('false');
  const [chkStaySignedIn, setChkStaySignedIn] = useState(false);

  const form = useForm(
    () => {
      if (!form.valid) return;

      onResetFeedback();
      onSubmit({
        email: email.value,
        password: password.value,
        staySignedIn: staySignedIn.value === 'true',
      });
    },
    email,
    password,
    staySignedIn
  );

  return (
    <Container fixed maxWidth="xs">
      <form {...form.bind}>
        <Grid container direction="column" rowSpacing={4}>
          <Grid item>
            <div>
              <img src={LogoImage} style={{width: '100%'}} alt="BoldTrail Recruit Logo" />
            </div>
          </Grid>
          <Grid item>
            <Typography variant="subtitle" align="center">
              Welcome back! Please login to your account.
            </Typography>
          </Grid>
          <Grid item>
            <ErrorDisplay error={error} />
          </Grid>
          <Grid item>
            <TextField
              name="username"
              {...email.bind}
              label="Email"
              autoComplete="email"
              type="email"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              name="password"
              label="Password"
              {...password.bind}
              autoComplete="password"
              type="password"
              fullWidth
            />
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name="staySignedIn"
                    checked={chkStaySignedIn}
                    onChange={evt => {
                      staySignedIn.setValue(evt.target.checked.toString());
                      staySignedIn.setTouched(true);
                      setChkStaySignedIn(evt.target.checked);
                    }}
                    color="primary"
                  />
                }
                label="Remember Me"
              />
            </Grid>
            <Grid item>
              <Link to="/account/forgotpassword">Forgot Password</Link>
            </Grid>
          </Grid>
          <Grid item container style={{ marginBottom: '0.5rem' }}>
            <Grid item xs={6}>
              <Button type="submit" fullWidth>
                Login
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Typography align="center">
              By signing in you agree to BoldTrail BackOffice's <br />
              <a href="https://www.insiderealestate.com/terms-of-service" target="_blank" rel="noreferrer">Terms of Use and Privacy Policy</a><br/>
              (version: Jun 10, 2024)
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
