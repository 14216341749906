import { Mls } from '../../api';
import { addDays, addYears, format } from 'date-fns';
import { QuickProductionFilter } from '../../search/components/filter-production-range';
import { QuickFilter } from '../../search';
import { SalesChange } from '../../api/mls';
import { HotListType } from '../../search/components/filter-hotlist';

export type SortFieldType = Mls.SearchSortField;
export const SortField = Mls.SearchSortField;
export type SortOrderType = Mls.SortOrder;
export const SortOrder = Mls.SortOrder;

export interface SearchForm {
  startDate?: string;
  endDate?: string;

  feed?: string[];
  board?: string[];
  broker?: string[];
  branch?: string[];

  county?: string[];
  area?: string[];
  city?: string[];
  postalCode?: string[];
  schoolDistrict?: string[];
  propertyType?: string[];

  newlyLicensedStartDate?: string;
  newlyLicensedEndDate?: string;

  newlyMovedStartDate?: string;
  newlyMovedEndDate?: string;

  agentSearchTerm?: string;

  productionStartValue?: number;
  productionEndValue?: number;

  productionFilterValue?: QuickProductionFilter;

  agentHistoryFilterValue?: string;
  agentHistoryDateFilterValue?: QuickFilter;

  hasActiveOrPendingListings?: boolean;
  daysSinceLastClose?: number;
  percentageChange?: number;
  salesChange?: SalesChange;

  hotListType?: HotListType;
}

const today = new Date();

export const dateFormat = "yyyy-MM-dd'T'00:00:00";

export const emptyForm: SearchForm = {
  startDate: format(addDays(addYears(today, -1), 1), dateFormat),
  endDate: format(today, dateFormat),
  feed: [],
  board: [],
  broker: [],
  branch: [],
  county: [],
  area: [],
  city: [],
  schoolDistrict: [],
  propertyType: [],
  newlyLicensedStartDate: undefined,
  newlyLicensedEndDate: undefined,
  newlyMovedStartDate: undefined,
  newlyMovedEndDate: undefined,
  agentSearchTerm: undefined,
  productionStartValue: 0,
  productionEndValue: 0,
  hasActiveOrPendingListings: undefined,
  daysSinceLastClose: 0,
  percentageChange: 0,
  salesChange: undefined,
  hotListType: undefined,
};

export enum ResultType {
  Agent = 'Agent',
  Company = 'Company',
  Office = 'Office',
}

export type FilterOptions = Mls.GetSearchOptionsResponse;

export interface SearchResult {
  rank: number;
  listSideVolume: number;
  listSideCount: number;
  sellSideVolume: number;
  sellSideCount: number;
  totalVolume: number;
  totalCount: number;
  daysOnMarket: number;
  percentTotal: number;
}

export type SearchResultType<T> = T & SearchResult;

export type AgentSearchResult = SearchResultType<Mls.AgentSearchModel>;

export type OfficeSearchResult = SearchResultType<Mls.OfficeSearchModel>;

export type CompanySearchResult = SearchResultType<Mls.CompanySearchModel>;

export function getOfficeDisplayName({
  officeNumber,
  officeName,
}: {
  officeNumber: string;
  officeName: string;
}) {
  return `${officeName} [${officeNumber}]`;
}
