import { from } from 'rxjs';
import { Zsg } from '../../api';
import { map } from 'rxjs/operators';
import {
  AnswersStore,
  SubmissionsStore,
  SurveysStore,
  QuestionTypesStore,
  answersStore,
  submissionsStore,
  surveysStore,
  questionTypesStore,
  QuestionsStore,
  questionsStore,
  DefaultQuestionsStore,
  defaultQuestionsStore,
  SurveyEmailTemplateStore,
  surveyEmailTemplateStore,
} from './surveys.store';
import { applyTransaction, setLoading } from '@datorama/akita';
import { AnswerModel, DefaultQuestionModel } from '../../api/app';
import { CreateSurvey, UpdateSurvey } from './surveys.models';
import { AgentModel } from '../../api/mls';
import { dispatchForm } from '../forms';
import { SurveyForms } from './survey.forms';
import { CompanyStore, companyStore } from 'state/offices/company.store';

export class SurveysService {
  constructor(
    private readonly surveyApi: Zsg.SurveyApi,
    private readonly surveyStore: SurveysStore,
    private readonly questionsStore: QuestionsStore,
    private readonly submissionsStore: SubmissionsStore,
    private readonly answersStore: AnswersStore,
    private readonly questionTypesStore: QuestionTypesStore,
    private readonly defaultQuestionsStore: DefaultQuestionsStore,
    private readonly companyStore: CompanyStore,
    private readonly surveyEmailTemplateStore: SurveyEmailTemplateStore,
  ) {}

  createSurvey(request: CreateSurvey) {
    from(this.surveyApi.surveyPost(request))
      .pipe(
        setLoading(this.surveyStore),
        map(response => response.data.data || {}),
        dispatchForm(SurveyForms.Create)
      )
      .subscribe(survey => {
        this.surveyStore.upsert(survey.id, survey);
        this.surveyStore.setActive(survey.id);
      });
  }

  updateSurvey(request: UpdateSurvey) {
    from(this.surveyApi.surveyPut(request))
      .pipe(
        setLoading(this.surveyStore),
        map(response => response.data.data || {}),
        dispatchForm(SurveyForms.Update)
      )
      .subscribe(survey => {
        this.surveyStore.update(survey.id, survey);
      });
  }

  updateSurveyActive(id: number) {
    from(this.surveyApi.surveyUpdatestatusIdPut(id))
      .pipe(
        setLoading(this.surveyStore),
        map(response => response.data.data || {})
      )
      .subscribe(survey => {
        this.surveyStore.update(survey.id, survey);
        this.surveyStore.setActive(survey.id);
      });
  }

  getQuestionsBySurveyId(id: number) {
    const request = this.surveyApi.surveyQuestionsIdGet(id).catch(e => {
      if (e.response.status === 400) {
        this.questionsStore.setError(e.response.data);
      } else {
        this.questionsStore.setError('An error has occured!');
      }
      return Promise.reject(e);
    });

    from(request)
      .pipe(
        setLoading(this.questionsStore),
        map(response => response.data.data || [])
      )
      .subscribe(questions => {
        this.questionsStore.set([...questions]);
      });
  }

  getQuestionsBySubmissionLink(link: string) {
    const request = this.surveyApi.surveySubmissionQuestionsSubmissionLinkGet(link).catch(e => {
      if (e.response.status === 400) {
        this.questionsStore.setError(e.response.data);
      } else {
        this.questionsStore.setError('An error has occured!');
      }
      return Promise.reject(e);
    });

    from(request)
      .pipe(
        setLoading(this.questionsStore),
        map(response => response.data.data || [])
      )
      .subscribe(questions => {
        this.questionsStore.set([...questions]);
      });
  }

  getSurveyById(id: number) {
    const request = this.surveyApi.surveyIdGet(id).catch(e => {
      if (e.response.status === 400) {
        this.surveyStore.setError(e.response.data);
      } else {
        this.surveyStore.setError('An error has occured!');
      }
      return Promise.reject(e);
    });

    from(request)
      .pipe(
        setLoading(this.surveyStore),
        map(response => response.data.data || {})
      )
      .subscribe(survey =>
        applyTransaction(() => {
          this.surveyStore.upsert(survey.id, survey);
          this.surveyStore.setActive(survey.id);
        })
      );
  }

  getActiveSurvey() {
    from(this.surveyApi.surveyActiveGet())
      .pipe(
        setLoading(this.surveyStore),
        map(response => response.data.data),
      )
      .subscribe(survey => {
        if(survey) {
          this.surveyStore.upsert(survey.id, survey);
          this.surveyStore.setActive(survey.id);
        }
      }
      );
  }

  getSurveysByManagerId(id: string) {
    from(this.surveyApi.surveyManagerIdGet(id))
      .pipe(
        setLoading(this.surveyStore),
        map(response => response.data.data)
      )
      .subscribe(surveys =>
        applyTransaction(() => {
          this.surveyStore.set([...surveys]);
        })
      );
  }

  sendSurvey(listingKey: string, member: AgentModel, coMember: AgentModel, address?: string, closeDate?: string) {
    const agent = {
      key: member.memberKey,
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
      mobilePhone: member.mobilePhone,
      officeKey: member.officeKey,
      office: member.officeName
    };

    const coAgent = {
      key: coMember.memberKey,
      firstName: coMember.firstName,
      lastName: coMember.lastName,
      email: coMember.email,
      mobilePhone: coMember.mobilePhone,
      officeKey: coMember.officeKey,
      office: coMember.officeName
    }

    const request = this.surveyApi
      .surveySendPost({ listingKey, member: agent, coMember: coAgent, address, closeDate })
      .catch(e => {
        if (e.response.status === 400) {
          this.surveyStore.setError(new Error(e.response.data));
        } else {
          this.surveyStore.setError(new Error('An error has occured!'));
        }
        return Promise.reject(e);
      });

    from(request)
      .pipe(
        setLoading(this.submissionsStore),
        map(response => response.data.data)
      )
      .subscribe(submission =>
        applyTransaction(() => {
          applyTransaction(() => {
            this.submissionsStore.upsert(submission?.id, submission);
          });
        })
      );
  }

  submitAnswers(link: string, answers: Array<AnswerModel>) {
    from(this.surveyApi.surveyAnswerSubmissionLinkPost(link, answers))
      .pipe(
        setLoading(this.submissionsStore),
        map(response => response.data.data || {})
      )
      .subscribe(submission =>
        applyTransaction(() => {
          applyTransaction(() => {
            this.submissionsStore.upsert(submission?.surveyId, submission);
            this.submissionsStore.setActive(submission?.id);
          });
        })
      );
  }

  getSubmissionById(id: number) {
    from(this.surveyApi.surveySubmissionSubmissionIdGet(id))
      .pipe(
        setLoading(this.submissionsStore),
        map(response => response.data.data || {})
      )
      .subscribe(submission =>
        applyTransaction(() => {
          this.submissionsStore.upsert(submission.id, submission);
          this.submissionsStore.setActive(submission?.id);
        })
      );
  }

  GetSubmissionsBySurveyId(id: number) {
    from(this.surveyApi.surveySubmissionsSurveyIdGet(id))
      .pipe(
        setLoading(this.submissionsStore),
        map(response => response.data.data)
      )
      .subscribe(submissions =>
        applyTransaction(() => {
          this.submissionsStore.set([...submissions]);
        })
      );
  }

  GetSubmissionsByCompanyId(id: number) {
    from(this.surveyApi.surveyCompanySubmissionsCompanyIdGet(id))
    .pipe(
      setLoading(this.submissionsStore),
      map(response => response.data.data)
    )
    .subscribe(submissions =>
      applyTransaction(() => {
        this.submissionsStore.set([...submissions]);
      })
    );
  }

  getAnswersBySubmissionId(id: number) {
    from(this.surveyApi.surveyAnswersIdGet(id))
      .pipe(
        setLoading(this.answersStore),
        map(response => response.data.data)
      )
      .subscribe(answers =>
        applyTransaction(() => {
          this.answersStore.set([...answers]);
        })
      );
  }

  getQuestionTypes() {
    from(this.surveyApi.surveyQuestiontypesGet())
      .pipe(
        setLoading(this.questionTypesStore),
        map(response => response.data.data)
      )
      .subscribe(questionTypes => {
        applyTransaction(() => {
          this.questionTypesStore.set([...questionTypes]);
        });
      });
  }

  createDefaultQuestions(questions: Array<DefaultQuestionModel>) {
    from(this.surveyApi.surveyDefaultsPut(questions))
      .pipe(
        setLoading(this.defaultQuestionsStore),
        map(response => response.data.data)
      )
      .subscribe(questions => {
        applyTransaction(() => {
          this.defaultQuestionsStore.set([...questions]);
        });
      });
  }

  getDefaultQuestions() {
    const request = this.surveyApi.surveyDefaultsGet().catch(e => {
      this.defaultQuestionsStore.setError(e);
      throw e;
    });

    from(request)
      .pipe(
        setLoading(this.defaultQuestionsStore),
        map(response => response.data.data)
      )
      .subscribe(questions => {
        applyTransaction(() => {
          this.defaultQuestionsStore.set([...questions]);
          this.defaultQuestionsStore.setLoading(false);
        });
      });
  }

  getCompanyBySurvey(link: string) {
    from(this.surveyApi.surveyCompanySubmissionLinkGet(link))
      .pipe(
        setLoading(this.companyStore),
        map(response => response.data.data),
      )
      .subscribe(company =>
        applyTransaction(() => {
          this.companyStore.upsert(company.id, company);
          this.companyStore.setActive(company.id);
        })
      )
  }

  getCompanySurveyEmailTemplate() {
    from(this.surveyApi.surveyCompanyEmailtemplateGet())
      .pipe(
        map(response => response.data.template)
      )
      .subscribe(template => {
        this.surveyEmailTemplateStore.remove();
        this.surveyEmailTemplateStore.upsert(template.subject, template);
      })
  }
}

export const surveysService = new SurveysService(
  new Zsg.SurveyApi(),
  surveysStore,
  questionsStore,
  submissionsStore,
  answersStore,
  questionTypesStore,
  defaultQuestionsStore,
  companyStore,
  surveyEmailTemplateStore,
);
