import { useEffect } from "react";
import { useObservable } from "shared";
import {enterpriseAdoptionReportQuery, enterpriseAdoptionReportService, GetAdoptionStatsRequest } from "state/reports/enterprise-adoption";

export function useAdoptionStats(params: GetAdoptionStatsRequest) {
  useEffect(() => {
    enterpriseAdoptionReportService.getAdoptionStats(params);
  }, [params]);

  return {
    data: useObservable(enterpriseAdoptionReportQuery.data),
    count: useObservable(enterpriseAdoptionReportQuery.count),
    loading: useObservable(enterpriseAdoptionReportQuery.loading)
  };
}
