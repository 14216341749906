import { useHistory, useLocation } from 'react-router-dom';

import { PageHeader, LoadingFade, icons } from '../../shared';

import { AgentSearch, ReportSelector } from '../components';
import { useSearchOptions } from '../../state/search/search.hooks';
import { locationStore } from 'state';

export const SearchPage = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    data: filterOptions,
    isLoading,
    isRefetching,
    isSuccess,
    error,
    reportType,
    setReportType,
    resultType,
    setResultType,
    searchForm,
    setSearchForm
  } = useSearchOptions();

  const submit = () => {
    locationStore.reset();
    locationStore.update({previousPath: location.pathname});
    history.push('/search/results', location.state);
  };

  return (
    <>
      <PageHeader title="RECRUIT SEARCH" icon={icons.searchGlass} />
      <LoadingFade
        isContentLoading={isLoading}
        content={() => (
          <>
            <ReportSelector
              reportType={reportType}
              setReportType={setReportType}
              resultType={resultType}
              setResultType={setResultType}
            />

            <AgentSearch
              searchForm={searchForm}
              setForm={form => setSearchForm(form, undefined, true)}
              activeFilterOptions={filterOptions}
              reportType={reportType}
              onSubmit={submit}
              onResetFeedback={() => {}}
              error={error as any}
              success={isSuccess}
              pending={isRefetching}
            />
          </>
        )}
      />
    </>
  );
};
