import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Search} from '@mui/icons-material';
import {Box, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import {GridPaginationModel} from '@mui/x-data-grid';
import {AgentSurveyAverage, GetAgentSurveyAveragesRequest, MemberFilter, OfficeFilter} from 'api/app';
import React, {useState} from 'react';
import {useRecruit} from 'recruiting';
import {DataTableColumn, Link, Typography, Button, DataTable, icons, useCurrentUserId, Datepicker} from 'shared';
import {RecruitAgent} from 'shared/components/recruit-agent';
import {useInput} from 'shared/forms';
import {agentSurveyReportService, initialPaginationState, OrderBy} from 'state/reports';
import {SubmissionScore} from 'surveys';
import {useAgentSurveyAverages} from '../hooks';
import {
  endDate as endDateValidator,
} from '../../../shared/forms/validators';
import {format} from 'date-fns';
import {dateFormat} from 'state/search';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { Picker } from '../../../search/components/filter-picker';


export const AgentSurveyList: React.FC = () => {

  const [paginationState, setPaginationState] = useState<GetAgentSurveyAveragesRequest>(initialPaginationState);
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const startDateInput = useInput(paginationState.startDate, {
    onChange: value => setPaginationState({
      ...paginationState,
      pageNum: 0,
      startDate: value
    })
  });
  const endDateInput = useInput(paginationState.endDate, {
    validators: [endDateValidator(paginationState.startDate)],
    onChange: value => setPaginationState({
      ...paginationState,
      pageNum: 0,
      endDate: value
    })
  });
  const officeFilter = useInput<string[] | undefined>(paginationState.officeKeys ?? [], {
    onChange: value => setPaginationState({
      ...paginationState,
      pageNum: 0,
      officeKeys: value
    }),
  });
  const agentFilter = useInput<string[] | undefined>(paginationState.memberKeys ?? [], {
    onChange: value => setPaginationState({
      ...paginationState,
      pageNum: 0,
      memberKeys: value
    }),
  });

  const userId = useCurrentUserId();
  const {isRecruit, isRecruited, toggleRecruit} = useRecruit(userId);
  const {agents, total, filters, loading} = useAgentSurveyAverages(paginationState);


  const onPaginationStateChange = (model: GridPaginationModel) => {
    setPaginationState({
      ...paginationState,
      pageNum: model.page,
      pageSize: model.pageSize,
    } as GetAgentSurveyAveragesRequest);
  }

  const onExport = async () => {
    setExportLoading(true);
    try {
      await agentSurveyReportService.exportAgentSurveyReport(paginationState);
    } catch (error) {
      console.log(error);
    }
    setExportLoading(false);
  };

  const columns: DataTableColumn<AgentSurveyAverage>[] = [
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">WATCHING</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'watching',
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: ({row}) => (
        <RecruitAgent
          memberKey={row.memberKey!}
          isRecruit={isRecruit(row.memberKey!)}
          isRecruited={isRecruited(row.memberKey!)}
          toggleRecruit={toggleRecruit}
        />
      )
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COOPERATING AGENT</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'memberName',
      sortable: false,
      filterable: false,
      renderCell: ({row}) => (
        <>
          <Link
            to={`/agents/${row.memberKey}`}
            color="primary"
            style={{
              marginRight: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(100% - 32px)",
            }}
          >
            {row.memberName}
          </Link>
          <a
            href={`https://www.google.com/search?q=${row.memberName} Realtor ${row.memberOffice}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Search color="primary"/>
          </a>
        </>
      ),
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COOPERATING BROKER</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'memberOffice',
      renderCell: ({row}) => row.memberOffice ?? 'N/A',
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.memberOffice ?? 'N/A',
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">EMAIL</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'memberEmail',
      renderCell: ({row}) => row.memberEmail ?? 'N/A',
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.memberEmail ?? 'N/A',
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">CELL</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'memberMobilePhone',
      renderCell: ({row}) => row.memberMobilePhone ?? 'N/A',
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.memberMobilePhone ?? 'N/A',
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COMPLETED SURVEYS</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'count',
      renderCell: ({row}) => row.count,
      flex: 0.5,
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.count,
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">SURVEY AVERAGE</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'average',
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.average,
      renderCell: ({row}) => (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SubmissionScore
            totalPoints={row.average || 0}
            possiblePoints={row.averagePossible || 0}
          />
        </Box>
      ),
    },
  ]

  return (
    <Card>
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container direction="column">
            <Grid item style={{marginBottom: '10px'}}>
              <Grid container justifyContent="space-between" alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Datepicker
                        format="MM/dd/yyyy"
                        label="Start Date"
                        value={startDateInput.value}
                        onChange={(date?: Date) => {
                          if (!date) {
                            startDateInput.setValue(undefined);
                            return;
                          }
                          startDateInput.setValue(format(date, dateFormat));
                        }}
                        error={startDateInput.errors.length > 0}
                        helperText={startDateInput.errors.length > 0 ? startDateInput.errors[0] : ''}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Datepicker
                        format="MM/dd/yyyy"
                        label="End Date"
                        value={endDateInput.value}
                        onChange={(date?: Date) => {
                          if (!date) {
                            endDateInput.setValue(undefined);
                            return;
                          }
                          endDateInput.setValue(format(date, dateFormat));
                        }}
                        error={endDateInput.errors.length > 0}
                        helperText={endDateInput.errors.length > 0 ? endDateInput.errors[0] : ''}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Picker
                        label="Office"
                        input={officeFilter}
                        options={filters.offices}
                        optionValue={(f: OfficeFilter) => f.officeKey!}
                        optionText={(f: OfficeFilter) => f.officeName!}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Picker
                        label="Agent"
                        input={agentFilter}
                        options={filters.agents}
                        optionValue={(f: MemberFilter) => f.memberKey!}
                        optionText={(f: MemberFilter) => f.memberName!}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                    <Grid item>
                      <FormControl variant="standard">
                        <InputLabel>Sort By</InputLabel>
                        <Select
                          value={paginationState.orderBy}
                          onChange={(event) =>
                            setPaginationState({
                              ...paginationState,
                              orderBy: event.target.value as OrderBy,
                            })
                          }
                        >
                          <MenuItem value={OrderBy.MemberName}>Cooperating Agent</MenuItem>
                          <MenuItem value={OrderBy.MemberEmail}>Email</MenuItem>
                          <MenuItem value={OrderBy.MemberOffice}>Cooperating Broker</MenuItem>
                          <MenuItem value={OrderBy.Average}>Survey Average</MenuItem>
                          <MenuItem value={OrderBy.Count}>Completed Surveys</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl variant="standard">
                        <InputLabel>Sort Order</InputLabel>
                        <Select
                          value={paginationState.isDescending}
                          onChange={(event) =>
                            setPaginationState({
                              ...paginationState,
                              isDescending: event.target.value === 'true',
                            })
                          }
                        >
                          <MenuItem value="false">Ascending</MenuItem>
                          <MenuItem value="true">Descending</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={onExport}
                        pending={exportLoading}
                        startIcon={<FontAwesomeIcon icon={icons.download} size="lg"/>}
                      >
                        Export Results
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <DataTable
                getRowId={(row) => row.memberKey! + '/' + row.officeKey!}
                rows={agents}
                rowCount={total}
                columns={columns}
                progressPending={loading}
                showPagination
                paginationMode="server"
                paginationModel={{
                  page: paginationState.pageNum,
                  pageSize: paginationState.pageSize
                } as GridPaginationModel}
                onPaginationModelChange={onPaginationStateChange}
                pageSizeOptions={[10, 25, 50, 100]}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </CardContent>
    </Card>
  );
}
