import React, { useMemo, useState } from 'react';
import {
  CardContent,
  Card,
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  LinearProgress,
  Box,
  Typography,
  RadioProps,
  styled,
} from '@mui/material';
import { FavoriteAgent } from 'shared/components/favorite-agent';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../shared';

import {
  NumberFormatters,
  Link,
  useObservable,
  SearchInput,
  BalanceReportHelper,
  CategorizedSalesVolumeModel,
  BalanceReport,
  BalanceReportMode,
  deferred,
} from '../../shared';
import { DataTable, DataTableColumn } from 'shared/components/data-table';
import { ValueComparison } from 'shared/components/value-comparison';
import { useInput } from '../../shared/forms';

import { Agent, AgentVolumeLookup } from '../../state';
import { AgentsQuery } from '../../state/mls/stores';
import { useLoadingIndicator } from '../../shared/indicators/isLoading';
import { LoadingFade } from '../../shared/components/loading-fade';
import { AgentVolumeSummaryTooltip } from '../../shared/components/agent-volume-summary-tooltip';
import themeService from 'theme/ThemeService';
import {makeStyles} from "@mui/styles";

interface Props {
  agents: Agent[];
  volume: AgentVolumeLookup;
  isFavorite: (memberKey: string) => boolean;
  toggleFavorite: (memberKey: string) => void;
}

const orderAndCategorize = (data: CategorizedSalesVolumeModel[], mode: BalanceReportMode) => {
  return BalanceReportHelper.orderAndCategorize(data, mode);
};

const palette = themeService.getPalette();

const useStyles = makeStyles({
  link: {
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: palette.teal,
    maxWidth: "calc(100% - 32px)",
  }
});

const AgentListView: React.FC<Props> = ({ agents, volume, isFavorite, toggleFavorite }) => {
  const styles = useStyles();
  const [dataGroupingMode, setDataGroupingMode] = useState<BalanceReportMode>('totalVolume');

  const orderedData = useMemo(
    () =>
      orderAndCategorize(
        agents
          .filter(agent => agent.active)
          .map(
            (agent, i) =>
              ({
                id: agent.memberKey,
                index: i,
                agent,
                volume: volume.get(agent.memberKey),
                isFavorite,
                toggleFavorite,
              } as CategorizedSalesVolumeModel)
          ),
        dataGroupingMode
      ),
    [agents, volume, isFavorite, toggleFavorite, dataGroupingMode]
  );

  const data: CategorizedSalesVolumeModel[] = [
    ...orderedData.top,
    ...orderedData.middle,
    ...orderedData.bottom,
  ];

  const searchTerm = useObservable(AgentsQuery.instance.searchTerm);
  const searchInput = useInput(searchTerm ?? '');

  const onSearch = (input: string) => {
    searchInput.setValue(input);
  };

  const [balanceReport, toggleBalanceReport] = useState(false);

  const reorderData = (mode: BalanceReportMode) => {
    setDataGroupingMode(mode);
  };

  const loading = useLoadingIndicator();

  const columns: DataTableColumn<CategorizedSalesVolumeModel>[] = [
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">FAVORITE</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      field: 'favorite',
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <FavoriteAgent
          memberKey={row.agent.memberKey}
          isFavorite={row.isFavorite(row.agent.memberKey)}
          toggleFavorite={row.toggleFavorite}
        />
      ),
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">NAME</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ row }) =>
        row.volume && (
          <>
            <Link to={`/agents/${row.volume.agent.memberKey}`} className={styles.link}>
              {row.volume.agent.fullName}
            </Link>
            {/*TODO recently recruited agents should show their recruit date... we currently don't have this info
     {recruit.recruitedDate && (
        <Chip
          color="secondary"
          size="small"
          style={{ fontSize: '80%' }}
          label={'Recruited ' + format(new Date(recruit.recruitedDate), 'MMM do yyyy')}
        />
  )}*/}
            <a
              href={`https://www.google.com/search?q=${row.volume?.agent.fullName} Realtor ${row.volume?.agent.officeName}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: palette.primary, margin: '0 .25rem' }}
            >
              <FontAwesomeIcon icon={icons.searchGlass} size="sm" />
            </a>
          </>
        ),
      flex: 2,
      sortable: true,
      valueGetter: (value, row) => row.volume?.agent.fullName,
      field: 'volume.agent.fullName',
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">ACTIVE</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ row }) => row.volume && <ValueComparison {...row.volume.activeListings} size="13px" />,
      sortable: true,
      valueGetter: (value, row) => row.volume?.activeListings.current,
      field: 'volume.activeListings.current',
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">PENDING</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ row }) => row.volume && <ValueComparison {...row.volume.pendingListings} size="13px" />,
      sortable: true,
      valueGetter: (value, row) => row.volume?.pendingListings.current,
      field: 'volume.pendingListings.current',
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">LIST TRANS</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ row }) => row.volume && <ValueComparison {...row.volume.listTransactions} size="13px" />,
      sortable: true,
      valueGetter: (value, row) => row.volume?.listTransactions.current,
      field: 'volume.listTransactions.current',
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">LIST TRANS VOL</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ row }) =>
        row.volume && (
          <ValueComparison {...row.volume.listTransactionsVol} size="13px" formatter={NumberFormatters.currency} />
        ),
      sortable: true,
      valueGetter: (value, row) => row.volume?.listTransactionsVol.current,
      field: 'volume.listTransactionsVol.current',
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">SELL TRANS</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ row }) => row.volume && <ValueComparison {...row.volume.sellTransactions} size="13px" />,
      sortable: true,
      valueGetter: (value, row) => row.volume?.sellTransactions.current,
      field: 'volume.sellTransactions.current',
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">SELL TRANS VOL</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ row }) =>
        row.volume && (
          <ValueComparison {...row.volume.sellTransactionsVol} size="13px" formatter={NumberFormatters.currency} />
        ),
      sortable: true,
      valueGetter: (value, row) => row.volume?.sellTransactionsVol.current,
      field: 'volume.sellTransactionsVol.current',
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">TOTAL TRANS</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ row }) => row.volume && <ValueComparison {...row.volume.totalTransactions} size="13px" />,
      sortable: true,
      valueGetter: (value, row) => row.volume?.totalTransactions.current,
      field: 'volume.totalTransactions.current',
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">TOTAL VOL</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ row }) => (
        <>
          {row.volume && (
            <ValueComparison {...row.volume.totalVolume} size="13px" formatter={NumberFormatters.currency} />
          )}
        </>
      ),
      sortable: true,
      valueGetter: (value, row) => row.volume?.totalVolume.current,
      field: 'volumeTotalVolume',
      type: 'number'
    },
    {
      headerName: '',
      field: 'volumeSumary',
      sortable: false,
      filterable: false,
      renderCell: ({ row }) =>
        !!row.volume && (
          <AgentVolumeSummaryTooltip
            memberKey={row.volume.agent.memberKey}
            agentName={row.volume.agent.fullName}
          />
        ),
    },
  ];

  return (
    <>
      {loading && <LinearProgress />}

      <Grid item container spacing={1}>
        <Grid item xs={12} md={data && data.length > 9 && balanceReport ? 8 : 12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Card>
              <CardContent>
                <Grid item container spacing={1}>
                  <Grid
                    container
                    item
                    sm={12}
                  >
                    <Grid item xs={12} sm={4}>
                      <SearchInput
                        value={searchInput.value}
                        onChange={event => onSearch(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      {data && data.length > 9 && (
                        <Box display="flex" justifyContent="flex-end">
                          {!balanceReport && (
                            <Button
                              onClick={() => toggleBalanceReport(true)}
                              variant="contained"
                              style={{ whiteSpace: 'nowrap', marginLeft: '1rem' }}
                            >
                              <FontAwesomeIcon
                                icon={icons.poll}
                                size="lg"
                                style={{ marginRight: '.5rem' }}
                              />
                              {'Show Balance Report'}
                            </Button>
                          )}
                          {balanceReport && (
                            <Button
                              onClick={() => toggleBalanceReport(false)}
                              variant="contained"
                              style={{ whiteSpace: 'nowrap', marginLeft: '1rem' }}
                            >
                              <FontAwesomeIcon
                                icon={icons.poll}
                                size="lg"
                                style={{ marginRight: '.5rem' }}
                              />
                              {'Hide Balance Report'}
                            </Button>
                          )}
                        </Box>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      value={dataGroupingMode}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        reorderData((event.target as HTMLInputElement).value as BalanceReportMode)
                      }
                    >
                      <FormLabel style={{ color: palette.neutralDark, display: 'inline-flex', alignItems: 'center', marginRight: '10px' }}>
                        Categorize By:
                      </FormLabel>

                      <FormControlLabel
                        value="totalTransactions"
                        control={<BpRadio />}
                        label={
                          <FormLabel
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              color: palette.neutralDark,
                            }}
                          >
                            <label>Transaction Count</label>
                          </FormLabel>
                        }
                        color="primary"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="totalVolume"
                        color="secondary"
                        control={<BpRadio />}
                        label={
                          <FormLabel
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              color: palette.neutralDark,
                            }}
                          >
                            <label>Total Volume</label>
                          </FormLabel>
                        }
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <LoadingFade
                  isContentLoading={loading}
                  content={() => (
                    <DataTable
                      rows={data.filter(
                        item =>
                          item.volume?.agent &&
                          item.volume?.agent.fullName &&
                          item.volume?.agent.fullName
                            .toLowerCase()
                            .includes(searchInput.value.toLowerCase())
                      )}
                      columns={columns}
                      showPagination
                      pageSizeOptions={[10, 25, 50, 100]}
                      progressPending={loading}
                      initialState={{
                        sorting: {
                          sortModel: [
                            {
                              field: 'volumeTotalVolume',
                              sort: 'desc',
                            },
                          ],
                        },
                        pagination: {
                          paginationModel: {
                            pageSize: 10
                          }
                        }
                      }}
                    />
                  )}
                />
              </CardContent>
            </Card>
          </LocalizationProvider>
        </Grid>
        {balanceReport && (
          <Grid item xs={12} md={4}>
            {data && data.length > 9 && (
              <BalanceReport orderedData={orderedData} dataGroupingMode={dataGroupingMode} />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const AgentList = deferred(AgentListView);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'transparent',
  borderColor: palette.primary,
  borderWidth: 1,
  borderStyle: 'solid',
  position: 'relative', // Add this line
  '&::before': {
    display: 'block',
    width: 8,
    height: 8,
    backgroundColor: palette.primary,
    borderRadius: '50%',
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  'input:hover ~ &': {
    borderColor: palette.primary,
  },
});

function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
