import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookieNames, LoadingFade, useObservable } from '../../shared';
import { Redirect, useParams } from 'react-router-dom';
import { authService, sessionQuery } from '../../state';
import { RefreshKvCoreTokens } from '../utils';
import { LinkToSso } from './link-to-sso';

export function KvCoreRefresh() {
  const { refreshToken } = useParams<{ refreshToken: string }>();
  const [cookies, setCookie, removeCookie] = useCookies([
    CookieNames.kvCoreToken,
    CookieNames.kvCoreRefreshToken,
  ]);
  const [tokenAvailable, setTokenAvailable] = useState(false);
  const [authStarted, setAuthStarted] = useState(false);
  const [error, setError] = useState(false);
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');

  useEffect(() => {
    if (!refreshToken) return;
    if (cookies.kvCoreToken !== undefined) {
      setTokenAvailable(true);
      return;
    }
    if (authStarted) return;
    try {
      setAuthStarted(true);
      authService.refreshKvCoreTokenRequest(
        refreshToken,
        RefreshKvCoreTokens(setCookie, removeCookie)
      );
    } catch {
      setError(true);
    }
  }, [cookies, authStarted, refreshToken, removeCookie, setCookie]);

  if (!refreshToken || !isLoggedIn) return <Redirect to={'/'} />;
  if (error) return <LinkToSso />;

  if (tokenAvailable) return <Redirect to={'/kvcore/sso'} />;

  return <LoadingFade isContentLoading={true}></LoadingFade>;
}
