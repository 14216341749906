import { useState } from 'react';
import { ErrorDisplay, useForm, useInput, required } from '../../shared/forms';
import { CreateQuestions } from './create-questions';
import { SurveyQuestion } from '../../state/surveys';
import { Box, Card, Grid } from '@mui/material';
import { Button, TextField } from 'shared/components';

interface Props {
  surveyTitle?: string | null;
  defaultQuestions: Array<SurveyQuestion>;
  onSubmit: (title: string, questions: Array<SurveyQuestion>) => void;
  error: Error | undefined;
  canDeleteQuestions?: boolean;
}

export function SurveyForm({
  surveyTitle,
  defaultQuestions,
  onSubmit,
  error,
  canDeleteQuestions = true,
}: Props) {
  const title = useInput<string>(surveyTitle ?? '', {
    validators: [required()],
  });
  const [addQuestionDisabled, setAddQuestionDisabled] = useState(false);
  const [questions, setQuestions] = useState<Array<SurveyQuestion>>(defaultQuestions);

  const form = useForm(() => {
    title.setTouched(true);
    if (!form.valid) return;
    onSubmit(title.value, [...questions]);
  }, title);

  return (
    <Card
      sx={theme => ({
        padding: theme.spacing(4),
      })}
    >
      <form {...form.bind}>
        {!!error ? (
          <Box marginBottom={4}>
            <ErrorDisplay error={error} />
          </Box>
        ) : null}

        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TextField name="surveyTitle" {...title.bind} label="Survey Title" fullWidth />
          </Grid>

          <Grid item>
            <CreateQuestions
              questions={questions}
              setQuestions={setQuestions}
              addQuestionDisabled={addQuestionDisabled}
              canDeleteQuestions={canDeleteQuestions}
            />
          </Grid>

          <Grid item>
            <Button
              onMouseEnter={() => setAddQuestionDisabled(true)}
              onMouseLeave={() => setAddQuestionDisabled(false)}
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}
