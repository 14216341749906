import { GetAgentSurveyAveragesRequest } from "api/app";
import { useEffect } from "react";
import { useObservable } from "shared";
import {agentSurveyReportQuery, agentSurveyReportService } from "state/reports";

export function useAgentSurveyAverages(params: GetAgentSurveyAveragesRequest) {
  useEffect(() => {
    agentSurveyReportService.getAgentSurveyAverages(params);
  }, [params]);

  useEffect(() => {
    agentSurveyReportService.getAgentSurveyReportFilters(params.startDate, params.endDate);
  }, [params.startDate, params.endDate])

  return {
    agents: useObservable(agentSurveyReportQuery.agents),
    total: useObservable(agentSurveyReportQuery.total),
    filters: useObservable(agentSurveyReportQuery.filters),
    loading: useObservable(agentSurveyReportQuery.loading)
  };
}
