import {
  ReactNode,
  ChangeEventHandler,
  FocusEventHandler,
  ElementType,
  KeyboardEventHandler,
} from 'react';
import {TextField as MuiTextField, InputBaseComponentProps, Theme} from '@mui/material';
import {SxProps} from "@mui/system";

type InputType = 'text' | 'password' | 'email' | 'phone' | 'number' | 'textarea' | 'select';

type TextFieldProps = {
  name?: string;
  label?: string;
  id?: string;
  placeholder?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  minRows?: number;
  readonly?: boolean;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  autoComplete?: string;
  type?: InputType;
  rows?: number;
  maxRows?: number;
  value?: number | string | readonly string[] | null;
  error?: boolean;
  helperText?: ReactNode;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  children?: ReactNode;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>;
  _component?: ElementType<InputBaseComponentProps>;
  sx?: SxProps<Theme>;
};

export function TextField({
  type = 'text',
  startAdornment,
  endAdornment,
  readonly,
  fullWidth,
  _component,
  sx,
  ...rest
}: TextFieldProps) {
  return (
    <MuiTextField
      type={inputTypeMapping(type)}
      multiline={type === 'textarea'}
      InputProps={{ startAdornment, endAdornment, readOnly: readonly, inputComponent: _component }}
      sx={{
        ...sx,
        width: fullWidth ? '100%' : 'auto',
        '& .MuiInputBase-root': {
          minHeight: '40px',
          height: 'auto',
          padding: '4px 12px',
        },
        '& .MuiInputLabel-outlined': {
          transform: 'translate(14px, 12px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
        },
        '& .MuiOutlinedInput-input': {
          padding: '6px 0',
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingTop: '4px !important',
          paddingBottom: '4px !important',
        },
        '& .MuiChip-root': {
          height: '24px',
          fontSize: '0.8125rem',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
        },
        '& .MuiInputAdornment-root': {
          '& .MuiSvgIcon-root': {
            fontSize: '1.125rem',
          },
        },
      }}
      {...rest}
    />
  );
}

function inputTypeMapping(inputType: InputType) {
  if (inputType === 'textarea') return 'text';
  if (inputType === 'select') return 'text';

  return inputType;
}
