import React from 'react';
import {
  Button,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Theme,
} from '@mui/material';
import { makeStyles, createStyles, ClassNameMap } from '@mui/styles';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Link } from 'shared/components/link';
import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

export const AccountMenu: React.FC = () => {
  const classes: ClassNameMap<string> = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      paper: {
        marginRight: theme.spacing(2),
      },
      button: {
        border: 'solid thin',
        borderColor: palette.neutral,
        borderRadius: '.25rem',
        padding: '.4rem',
        margin: '0 .125rem',
        color: palette.neutralDark,
      },
    })
  )();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (target: EventTarget) => {
    if (anchorRef.current && anchorRef.current.contains(target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<KeyboardArrowDown />}
        className={classes.button}
      >
        My Account
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={e => handleClose(e.target as EventTarget)}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <Link to="/profile">
                    <MenuItem onClick={e => handleClose(e.target)}>Profile</MenuItem>
                  </Link>
                  <Link to="/notifications">
                    <MenuItem onClick={e => handleClose(e.target)}>Email Notifications</MenuItem>
                  </Link>
                  <Link to="/survey">
                    <MenuItem onClick={e => handleClose(e.target)}>Agent Surveys</MenuItem>
                  </Link>
                  <Link to="/account/signout">
                    <MenuItem onClick={e => handleClose(e.target)}>Logout</MenuItem>
                  </Link>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
