import { AxiosPromise, AxiosResponse } from 'axios';

export type CancellablePromise<ParamType, ReturnType> = (
  param: ParamType,
  options?: any
) => AxiosPromise<ReturnType>;

/**
 * A function to make Axios requests cancellable. Meant to be used for functions that may take a long time to complete.
 *
 * {@link determineStoreLoading} should be used with this function in the `pipe` operator unless the load state doesn't matter.
 * @param api The API context for the API that the function is coming from
 * @param fn The request that's being made
 * @returns A function that returns a promise of AxiosResponse<`F`>
 */
export function makeCancellable<ParamType, ReturnType>(
  api: any,
  fn: (param: ParamType, options?: any) => AxiosPromise<ReturnType>
) {
  let reject_: (reason?: any) => void; // cache for the latest `reject` executable
  return function (param: ParamType, options?: any) {
    if (reject_) reject_('cancelled'); // If previous reject_ exists, cancel it.
    // Note, this has an effect only if the previous race is still pending.
    let canceller = new Promise<AxiosResponse<ReturnType>>((_, reject) => {
      // create canceller promise
      reject_ = reject; // cache the canceller's `reject` executable
    });

    return Promise.race([canceller, fn.apply(api, [param, options])]); // now race the promise of interest against the canceller
  };
}
