import React from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';

import {
  AdminDashboardPage,
  CreateUserPage,
  CreateCompanyPage,
  EditCompanyPage,
  ViewCompanyPage,
  EditUserPage,
  FeedStatusPage,
  FeedDisclaimersPage,
  UserImpersonationPage,
} from './pages';
import { useObservable } from '../shared';
import { sessionQuery } from '../state';
import { EnterpriseAdoptionReportPage } from 'reports';

export const AdminRoutes: React.FC<RouteComponentProps> = ({ match }) => {
  const isAdmin = useObservable(sessionQuery.isAdmin);

  return (
    <Switch>
      {!isAdmin && <Redirect to={'/company'} />}
      <Route path={match.path} exact component={AdminDashboardPage} />
      <Route path={`${match.path}/companies`} exact component={AdminDashboardPage} />
      <Route path={`${match.path}/companies/create`} component={CreateCompanyPage} />
      <Route path={`${match.path}/companies/:companyId/users/create`} component={CreateUserPage} />
      <Route path={`${match.path}/companies/:companyId/users/:userId`} component={EditUserPage} />
      <Route path={`${match.path}/companies/:companyId/edit`} component={EditCompanyPage} />
      <Route path={`${match.path}/companies/:companyId/`} component={ViewCompanyPage} />
      <Route path={`${match.path}/status`} component={FeedStatusPage} />
      <Route path={`${match.path}/disclaimers`} component={FeedDisclaimersPage} />
      <Route path={`${match.path}/user-impersonation`} component={UserImpersonationPage}/>
      <Route path={`${match.path}/enterprise-adoption-report`} exact component={EnterpriseAdoptionReportPage} />
      <Redirect to={`${match.path}/companies`} />
    </Switch>
  );
};
