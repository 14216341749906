import React, { useMemo, useState } from 'react';
import {Card, Box, CardContent, CardHeader, LinearProgress, Grid, Typography, Tooltip} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from 'shared/components/button';

import { Listing, Agent, Office, hasAddress } from '../../state';
import { ThemeColors, NumberFormatters, Link } from '../../shared';
import { DataTable, DataTableColumn } from 'shared/components/data-table';
import { AgentTransactionMap } from './agent-transactions-map';
import { useAgentLookup } from './useAgentLookup';
import { useMlsOfficeLookup } from './useOfficeLookup';
import { useLoadingIndicator } from '../../shared/indicators/isLoading';
import { LoadingFade } from '../../shared/components/loading-fade';
import { Mls } from '../../api';
import themeService from 'theme/ThemeService';

enum TransactionSide {
  List = 'List',
  Sell = 'Sell',
  Both = 'Both',
}

interface Props {
  listings: Array<Listing>;
  listSide: Array<Listing>;
  sellSide: Array<Listing>;
  bothSides: Array<Listing>;
  officeKey?: string | null;
}

const formatAgentOfficeNumber = (officeNumber: string | null | undefined) =>
  officeNumber ? ` (${officeNumber})` : '';

export const AgentTransactions: React.FC<Props> = ({
  listings,
  listSide,
  sellSide,
  bothSides,
  officeKey,
}) => {
  const agentLookup = useAgentLookup();
  const officeLookup = useMlsOfficeLookup();
  const loading = useLoadingIndicator();

  const office: Mls.OfficeModel[] = useMemo(() => {
    if (officeKey) {
      return [officeLookup.get(officeKey)!];
    }
    return [];
  }, [officeLookup, officeKey]);

  const [showMap, setShowMap] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isClosed, setClosed] = useState(true);

  //Used ternary here because of preferance.
  const showing = !isClosed ? (isActive ? 'Active' : 'Pending') : 'Closed';

  if (showMap) {
    return (
      <Box height="100%">
        <Card>
          <CardHeader
            title="Transaction Map"
            action={<Button onClick={() => setShowMap(false)}>View Transactions List</Button>}
          />
          <CardContent>
            <AgentTransactionMap listings={listings} offices={office} />
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box height="100%">
      <>
        <Card>
          {loading && <LinearProgress />}
          <CardHeader
            title="Transactions"
            className="actionHeader"
            subheader={'Showing ' + showing + ' listings'}
            action={
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    onClick={() => {
                      setActive(prevIsActive => !prevIsActive);
                      setClosed(false);
                    }}
                  >
                    Show {isActive ? 'Pending' : 'Active'} Transactions
                  </Button>
                </Grid>

                {!isClosed && (
                  <Grid item>
                    <Button onClick={() => setClosed(true)}>Show Closed Transactions</Button>
                  </Grid>
                )}

                <Grid item>
                  <Button onClick={() => setShowMap(true)}>View Transactions Map</Button>
                </Grid>
              </Grid>
            }
          />

          <LoadingFade isContentLoading={loading}>
            <CardContent>
              <TransactionList
                title="Listing Side"
                side={TransactionSide.List}
                transactions={listSide}
                agents={agentLookup}
                offices={officeLookup}
                active={isActive}
                closed={isClosed}
              />
            </CardContent>
            <CardContent>
              <TransactionList
                title="Selling Side"
                side={TransactionSide.Sell}
                transactions={sellSide}
                agents={agentLookup}
                offices={officeLookup}
                active={isActive}
                closed={isClosed}
              />
            </CardContent>
            <CardContent>
              <TransactionList
                title="Both Sides"
                side={TransactionSide.Both}
                transactions={bothSides}
                agents={agentLookup}
                offices={officeLookup}
                active={isActive}
                closed={isClosed}
              />
            </CardContent>
          </LoadingFade>
        </Card>
      </>
    </Box>
  );
};

interface ViewModel {
  id: string;
  transaction: Mls.ListingModel;
  isTotal: boolean;
}

interface TransactionListProps {
  title: string;
  side: TransactionSide;
  agents: Map<string, Agent>;
  offices: Map<string, Office>;
  transactions: Array<Listing>;
  active: boolean;
  closed: boolean;
}

const palette = themeService.getPalette();

const useStyles = makeStyles(theme => ({
  link: {
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: palette.teal,
    maxWidth: "100%",
  }
}));

const TransactionList: React.FC<TransactionListProps> = ({
  title,
  side,
  transactions,
  agents,
  active,
  closed,
}) => {
  const defaultRowLimit = 10;
  const [showAll, setShowAll] = useState(false);
  const classes = useStyles();

  //Used ternary here because of preferance.
  const transactionState = !closed ? (active ? 'Active' : 'Pending') : 'Closed';

  const allRows = useMemo(
    () =>
      [
        ...transactions.filter(x => x.status === transactionState && hasAddress(x)),
      ] as Array<Mls.ListingModel>,
    [transactions, transactionState]
  );

  const data = useMemo(
    () => [
      ...allRows.slice(0, showAll ? undefined : defaultRowLimit).map(transaction => ({
        id: transaction.listingKey,
        transaction,
        isTotal: false,
      })),
      {
        id: 'total',
        transaction: allRows.reduce(
          (total, x) => ({
            ...total,
            listPrice: total.listPrice + (x.listPrice ?? 0),
            closePrice: total.closePrice! + (x.closePrice ?? 0),
            closeDate: '', //fake it on the summary row, so it ends up at the bottom of the list, when we're sorting by this field
          }),
          { listPrice: 0, closePrice: 0 } as Mls.ListingModel
        ),
        isTotal: true,
      },
    ],
    [allRows, showAll]
  );

  const columns = useMemo<Array<DataTableColumn<ViewModel>>>(
    () => [
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">MLS #</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        field: 'ml',
        sortable: false,
        filterable: true,
        renderCell: ({row}) => row.isTotal ? (<strong>Total:</strong>) : row.transaction.listingNumber ?? 'N/A',
        valueGetter: (value, row) => row.transaction.listingNumber,
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">ADDRESS</Typography>,
        headerAlign: 'center',
        align: 'center',
        field: 'address',
        flex: 3,
        sortable: false,
        filterable: true,
        valueGetter: (value, row) => row.transaction.address
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">SELLING AGENT</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        field: 'sellingAgent',
        flex: 1.5,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
          if (row.isTotal) return null;
          let memberKey = row.transaction.sellMemberKeys[0];
          if (!memberKey) {
            return (<div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
              <Tooltip arrow placement='top' title='No data for selling agent'>
                <Typography noWrap>N/A</Typography>
              </Tooltip>
            </div>)
          }
          let agent = agents.get(memberKey);
          let active = agent?.active ?? false;
          let agentName = agent?.fullName ?? 'N/A';
          let officeNumber = formatAgentOfficeNumber(agent?.officeNumber);
          if (active) {
            return (
              <Link className={classes.link} to={`/agents/${memberKey}`} color="primary">
                {agentName + ' ' + officeNumber}
              </Link>
            )
          }
          return (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Tooltip arrow placement='top' title='Agent is no longer active'>
                <Typography noWrap>{agentName}</Typography>
              </Tooltip>
            </div>
          );
        },
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">LISTING AGENT</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        field: 'ListingAgent',
        flex: 1.5,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
          if (row.isTotal) return null;
          let memberKey = row.transaction.listMemberKeys[0];
          if (!memberKey) {
            return (<div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
              <Tooltip arrow placement='top' title='No data for listing agent'>
                <Typography noWrap>N/A</Typography>
              </Tooltip>
            </div>)
          }
          let agent = agents.get(memberKey);
          let active = agent?.active ?? false;
          let agentName = agent?.fullName ?? 'N/A';
          let officeNumber = formatAgentOfficeNumber(agent?.officeNumber);
          if (active) {
            return (
              <Link className={classes.link} to={`/agents/${memberKey}`} color="primary">
                {(agentName + ' ' + officeNumber).trim()}
              </Link>
            )
          }
          return (
            <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
              <Tooltip arrow placement='top' title='Agent is no longer active'>
                <Typography noWrap>{(agentName + ' ' + officeNumber).trim()}</Typography>
              </Tooltip>
            </div>
          );
        },
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">LISTING DATE</Typography>,
        headerAlign: 'center',
        align: 'center',
        field: 'listDate',
        sortable: false,
        filterable: true,
        type: 'date',
        renderCell: ({row}) =>
          row.transaction.listDate ? new Date(row.transaction.listDate).toLocaleDateString() : undefined,
        valueGetter: (value, row) => {
          if (row.transaction.listDate) return new Date(row.transaction.listDate);
          return null;
        },
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">LIST PRICE</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        field:'listPrice',
        sortable: false,
        filterable: true,
        type: 'number',
        valueGetter: (value, row) => row.transaction.listPrice,
        renderCell: ({ row }) => (
          <Box color={row.isTotal ? ThemeColors.Green : undefined}>
            <strong>
              {row.isTotal && side === TransactionSide.Both
                ? row.transaction.listPrice != null &&
                  NumberFormatters.currency`${row.transaction.listPrice * 2}`
                : row.transaction.listPrice != null &&
                  NumberFormatters.currency`${row.transaction.listPrice}`}
            </strong>
          </Box>
        ),

      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">STATUS</Typography>,
        headerAlign: 'center',
        align: 'center',
        field: 'status',
        sortable: false,
        filterable: true,
        valueGetter: (value, row) => row.transaction.status,
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">SOLD DATE</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        field: 'closeDate',
        sortable: false,
        filterable: true,
        type: 'date',
        renderCell: ({ row }) =>
          row.transaction.closeDate ? new Date(row.transaction.closeDate).toLocaleDateString() : undefined,
        valueGetter: (value, row) => {
          if (row.transaction.closeDate) return new Date(row.transaction.closeDate);
          return null;
        },
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">SOLD PRICE</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        field: 'soldPrice',
        sortable: false,
        filterable: true,
        type: 'number',
        valueGetter: (value, row) => row.transaction.closePrice,
        renderCell: ({ row }) => (
          <Box color={row.isTotal ? ThemeColors.Green : undefined}>
            <strong>
              {row.isTotal && side === TransactionSide.Both
                ? row.transaction.closePrice != null &&
                  NumberFormatters.currency`${row.transaction.closePrice * 2}`
                : row.transaction.closePrice != null &&
                  NumberFormatters.currency`${row.transaction.closePrice}`}
            </strong>
          </Box>
        ),
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">DOM</Typography>,
        headerAlign: 'center',
        align: 'center',
        field: 'daysOnMarket',
        sortable: false,
        filterable: true,
        type: 'number',
        valueGetter: (value, row) => row.transaction.daysOnMarket,
      },
    ],
    [side, agents, classes.link]
  );
  return (
    <>
      <Box
        style={{
          border: 'solid thin',
          borderColor: ThemeColors.DarkerGray,
          borderRadius: '1rem',
          overflow: 'hidden',
        }}
      >
        {' '}
        <Box
          p={2}
          fontSize="125%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <strong>{title}</strong>
          {defaultRowLimit < allRows.length && (
            <Button onClick={() => setShowAll(!showAll)}>
              {showAll
                ? `Show Last ${defaultRowLimit}`
                : 'Show All ' + NumberFormatters.decimal`${allRows.length}`}
            </Button>
          )}
        </Box>
        <DataTable
          rows={data}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'closeDate',
                  sort: 'desc',
                },
              ],
            },
          }}
        />
      </Box>
    </>
  );
};
