import { useEffect, useState } from 'react';
import { QuestionType, SurveyQuestion } from '../../state/surveys';
import { icons } from '../../shared';
import { useQuestionTypes } from '../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Select, MenuItem, FormLabel } from '@mui/material';
import { Button, ConfirmationDialog, TextField } from 'shared/components';
import { DataTable, DataTableColumn} from 'shared/components/data-table';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useQuestionStyles } from '../utils';

interface Props {
  questions: Array<SurveyQuestion>;
  setQuestions: (questions: Array<SurveyQuestion>) => void;
  addQuestionDisabled: boolean;
  canDeleteQuestions?: boolean;
}

export function CreateQuestions({
  questions,
  setQuestions,
  addQuestionDisabled,
  canDeleteQuestions = true,
}: Props) {
  const { questionTypes, loading } = useQuestionTypes();
  const [editOpen, setEditOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState<
    { question: SurveyQuestion; index: number } | undefined
  >();

  const onNew = (question: SurveyQuestion) => {
    setQuestions([...questions, question]);
  };

  const handleEditClick = (question: SurveyQuestion, index: number) => {
    setQuestionToEdit({ question, index });
    setEditOpen(true);
  };

  const onEdit = (updatedQuestion: SurveyQuestion) => {
    if (!questionToEdit) return;

    const updatedQuestionsList = questions.map((question,index) => {
      if (index === questionToEdit.index) {
        return updatedQuestion;
      }
      return question
    })

    setQuestions(updatedQuestionsList);
    setQuestionToEdit(undefined);
  };

  const onOrderChange = (oldIndex: number, newIndex: number) => {
    let edit = [...questions];
    const [removedItem] = edit.splice(newIndex, 1);
    edit.splice(oldIndex, 0, removedItem);
    setQuestions(edit);
  };

  const onRemove = (question: SurveyQuestion) => {
    setQuestions([...questions.filter(q => q !== question)]);
  };

  const columns: DataTableColumn<SurveyQuestion>[] = [
    {
      headerName: 'Order',
      field: 'order',
      renderCell: ({row}) => {
        const index = questions.findIndex(q => q === row);
        return (
          <Grid container spacing={1}>
            {index > 0 && (
              <Grid item>
                <Button
                  type="button"
                  variant="secondary"
                  size="small"
                  onClick={() => onOrderChange(index, index - 1)}
                >
                  <ArrowDropUp />
                </Button>
              </Grid>
            )}

            {index + 1 < questions.length && (
              <Grid item>
                <Button
                  type="button"
                  variant="secondary"
                  size="small"
                  onClick={() => onOrderChange(index, index + 1)}
                >
                  <ArrowDropDown />
                </Button>
              </Grid>
            )}
          </Grid>
        );
      },
      flex: 1,
    },
    {
      headerName: 'Question',
      field: 'questionText',
      flex: 7,
    },
    {
      headerName: 'Response Type',
      field: 'responsetype',
      renderCell: ({ row }) =>
          questionTypes.find(qt => qt.id === row.questionTypeId)?.typeName,
    },
    {
      headerName: '',
      field: 'editDelete',
      renderCell: ({row}) => {
        const index = questions.findIndex(q => q === row);

        return (
          <Grid container columnGap={1}>
            <Grid item>
              <Button type="button" onClick={() => handleEditClick(row, index)}>
                Edit
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="button"
                variant="secondary"
                onClick={() => onRemove(row)}
                disabled={!canDeleteQuestions}
              >
                <FontAwesomeIcon icon={icons.trash} />
              </Button>
            </Grid>
          </Grid>
        );
      },
      flex: 1.2,
    },
  ];

  return (
    <>
      <QuestionDialog
        open={editOpen}
        setOpen={setEditOpen}
        title="Edit Question"
        onSubmit={onEdit}
        questionTypes={questionTypes}
        question={questionToEdit?.question}
      />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <DataTable
            columns={columns}
            rows={questions}
            progressPending={loading}
            getRowId={() => Math.random().toString()}
          />
        </Grid>

        <Grid item>
          <CreateQuestion
            onSubmit={onNew}
            questionTypes={questionTypes}
            addQuestionDisabled={addQuestionDisabled}
          />
        </Grid>
      </Grid>
    </>
  );
}

interface QuestionDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  question?: SurveyQuestion;
  onSubmit: (question: SurveyQuestion) => void;
  questionTypes: Array<QuestionType>;
}

function QuestionDialog({
  open,
  setOpen,
  title,
  question,
  onSubmit,
  questionTypes,
}: QuestionDialogProps) {
  const [questionId, setQuestionId] = useState<number | undefined>(undefined);
  const [questionText, setQuestionText] = useState('');
  const [questionType, setQuestionType] = useState(1);
  const [error, setError] = useState(false);

  useEffect(() => {
    setQuestionId(question?.id || questionId);
    setQuestionText(question?.questionText || questionText);
    setQuestionType(question?.questionTypeId || questionType);
  }, [question, open, questionId, questionText, questionType]);

  const handleSubmit = () => {
    if (questionText === '') {
      setError(true);
      return;
    }
    setError(false);
    onSubmit({ id: questionId, questionText, questionTypeId: questionType });
    handleClose();
  };

  const handleClose = () => {
    setQuestionText('');
    setQuestionType(1);
    setOpen(false);
  };

  return (
    <ConfirmationDialog
      title={title}
      onClose={handleClose}
      onConfirm={handleSubmit}
      onCancel={handleClose}
      isOpen={open}
      fullWidth
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            name="questionText"
            label="Question"
            value={questionText}
            onChange={event => setQuestionText(event.target.value)}
            error={error}
            helperText={error ? 'This field is required!' : undefined}
            fullWidth
            required
            type="textarea"
          />
        </Grid>
        <Grid item marginBottom="1em">
          <FormLabel>Response Type</FormLabel>
          <Select
            value={questionType}
            onChange={event => setQuestionType(event.target.value as number)}
            required
            fullWidth
            variant="outlined"
          >
            {questionTypes.map(({ id, typeName }) => (
              <MenuItem key={id} value={id}>
                {typeName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </ConfirmationDialog>
  );
}

interface CreateQuestionProps {
  onSubmit: (question: SurveyQuestion) => void;
  questionTypes: Array<QuestionType>;
  addQuestionDisabled: boolean;
}

function CreateQuestion({ onSubmit, questionTypes, addQuestionDisabled }: CreateQuestionProps) {
  const tableStyles = useQuestionStyles();
  const [questionText, setQuestionText] = useState('');
  const [questionType, setQuestionType] = useState(1);
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (questionText === '') {
      setError(true);
      return;
    }
    setError(false);
    onSubmit({ questionText, questionTypeId: questionType });
    setQuestionText('');
    setQuestionType(1);
  };

  return (
    <Grid container direction="row" className={tableStyles.addQuestionGrid}>
      <Grid item sm={8.9} margin={'1em'}>
        <FormLabel>Question</FormLabel>
        <TextField
          name="questionText"
          value={questionText}
          onChange={event => setQuestionText(event.target.value)}
          error={error}
          helperText={error ? 'This field is required!' : undefined}
          required
          placeholder="Add text here"
          type="textarea"
          fullWidth
          disabled={addQuestionDisabled}
        />
      </Grid>
      <Grid item sm={1} margin={'1em'}>
        <FormLabel>Response Type</FormLabel>
        <Select
          value={questionType}
          onChange={event => setQuestionType(event.target.value as number)}
          required
          fullWidth
          variant="outlined"
        >
          {questionTypes.map(({ id, typeName }) => (
            <MenuItem key={id} value={id}>
              {typeName}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item sm={1} margin={'2em'} justifyItems="center">
        <Button type="button" onClick={handleSubmit}>
          Add to Survey
        </Button>
      </Grid>
    </Grid>
  );
}
