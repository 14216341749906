import { CookieNames, RefreshCookie } from '../shared';

export function RefreshKvCoreTokens(
  setCookie: (name: CookieNames, value: any, options?: any) => void,
  removeCookie: (name: CookieNames, options?: any) => void
) {
  const onCookieChange = (token: string, refreshToken: string | undefined, tokenExpiry: number) => {
    const tokenExpiryDate = new Date(Date.now() + 1000 * tokenExpiry);
    const refreshTokenExpiryDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 7);

    const refreshCookie = RefreshCookie(setCookie, removeCookie);
    if (refreshToken !== undefined)
      refreshCookie(CookieNames.kvCoreRefreshToken, refreshToken, {
        expires: refreshTokenExpiryDate,
      });
    refreshCookie(CookieNames.kvCoreToken, token, { expires: tokenExpiryDate });
  };

  return onCookieChange;
}
