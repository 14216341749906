import { StoreConfig } from '@datorama/akita';
import {MemberFilter, OfficeFilter } from 'api/app';
import { ResettableStore } from '../../util';
import { AgentModel } from './agent-survey.models';

export interface AgentSurveyReportState {
  agents: AgentModel[];
  total: number;
  filters: {
    offices: OfficeFilter[],
    agents: MemberFilter[]
  }
}

export const initialState: AgentSurveyReportState = {
  agents: [],
  total: 0,
  filters: {
    offices: [],
    agents: []
  }
};

@StoreConfig({ name: 'agentSurveyReport', resettable: true })
export class AgentSurveyReportStore extends ResettableStore<AgentSurveyReportState> {
  constructor() {
    super(initialState);
  }
}

export const agentSurveyReportStore = new AgentSurveyReportStore();
