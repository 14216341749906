import React from 'react';
import { Card, CardHeader, Grid, Box } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { ArrowUpward, ArrowDownward, Info, Home } from '@mui/icons-material';
import { Agent, AgentVolumeSummary } from '../../state';
import { Link } from './link';
import { Typography } from './typography';
import { NumberFormatters } from '../utils/formatters';
import {
  CategorizedSalesVolumeDataWrapper,
  BalanceReportMode,
} from '../utils/balanceReportHelpers';

import _ from 'lodash';
import { ThemeColors } from '../theme';
import themeService from 'theme/ThemeService';

const deltaChange = (current: number | undefined = 0, previous: number | undefined = 0) => {
  const change = (current - previous) / previous;
  return isNaN(change) ? 0 : change;
};
const formatCurrency = (amount: number | undefined = 0) => {
  return NumberFormatters.currency`${amount || 0}`;
};
const formatPercentage = (delta: number) => {
  return isNaN(delta) ? '' : NumberFormatters.percent`${delta}`;
};
interface Props {
  orderedData: CategorizedSalesVolumeDataWrapper;
  dataGroupingMode: BalanceReportMode;
}

interface ViewModel {
  id: Agent['memberKey'];
  index: number;
  agent: Agent;
  volume?: AgentVolumeSummary;
}
enum Slice {
  Top = ThemeColors.Green,
  Middle = ThemeColors.Orange,
  Bottom = ThemeColors.Red,
}

const palette = themeService.getPalette();

const useStyles = makeStyles(
  createStyles({
    BalanceReportBox: { height: '100%' },
    BalanceReportGrid: { padding: '16px', minHeight: '95%', display: 'flex' },

    BalanceChartLabel: {
      position: 'absolute',
      left: '-22px',
      bottom: '50%',
      color: 'white',
      fontWeight: 500,
      zIndex: 10,
      rotate: '-90deg',
      fontSize: '.75rem',
    },
    BalanceChartDetailsWrapper: {
      fontSize: '85%',
      overflow: 'hidden',
      borderBottom: 'solid thin',
      borderBottomColor: ThemeColors.LightGray,
      padding: '.5rem 0',
      margin: '0 .5rem',

      '& > .MuiGrid-item': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        '& > span': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
      },
    },
    BalanceChartName: {
      fontSize: 'inherit',
    },
  })
);

const useSliceStyles = makeStyles(
  createStyles({
    BalanceChartBar: ({ slice }: BalanceChartBarProps) => ({
      borderColor: slice.toString(),
      borderWidth: '1.25rem',
      borderStyle: 'solid',
      borderTopStyle: 'none',
      borderBottomStyle: 'none',
      borderRightStyle: 'none',
      flex: slice === Slice.Middle ? 2 : 1,
      flexDirection: slice === Slice.Middle ? 'column' : 'inherit',
      position: 'relative',
      paddingLeft: 0,
      paddingBottom: '0.5rem',
      minHeight: '115px',
    }),
  })
);

export const BalanceReport: React.FC<Props> = ({
  orderedData,
  dataGroupingMode = 'totalVolume',
}) => {
  const styles = useStyles();

  return (
    <Box width="100%" height="100%">
      <Card style={{ height: '100%' }}>
        <CardHeader
          title={`Balance Report by ${
            dataGroupingMode === 'totalVolume' ? 'Total Volume' : 'Total Transactions'
          }`}
        />

        <Box className={styles.BalanceReportBox} height="100%">
          <Grid container direction="column" className={styles.BalanceReportGrid}>
            {orderedData && (
              <>
                <BalanceChartBar
                  dataValues={orderedData.top}
                  slice={Slice.Top}
                  percent={20}
                  dataGroupingMode={dataGroupingMode}
                />
                <BalanceChartBar
                  dataValues={orderedData.middle}
                  slice={Slice.Middle}
                  percent={60}
                  dataGroupingMode={dataGroupingMode}
                />
                <BalanceChartBar
                  dataValues={orderedData.bottom}
                  slice={Slice.Bottom}
                  percent={20}
                  dataGroupingMode={dataGroupingMode}
                />
              </>
            )}
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

interface BalanceChartBarProps {
  dataValues: ViewModel[];
  slice: Slice;
  percent: number;
  dataGroupingMode: BalanceReportMode;
}

const BalanceChartBar: React.FC<BalanceChartBarProps> = props => {
  const { dataValues, slice, percent, dataGroupingMode } = props;
  const styles = useStyles();
  const sliceStyles = useSliceStyles(props);

  return (
    <Grid item xs className={sliceStyles.BalanceChartBar} container>
      {/*top 20th percentile*/}
      {slice === Slice.Top &&
        _.take(dataValues, 5).map(
          ({ volume, index }) =>
            volume && (
              <BalanceChartLabel
                key={index}
                rowNumber={index + 1}
                volume={volume}
                slice={slice}
                dataGroupingMode={dataGroupingMode}
              />
            )
        )}

      {/* Middle 60th percentile*/}

      {slice === Slice.Middle &&
        (dataValues.length <= 10
          ? dataValues.map(
              ({ volume, index }) =>
                volume && (
                  <BalanceChartLabel
                    key={index}
                    rowNumber={index + 1}
                    volume={volume}
                    slice={slice}
                    dataGroupingMode={dataGroupingMode}
                  />
                )
            )
          : _.take(dataValues, 5).map(
              ({ volume, index }) =>
                volume && (
                  <BalanceChartLabel
                    key={index}
                    rowNumber={index + 1}
                    volume={volume}
                    slice={slice}
                    dataGroupingMode={dataGroupingMode}
                  />
                )
            ) &&
            _.takeRight(dataValues, 5).map(
              ({ volume, index }) =>
                volume && (
                  <BalanceChartLabel
                    key={index}
                    rowNumber={index + 1}
                    volume={volume}
                    slice={slice}
                    dataGroupingMode={dataGroupingMode}
                  />
                )
            ))}

      {/*top 20th percentile*/}
      {slice === Slice.Bottom &&
        _.takeRight(dataValues, 5).map(
          ({ volume, index }) =>
            volume && (
              <BalanceChartLabel
                key={index}
                rowNumber={index + 1}
                volume={volume}
                slice={slice}
                dataGroupingMode={dataGroupingMode}
              />
            )
        )}

      {slice !== Slice.Middle && dataValues.length > 5 && (
        <small style={{ marginLeft: '10px', marginTop: '10px' }}>
          <Info style={{ fontSize: 'small' }} /> Plus {dataValues.length - 5} more
        </small>
      )}
      {slice === Slice.Middle && dataValues.length > 10 && (
        <small style={{ marginLeft: '10px', marginTop: '10px' }}>
          <Info style={{ fontSize: 'small' }} /> Plus {dataValues.length - 10} more
        </small>
      )}

      {<div className={styles.BalanceChartLabel}>{percent}%</div>}
    </Grid>
  );
};

interface BalanceChartLabelProps {
  rowNumber: number;
  volume: AgentVolumeSummary;
  slice: Slice;
  dataGroupingMode: BalanceReportMode;
}

const getDeltaColor = (difference: number) => {
  if (difference === 0) return palette.neutralDark;
  else if (difference > 0) return Slice.Top.toString();
  else return Slice.Bottom.toString();
};

const BalanceChartLabel: React.FC<BalanceChartLabelProps> = ({
  rowNumber,
  volume,
  slice,
  dataGroupingMode,
}) => {
  const styles = useStyles();

  return (
    <Grid container item xs={12} className={styles.BalanceChartDetailsWrapper}>
      <Grid item xs={5}>
        <Typography noWrap>
          {rowNumber}.
          <Link to={`/agents/${volume.agent.memberKey}`} color="textPrimary">
            {volume.agent.fullName}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <span
          style={{
            fontWeight: 'bold',
            color: getDeltaColor(
              deltaChange(volume.totalVolume?.current, volume.totalVolume?.previous)
            ),
          }}
        >
          {formatCurrency(volume.totalVolume?.current)}
        </span>
      </Grid>
      <Grid item xs={2}>
        {volume[dataGroupingMode]?.previous && volume[dataGroupingMode]?.previous > 0 ? (
          <span
            style={{
              color: getDeltaColor(
                deltaChange(volume[dataGroupingMode]?.current, volume[dataGroupingMode]?.previous)
              ),
            }}
          >
            {formatPercentage(
              deltaChange(volume[dataGroupingMode]?.current, volume[dataGroupingMode]?.previous)
            )}
            {deltaChange(volume[dataGroupingMode]?.current, volume[dataGroupingMode]?.previous) >
            0 ? (
              <ArrowUpward fontSize="inherit" />
            ) : (
              <ArrowDownward fontSize="inherit" />
            )}
          </span>
        ) : null}
      </Grid>
      <Grid item xs={2}>
        <span style={{ color: palette.neutralDark }}>
          {volume.totalTransactions.current}
          <Home style={{ fontSize: 'small' }} />
        </span>
      </Grid>
    </Grid>
  );
};
