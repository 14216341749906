import React, { useState } from 'react';
import { AppBar, Toolbar, Box, IconButton, Typography } from '@mui/material';
import {ClassNameMap, makeStyles} from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { NavDrawer } from './nav-drawer';
import { NotificationsMenu } from './notifications-menu';
import { FavoritesMenu } from './favorites-menu';
import { AccountMenu } from './account-menu';
import themeProvider from 'theme/ThemeService';
import { useObservable } from '../../shared/utils/useObservable';
import { sessionQuery } from 'state';

const appBarHeight = 64;

const palette = themeProvider.getPalette();

export interface AppBarMenuButtonProps {
  customStyles: ClassNameMap;
  iconSize: 'inherit' | 'large' | 'medium' | 'small';
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `100%`,
    height: appBarHeight,
    backgroundColor: theme.palette.primary.contrastText,
    border: '0px,',
    boxShadow: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: palette.background,
    width: '100vw',
  },
  divider: {
    alignSelf: 'stretch',
    height: 'auto',
    margin: '1rem',
  },
}));

const useAppBarButtonStyles = makeStyles({
  button: {
    border: 'solid thin',
    borderColor: palette.neutral,
    borderRadius: '.25rem',
    padding: '.5rem',
    margin: '0 .125rem',
    color: palette.neutralDark,
  },
  badge: {
    background: palette.logoGradient,
    color: palette.neutralLightest,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const NavLayout: React.FC = ({ children }) => {
  const styles = useStyles();
  const appBarButtonStyles = useAppBarButtonStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isImpersonating = useObservable(sessionQuery.isImpersonating);
  const currentUser = useObservable(sessionQuery.currentUser);

  return (
    <div className={styles.root}>
      <AppBar position="fixed" color="default" className={styles.appBar}>
        <Toolbar>
          <img style={{maxHeight: '75%', marginRight: '20px'}} src="/favicon.ico" alt="Recruit Logo"/>
          <IconButton
            className={styles.menuButton}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setMobileOpen(!mobileOpen)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Box flex={1} />
          {isImpersonating && <Typography noWrap color="red" style={{marginRight: '20px'}}>Currently Impersonating:<br />{currentUser?.name}</Typography>}
          <NotificationsMenu styles={appBarButtonStyles} />
          <FavoritesMenu styles={appBarButtonStyles} />
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <nav>
        <NavDrawer mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} appBarHeight={appBarHeight}/>
      </nav>
      <main className={styles.content}>
        <div className={styles.toolbar} />
        {children}
      </main>
    </div>
  );
};
