import React, { useState, useEffect } from 'react';
import {
  FormProps,
  ErrorDisplay,
  useForm,
  useInput,
  requiredCompanyOffices,
  seatCountValidator,
  kvCoreSid as kvCoreSidValidator,
  ampstatsSid as ampstatsSidValidator,
  email
} from '../../shared/forms';
import {
  FormControl,
  Grid,
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Divider,
  Tooltip,
} from '@mui/material';
import { Button, ConfirmationDialog, TextField } from 'shared/components';
import { EditCompanyForm as EditCompanyFormModel, mlsofficesService } from '../../state/admin';
import { Picker } from '../../search/components/filter-picker';
import { AccountStatus, UpdateOfficeRequest, ExternalIdSourceType } from '../../api/app';
import { FeedModel, OfficeModel } from '../../api/mls';
import { getOfficeDisplayName } from '../../state/search/search.model';
import { Typography } from 'shared/components';
import { DataTable, DataTableColumn } from 'shared/components/data-table';
import { OfficeRegionPicker } from 'admin/components/office-region-picker';
import { useHistory } from 'react-router-dom';
import { SuccessDisplay } from '../../shared/forms/SuccessDisplay';
import { Check, Close, InfoOutlined } from '@mui/icons-material';

interface Props extends FormProps<EditCompanyFormModel> {
  activeForm?: EditCompanyFormModel;
  feedOptions: FeedModel[];
  companies: OfficeModel[];
  offices: OfficeModel[];
  cityOptions: string[];
  numOfUsers: number | undefined;
}

export const EditCompanyForm: React.FC<Props> = ({
  activeForm,
  feedOptions,
  companies,
  offices,
  cityOptions,
  pending,
  success,
  error,
  onSubmit,
  onResetFeedback,
  numOfUsers,
}) => {
  const history = useHistory();
  const [hashTags, setHashTags] = useState<string[]>([]);
  const [showHashTagInput, setShowHashTagInput] = useState(false);
  const [hashTagInputValue, setHashTagInputValue] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showConfirmationOKButton, setShowConfirmationOKButton] = useState(true);

  const updateCompanyKeys = (keys: string[] | undefined) => {
    mlsofficesService.setCompanyKeys(keys);
  };

  const updateFeeds = (feeds: string[] | undefined) => {
    const parsedFeeds = feeds?.map(f => parseInt(f));
    mlsofficesService.setFeeds(parsedFeeds);
    const filteredKeys = companyCodes.value?.filter(key => {
      const office = companies.find((c: OfficeModel) => c.officeKey === key);
      return parsedFeeds?.includes(office?.feed ?? -1);
    });
    companyCodes.setValue(filteredKeys);
    updateCompanyKeys(filteredKeys);
  };

  const [companyOffices, setCompanyOffices] = useState<UpdateOfficeRequest[]>([]);
  const [accountStatus, setAccountStatus] = useState<AccountStatus>(activeForm?.accountStatus ?? AccountStatus.Active);
  const [accountStatusChanged, setAccountStatusChanged] = useState<boolean>(false);
  const [kvCore, setKvCore] = useState<boolean>(activeForm?.kvCore ?? false);
  const [isEnterprise, setIsEnterprise] = useState<boolean>(activeForm?.isEnterprise ?? false);
  const [enterpriseType, setEnterpriseType] = useState<ExternalIdSourceType>(activeForm?.enterpriseType ?? ExternalIdSourceType.Remax);
  const name = useInput<string>(activeForm?.name ?? '');
  const kvCoreAccountId = useInput<string>(activeForm?.kvCoreAccountId ?? '');
  const kvCoreAccessToken = useInput<string>(activeForm?.kvCoreAccessToken ?? '');
  const seatCount = useInput<number>(activeForm?.seatCount ?? 0, {
    validators: [seatCountValidator(numOfUsers ?? 0, kvCore)],
  });
  const feeds = useInput<string[] | undefined>(activeForm?.feeds.map(f => f.toString()) ?? [], {
    onChange: value => updateFeeds(value),
  });
  const companyCodes = useInput<string[] | undefined>(activeForm?.companyCodes ?? [], {
    validators: [requiredCompanyOffices()],
    onChange: value => updateCompanyKeys(value),
  });
  const kvCoreSid = useInput<string>(activeForm?.kvCoreSid ?? '', {
    validators: [kvCoreSidValidator(accountStatus, kvCore)]
  });
  const ampstatsSid = useInput<string>(activeForm?.ampstatsSid ?? '', {
    validators: [ampstatsSidValidator(accountStatus)]
  });
  const kvCoreMainEmail = useInput<string>(activeForm?.kvCoreMainEmail ?? '', {
    validators: [email()]
  });

  const confirmRemoveHashTag = (selectedHashTag: any) => {
    setHashTagInputValue(selectedHashTag);
    if (selectedHashTag === '#ampstats') {
      setConfirmationMessage(`Are you sure you want to delete the ${selectedHashTag} hashtag?`);
      setShowConfirmationOKButton(true);
      setConfirmationOpen(true);
    } else {
      removeHashTag(selectedHashTag);
    }
  };

  const removeHashTag = (selectedHashTag: any) => {
    let companyHashTags = [...hashTags];
    companyHashTags = companyHashTags.filter(h => h !== selectedHashTag);
    setHashTags(companyHashTags);
  };

  const addHashTag = () => {
    let companyHashTags = [...hashTags];
    let newCompanyHashTag = hashTagInputValue;
    if (newCompanyHashTag.length > 0) {
      if (newCompanyHashTag[0] !== '#') {
        newCompanyHashTag = '#' + newCompanyHashTag;
      }

      if (!companyHashTags.includes(newCompanyHashTag)) {
        companyHashTags.push(newCompanyHashTag);
        setHashTags(companyHashTags);
        setHashTagInputValue('');
      } else {
        setConfirmationMessage(`The ${newCompanyHashTag} hashtag already exists`);
        setShowConfirmationOKButton(false);
        setConfirmationOpen(true);
      }
    }
  };

  const changeHashTagInput = (event: any) => {
    if (/^[A-Za-z0-9]*$/.test(event.target.value)) {
      setHashTagInputValue(event.target.value);
    } else {
      event.target.value = event.target.value.substring(0, event.target.value.length - 1);
    }
  };

  const updateOfficeCities = (officeKey: string, cities: string[]) => {
    setCompanyOffices((prevOffices) => {
      return prevOffices.map((office) => {
        if (office.codes[0] === officeKey) {
          return {
            ...office,
            cities: cities,
          };
        }
        return office;
      });
    });
  }

  const form = useForm(
    () => {
      if (!form.valid) return;

      onResetFeedback();
      onSubmit({
        name: name.value,
        feeds: feeds.value?.map(f => parseInt(f)) ?? [],
        companyCodes: companyCodes.value,
        hashTags: hashTags,
        kvCoreAccountId: kvCoreAccountId.value,
        kvCoreAccessToken: kvCoreAccessToken.value,
        accountStatus: accountStatus as AccountStatus,
        kvCore: kvCore,
        seatCount: seatCount.value,
        kvCoreSid: kvCoreSid.value,
        ampstatsSid: ampstatsSid.value,
        kvCoreMainEmail: kvCoreMainEmail.value,
        isEnterprise: isEnterprise,
        enterpriseType: enterpriseType,
        offices: companyOffices,
      });
    },
    name,
    feeds,
    companyCodes,
    seatCount,
    kvCoreSid,
    ampstatsSid,
    kvCoreMainEmail
  );

  const columns: DataTableColumn<UpdateOfficeRequest>[] = [
    {
      headerName: 'Office',
      field: 'name',
      renderCell: ({ row }) => `${row.name} (${row.officeNumber})`,
      flex: 2,
      valueGetter: (value, row) => row.name,
    },
    {
      headerName: 'Market Region',
      field: 'officeKey',
      renderCell: ({ row }) => (
        <OfficeRegionPicker
          label="City/Town"
          input={row.cities ?? undefined}
          onChange={value => updateOfficeCities(row.codes[0], value)}
          options={cityOptions}
        />
      ),
      sortable: false,
      filterable: false,
      flex: 4,
    },
  ];

  useEffect(() => {
    if (activeForm?.hashTags) {
      const hashTags: string[] = activeForm?.hashTags;
      setHashTags(hashTags);
    }
  }, [activeForm?.hashTags, setHashTags]);

  useEffect(() => {
    const transformedOffices = offices.map(o => {
      const matchedOffice = activeForm?.offices.find(o2 => o2.codes[0] === o.officeKey);
      return {
        name: o.officeName,
        officeId: matchedOffice ? matchedOffice.officeId : null,
        officeNumber: o.officeNumber,
        codes: [
          o.officeKey
        ],
        cities: matchedOffice ? matchedOffice.cities : []
      } as UpdateOfficeRequest;
    });
    setCompanyOffices(transformedOffices ?? []);
  }, [activeForm?.offices, offices]);

  const back = () => {
    history.goBack();
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={confirmationOpen}
        title="Confirm"
        onConfirm={() => {
          if (showConfirmationOKButton) {
            setConfirmationOpen(false);
            setConfirmationMessage('');
            let companyHashTags = [...hashTags];
            companyHashTags = companyHashTags.filter(h => h !== hashTagInputValue);
            setHashTags(companyHashTags);
          } else {
            setConfirmationOpen(false);
            setConfirmationMessage('');
          }
        }}
        onCancel={() => {
          setConfirmationOpen(false);
          setConfirmationMessage('');
        }}
        confirmButtonProps={{
          label: showConfirmationOKButton ? 'Confirm' : 'OK',
        }}
        onClose={() => setConfirmationOpen(false)}
      >
        <>
          <ErrorDisplay error={error} />
          <Typography>{confirmationMessage}</Typography>
        </>
      </ConfirmationDialog>

      <ConfirmationDialog
        title="Change Status"
        isOpen={accountStatusChanged}
        onConfirm={() => setAccountStatusChanged(false)}
        onCancel={() => {
          setAccountStatusChanged(false);
          setAccountStatus(activeForm?.accountStatus ?? AccountStatus.Active);
        }}
      >
        <Typography>
          You are changing the status of this account to <strong>{accountStatus}</strong>. You
          will still need to save these changes if confirmed. Do you want to Proceed?
        </Typography>
      </ConfirmationDialog>

      <form {...form.bind}>
        {!!error ? (
          <Box marginBottom={4}>
            <ErrorDisplay error={error} />
          </Box>
        ) : null}

        {success ? (
          <Box marginBottom={4}>
            <SuccessDisplay
              display={success || false}
              successMessage={
                !!activeForm
                  ? "The company's data has been saved."
                  : 'The company has been created.'
              }
            />
          </Box>
        ) : null}

        <Grid container item xs={12} spacing={6}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">{!!activeForm ? 'Edit' : 'New'} Company</Typography>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  {...name.bind}
                  label="Company Name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth={true} variant="outlined">
                  <InputLabel variant="outlined" id="status-label">
                    Account Status
                  </InputLabel>
                  <Select
                    labelId="status-label"
                    label="Account Status"
                    value={accountStatus}
                    onChange={event => {
                      setAccountStatus(event.target.value as AccountStatus);
                      if (event.target.value as AccountStatus !== (activeForm?.accountStatus ?? AccountStatus.Active)) {
                        setAccountStatusChanged(true);
                      }
                    }}
                  >
                    {Object.entries(AccountStatus).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        {key === 'Demo' ? 'Demo Account' : key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth={true} variant="outlined">
                  <InputLabel variant="outlined" id="integration-label">
                    Integrated With BoldTrail
                  </InputLabel>
                  <Select
                    labelId="integration-label"
                    label="Integrated With BoldTrail"
                    value={kvCore}
                    onChange={event => {
                      setKvCore((event.target.value as string) === "true" ? true : false);
                    }}
                  >
                    <MenuItem key="Yes" value="true">
                      Yes
                    </MenuItem>
                    <MenuItem key="No" value="false">
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {kvCore &&
                (<>
                  <Grid item xs={12}>
                    <TextField
                      name="kvCoreSid"
                      {...kvCoreSid.bind}
                      label="BoldTrail SID"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="kvCoreAccountId"
                      {...kvCoreAccountId.bind}
                      label="BoldTrail Account ID"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="kvCoreAccessToken"
                      {...kvCoreAccessToken.bind}
                      label="BoldTrail Access Token"
                      fullWidth
                    />
                  </Grid>
                  <Grid container item xs={12} alignItems="center" spacing={1}>
                    <Grid item xs={11}>
                      <TextField
                        name="kvCoreMainEmail"
                        {...kvCoreMainEmail.bind}
                        label="BoldTrail Main Account Email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title="This should be the top level email address for the user in BoldTrail that this BoldTrail Recruit account will be integrated with">
                        <InfoOutlined />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </>)
              }
              <Grid item xs={12}>
                <FormControl fullWidth={true} variant="outlined">
                  <InputLabel variant="outlined" id="is-enterprise-label">
                    Is Enterprise Account
                  </InputLabel>
                  <Select
                    labelId="is-enterprise-label"
                    label="Is Enterprise Account"
                    value={isEnterprise}
                    onChange={event => {
                      let value = (event.target.value as string) === "true" ? true : false
                      setIsEnterprise(value);
                    }}
                  >
                    <MenuItem key="Yes" value="true">
                      Yes
                    </MenuItem>
                    <MenuItem key="No" value="false">
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {isEnterprise &&
                <Grid item xs={12}>
                  <FormControl fullWidth={true} variant="outlined">
                    <InputLabel variant="outlined" id="enterprise-type-label">
                      Enterprise Type
                    </InputLabel>
                    <Select
                      labelId="enterprise-type-label"
                      label="Enterprise Type"
                      value={enterpriseType}
                      onChange={event => setEnterpriseType(event.target.value as ExternalIdSourceType)}
                    >
                      {Object.entries(ExternalIdSourceType).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              }
              <Grid item xs={12}>
                <TextField
                  name="ampstatsSid"
                  {...ampstatsSid.bind}
                  label="BoldTrail Recruit SID"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="seatCount"
                  type="number"
                  {...seatCount.bind}
                  label="Seat Count"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Picker
                  label="MLS Feed"
                  input={feeds}
                  options={feedOptions}
                  optionValue={(f: FeedModel) => f.id.toString()}
                  optionText={(f: FeedModel) => f.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Picker
                  label="Company"
                  input={companyCodes}
                  options={companies}
                  optionValue={(o: OfficeModel) => o.officeKey}
                  optionText={(o: OfficeModel) => getOfficeDisplayName(o)}
                />
                {(!companyCodes || companyCodes.value?.length === 0) && (
                  <div>You must assign at least one company before submitting form.</div>
                )}
              </Grid>
              <Grid item xs={12} marginTop={2}>
                <InputLabel>HashTags</InputLabel>
                <Divider style={{ background: 'rgba(0, 0, 0, 0.42)' }} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0.8}>
                  {hashTags.map(text => (
                    <Grid item key={text}>
                      <Chip label={text} onDelete={() => confirmRemoveHashTag(text)} />
                    </Grid>
                  ))}
                </Grid>

                {showHashTagInput && (
                  <TextField
                    onChange={changeHashTagInput}
                    startAdornment={
                      <InputAdornment position="start" style={{ backgroundColor: '#efefef' }}>
                        #
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowHashTagInput(false);
                            addHashTag();
                          }}
                          edge="end"
                          color="primary"
                          size="large"
                        >
                          <Check />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setHashTagInputValue('');
                            setShowHashTagInput(false);
                          }}
                          edge="end"
                          color="primary"
                          size="large"
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                {!showHashTagInput && (
                  <div style={{ float: 'right' }}>
                    <Button
                      onClick={() => {
                        setShowHashTagInput(!showHashTagInput);
                      }}
                    >
                      Add
                    </Button>
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                {companyOffices && companyOffices.length > 0 && (
                  <DataTable
                    rows={companyOffices}
                    columns={columns}
                    getRowId={(row) => row.officeNumber!}
                    initialState={{
                      sorting: {
                        sortModel: [
                          {
                            field: 'office',
                            sort: 'asc',
                          },
                        ],
                      },
                    }}
                  />
                )}
                {(!companyOffices || companyOffices.length === 0) && (
                  <div>
                    Select one or more companies to view their offices and designate each office's
                    market region.
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button disabled={!form.valid} pending={pending}>
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button type="button" onClick={() => back()} variant="secondary">
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
