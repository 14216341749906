import React from 'react';
import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { NumberFormatters } from '../../shared';
import { Block } from 'shared/components/block';
import { OfficeVolumeSummaryTooltip } from '../../shared/components/office-volume-summary-tooltip';
import { useOfficeDetails } from '../../state/search/search.hooks';

interface Props {
  officeKey: string;
  startDate: string | undefined;
  endDate: string | undefined;
}

export const OfficeInfo: React.FC<Props> = ({ officeKey, startDate, endDate }) => {
  const officeData = useOfficeDetails(officeKey, startDate, endDate);
  if (!officeData || officeData?.officeKey !== officeKey) return null;

  return (
    <Card>
      <CardHeader
        title={`${officeData.officeName}:
          ${officeData.address}
          ${officeData.city},
          ${officeData.state}
          ${officeData.postalCode}`}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xl lg={4} xs={12} sm={6}>
            <Block title="List Side Count">{officeData.listSideCount}</Block>
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <Block title="List Side Volume">
              {NumberFormatters.currency`${officeData.listSideVolume || 0}`}
            </Block>
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <Block title="Sell Side Count">{officeData.sellSideCount}</Block>
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <Block title="Sell Side Volume">
              {NumberFormatters.currency`${officeData.sellSideVolume || 0}`}
            </Block>
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <Block title="Total Count">{officeData.totalCount}</Block>
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <Block title="Total Volume">
              {NumberFormatters.currency`${officeData.totalVolume || 0}`}
            </Block>
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <Block title="Average Price">
              {NumberFormatters.currency`${officeData.totalVolume / officeData.totalCount || 0}`}
            </Block>
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <Block title="DOM">{officeData.daysOnMarket}</Block>
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <Block title="TREND">
              <OfficeVolumeSummaryTooltip
                officeKey={officeKey}
                officeName={officeData.officeName}
              />
            </Block>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
