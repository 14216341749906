import React from 'react';
import {icons, PageHeader } from 'shared';
import { AgentSurveyList } from '../components';

export const AgentSurveyPage: React.FC = () => {
  return (
    <>
      <PageHeader
        title="AGENT SURVEY REPORT"
        icon={icons.pieChart}
      />
      <AgentSurveyList />
    </>
  );
}
