import { ThemeColors } from '../../shared';
import {
  SvgIconComponent,
  TrendingUp,
  AccountCircle,
  Language,
  Apartment,
  Home,
  Fireplace,
} from '@mui/icons-material';
import { SearchType } from '../../api/mls';

export enum Filter {
  DateRange = 1,
  Board = 2,
  Company = 3,
  Office = 4,
  Geography = 5,
  Listing = 6,
  AgentInfo = 7,
  Production = 8,
  HotList = 9,
}

export type ReportType = {
  label: string;
  filters: Filter[];
  color: ThemeColors;
  icon: SvgIconComponent;
  type: SearchType;
};

export const reportTypes: ReportType[] = [
  {
    label: 'Market Share',
    filters: [
      Filter.DateRange,
      Filter.Board,
      Filter.Company,
      Filter.Office,
      Filter.Geography,
      Filter.Listing,
    ],
    color: ThemeColors.Teal,
    icon: TrendingUp,
    type: SearchType.MarketShare,
  },
  {
    label: 'Prospect Sourcing',
    filters: [
      Filter.DateRange,
      Filter.Board,
      Filter.Company,
      Filter.Office,
      Filter.Geography,
      Filter.Listing,
      Filter.AgentInfo,
      Filter.Production,
    ],
    color: ThemeColors.Blue,
    icon: AccountCircle,
    type: SearchType.Prospect,
  },
  {
    label: 'Hot List',
    filters: [Filter.Board, Filter.Company, Filter.Office, Filter.HotList],
    color: ThemeColors.Orange,
    icon: Fireplace,
    type: SearchType.HotList,
  },
  {
    label: 'By Region',
    filters: [Filter.Board, Filter.DateRange, Filter.Geography, Filter.AgentInfo],
    color: ThemeColors.Yellow,
    icon: Language,
    type: SearchType.Region,
  },
  {
    label: 'By Company',
    filters: [Filter.DateRange, Filter.Board, Filter.Company],
    color: ThemeColors.Green,
    icon: Apartment,
    type: SearchType.Company,
  },
  {
    label: 'By Office',
    filters: [Filter.DateRange, Filter.Board, Filter.Company, Filter.Office],
    color: ThemeColors.Magenta,
    icon: Home,
    type: SearchType.Office,
  },
];
