import { asErrorBoundry, Button, ButtonLink } from '../../shared';
import { Card, CardHeader, CardContent, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { ManagerList } from '../components';
import { useCompany, useCompanyManagers } from '../hooks';
import { ArrowBack } from '@mui/icons-material';
import { useState } from 'react';

export const ViewCompanyPage = asErrorBoundry(() => {
  const companyId = parseInt(useParams<{ companyId: string }>().companyId);
  const { company } = useCompany(companyId);
  const managers = useCompanyManagers(companyId);
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);

  const backButtonClick = () => {
    history.push('/admin');
  };

  const handleNewUser = () => {
    if (!company.kvCore && company.seatCount <= managers.length) {
      setOpenDialog(true);
    } else {
      history.push('/admin/companies/' + company.id + '/users/create');
    }
  }

  if (!company) return null;
  return (
    <>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>This account only has {company.seatCount} available seats. Increase the seat count for this company to add new users.</DialogContent>
        <DialogActions>
          <Grid container gap={2} m={2}>
            <Grid item>
              <Button onClick={() => setOpenDialog(false)}>
                Ok
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader title={company.name}></CardHeader>
        <CardContent>
          <Grid container item spacing={2} xs={12}>
            <Grid item>
              {history.length > 0 && (
                <Button onClick={() => backButtonClick()} variant="subtle">
                  <ArrowBack fontSize="small" />
                </Button>
              )}
            </Grid>
            <Grid item>
              <ButtonLink to={'/admin/companies/' + company.id + '/edit'} variant="secondary">
                Edit Company
              </ButtonLink>
            </Grid>
            {!company.kvCore && (
              <Grid item>
                <Button onClick={handleNewUser} variant="secondary">
                  New User
                </Button>
              </Grid>
            )}
            {company.kvCore && (
              <Grid item xs={12}>
                <Typography>Users cannot be added from here as this account is integrated with BoldTrail. New users are automatically added when they SSO from BoldTrail into BoldTrail Recruit for the first time.</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <ManagerList company={company} managers={managers} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
});
