import { applyTransaction, setLoading } from "@datorama/akita";
import { ReportsApi } from "api/app";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import { EnterpriseAdoptionReportStore, enterpriseAdoptionReportStore, initialState } from "./enterprise-adoption.store";
import { GetAdoptionStatsRequest } from "./enterprise-adoption.models";
import saveAs from "file-saver";

export class EnterpriseAdoptionReportService {
  constructor(
    private readonly store: EnterpriseAdoptionReportStore,
    private readonly api: ReportsApi
  ) {}

  getAdoptionStats(params: GetAdoptionStatsRequest) {
    this.store.update(state => ({
      ...state,
      data: initialState.data,
      count: initialState.count
    }));

    from(this.api.reportsEnterpriseAdoptionGet(params.source, params.orderBy, params.isDescending, params.pageNum, params.pageSize))
      .pipe(
        setLoading(this.store),
        map(response => response.data)
      ).subscribe(data =>
      applyTransaction(() => {
        this.store.update({
          data: data.data,
          count: data.count,
        });
      })
    );
  }

  exportEnterpriseAdoptionReport(params: GetAdoptionStatsRequest): Promise<void> {
    if (!params.source) return new Promise<void>(() => {});

    return new Promise<void>((resolve, reject) => {
      from(
        this.api.reportsEnterpriseAdoptionExportGet(
          params.source,
          params.orderBy,
          params.isDescending,
          params.pageNum,
          params.pageSize,
          { responseType: 'blob' }
        )
      ).subscribe(
        response => {
          saveAs(response.data as any, 'EnterpriseAdoptionReport.csv');
          resolve(); // Resolve the promise when the download is complete
        },
        error => {
          reject(error); // Reject the promise if there's an error
        }
      );
    });
  }

}

export const enterpriseAdoptionReportService = new EnterpriseAdoptionReportService(
  enterpriseAdoptionReportStore,
  new ReportsApi()
);
