import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { QuickFilterButton } from '../../shared/forms';
import { Typography } from 'shared/components';

export enum HotListType {
  Inactive = 'Agents with 0 Active',
  New = 'Newly Licensed Agents',
  Moved = 'Agents who Moved',
}

interface HotListProps {
  title?: string;
  enabledFilters: HotListType[];
  disabled?: boolean;
  defaultFilter: HotListType;
  setHotList(selectedFilter: HotListType | null): void;
}

export function FilterHotList({
  title,
  enabledFilters,
  disabled = false,
  defaultFilter,
  setHotList,
}: HotListProps): React.ReactElement {
  const [filterSelection, setFilterSelection] = useState(defaultFilter);

  const getLabel = (filter: HotListType): string => {
    if (filter === HotListType.Inactive)
      return 'Agents with 0 active, pending.No closings in last 60 days and YOY sales volume down 20%+';
    else if (filter === HotListType.New)
      return 'Newly licensed agents from 10-12 months ago with at least 3 deals or $1 million in sales';
    else if (filter === HotListType.Moved)
      return 'Agents who moved 10-12 months ago and business is down 10% +';
    return '';
  };

  // adding setHotList to deps array will cause infinite render loop
  useEffect(() => {
    if (filterSelection !== null) setHotList(filterSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelection]);

  return (
    <>
      <Grid container spacing={2}>
        {title ? (
          <Grid item>
            <Typography variant="subtitle" align="center">
              {title}
            </Typography>
          </Grid>
        ) : undefined}
        {enabledFilters.map(filter => (
          <Grid item md={4} key={filter} style={{ display: 'flex', width: '100%' }}>
            <QuickFilterButton
              label={getLabel(filter)}
              filter={filter}
              active={filterSelection === filter}
              disabled={disabled}
              onSelection={setFilterSelection}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
