import { asErrorBoundry, icons, PageHeader, SearchInput } from '../../shared';
import { ButtonLink } from 'shared/components/button-link';
import {Card, CardContent, Grid } from '@mui/material';
import { CompanyList } from '../components';
import { useCompanies } from '../hooks';
import { useEffect, useState} from 'react';
import { CompanyModel } from 'api/app';

export const AdminDashboardPage = asErrorBoundry(() => {
  const [filterValue, setFilterValue] = useState<string>("");
  const [debouncedFilterValue, setDebouncedFilterValue] = useState<string>("");
  const [tableData, setTableData] = useState<CompanyModel[]>([]);

  const companies = useCompanies(debouncedFilterValue);

  useEffect(() => {
    setTableData(companies);
  }, [companies]);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedFilterValue(filterValue), 500)

    return () => clearTimeout(timer)
  }, [filterValue]);

  return (
    <>
      <PageHeader
        title="COMPANY MANAGEMENT"
        icon={icons.admin}
      />
      <Card>
        <CardContent>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <div style={{ width: '500px' }}>
                    <SearchInput
                      value={filterValue}
                      placeholder="Search by company, user, or SID..."
                      onChange={(event) => setFilterValue(event.target.value)}
                      fullWidth
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  xs={4}
                  justifyContent="flex-end"
                >
                  <ButtonLink to="/admin/companies/create">
                    + New Company
                  </ButtonLink>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CompanyList companies={tableData} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
});
