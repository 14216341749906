/* eslint-disable */
// tslint:disable
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ExternalIdSourceType } from '../model';
import { GetAdoptionStatsResponse } from '../model';
import { GetAgentSurveyAverageFiltersResponse } from '../model';
import { GetAgentSurveyAveragesRequest } from '../model';
import { GetAgentSurveyAveragesResult } from '../model';
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAgentSurveyAveragesRequest} [getAgentSurveyAveragesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAgentSurveysExportPost(getAgentSurveyAveragesRequest?: GetAgentSurveyAveragesRequest, options: any = {}): RequestArgs {
            const localVarPath = `/reports/agent-surveys-export`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof getAgentSurveyAveragesRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(getAgentSurveyAveragesRequest !== undefined ? getAgentSurveyAveragesRequest : {}) : (getAgentSurveyAveragesRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAgentSurveysFiltersGet(startDate?: string, endDate?: string, options: any = {}): RequestArgs {
            const localVarPath = `/reports/agent-surveys-filters`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetAgentSurveyAveragesRequest} [getAgentSurveyAveragesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAgentSurveysPost(getAgentSurveyAveragesRequest?: GetAgentSurveyAveragesRequest, options: any = {}): RequestArgs {
            const localVarPath = `/reports/agent-surveys`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof getAgentSurveyAveragesRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(getAgentSurveyAveragesRequest !== undefined ? getAgentSurveyAveragesRequest : {}) : (getAgentSurveyAveragesRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExternalIdSourceType} source 
         * @param {string} orderBy 
         * @param {boolean} isDescending 
         * @param {number} pageNum 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsEnterpriseAdoptionExportGet(source: ExternalIdSourceType, orderBy: string, isDescending: boolean, pageNum: number, pageSize: number, options: any = {}): RequestArgs {
            // verify required parameter 'source' is not null or undefined
            if (source === null || source === undefined) {
                throw new RequiredError('source','Required parameter source was null or undefined when calling reportsEnterpriseAdoptionExportGet.');
            }
            // verify required parameter 'orderBy' is not null or undefined
            if (orderBy === null || orderBy === undefined) {
                throw new RequiredError('orderBy','Required parameter orderBy was null or undefined when calling reportsEnterpriseAdoptionExportGet.');
            }
            // verify required parameter 'isDescending' is not null or undefined
            if (isDescending === null || isDescending === undefined) {
                throw new RequiredError('isDescending','Required parameter isDescending was null or undefined when calling reportsEnterpriseAdoptionExportGet.');
            }
            // verify required parameter 'pageNum' is not null or undefined
            if (pageNum === null || pageNum === undefined) {
                throw new RequiredError('pageNum','Required parameter pageNum was null or undefined when calling reportsEnterpriseAdoptionExportGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (pageSize === null || pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling reportsEnterpriseAdoptionExportGet.');
            }
            const localVarPath = `/reports/enterprise-adoption/export`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (source !== undefined) {
                localVarQueryParameter['Source'] = source;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (isDescending !== undefined) {
                localVarQueryParameter['IsDescending'] = isDescending;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['PageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExternalIdSourceType} source 
         * @param {string} orderBy 
         * @param {boolean} isDescending 
         * @param {number} pageNum 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsEnterpriseAdoptionGet(source: ExternalIdSourceType, orderBy: string, isDescending: boolean, pageNum: number, pageSize: number, options: any = {}): RequestArgs {
            // verify required parameter 'source' is not null or undefined
            if (source === null || source === undefined) {
                throw new RequiredError('source','Required parameter source was null or undefined when calling reportsEnterpriseAdoptionGet.');
            }
            // verify required parameter 'orderBy' is not null or undefined
            if (orderBy === null || orderBy === undefined) {
                throw new RequiredError('orderBy','Required parameter orderBy was null or undefined when calling reportsEnterpriseAdoptionGet.');
            }
            // verify required parameter 'isDescending' is not null or undefined
            if (isDescending === null || isDescending === undefined) {
                throw new RequiredError('isDescending','Required parameter isDescending was null or undefined when calling reportsEnterpriseAdoptionGet.');
            }
            // verify required parameter 'pageNum' is not null or undefined
            if (pageNum === null || pageNum === undefined) {
                throw new RequiredError('pageNum','Required parameter pageNum was null or undefined when calling reportsEnterpriseAdoptionGet.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (pageSize === null || pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling reportsEnterpriseAdoptionGet.');
            }
            const localVarPath = `/reports/enterprise-adoption`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (source !== undefined) {
                localVarQueryParameter['Source'] = source;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (isDescending !== undefined) {
                localVarQueryParameter['IsDescending'] = isDescending;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['PageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAgentSurveyAveragesRequest} [getAgentSurveyAveragesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAgentSurveysExportPost(getAgentSurveyAveragesRequest?: GetAgentSurveyAveragesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).reportsAgentSurveysExportPost(getAgentSurveyAveragesRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAgentSurveysFiltersGet(startDate?: string, endDate?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgentSurveyAverageFiltersResponse> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).reportsAgentSurveysFiltersGet(startDate, endDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetAgentSurveyAveragesRequest} [getAgentSurveyAveragesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAgentSurveysPost(getAgentSurveyAveragesRequest?: GetAgentSurveyAveragesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgentSurveyAveragesResult> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).reportsAgentSurveysPost(getAgentSurveyAveragesRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ExternalIdSourceType} source 
         * @param {string} orderBy 
         * @param {boolean} isDescending 
         * @param {number} pageNum 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsEnterpriseAdoptionExportGet(source: ExternalIdSourceType, orderBy: string, isDescending: boolean, pageNum: number, pageSize: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).reportsEnterpriseAdoptionExportGet(source, orderBy, isDescending, pageNum, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ExternalIdSourceType} source 
         * @param {string} orderBy 
         * @param {boolean} isDescending 
         * @param {number} pageNum 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsEnterpriseAdoptionGet(source: ExternalIdSourceType, orderBy: string, isDescending: boolean, pageNum: number, pageSize: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdoptionStatsResponse> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).reportsEnterpriseAdoptionGet(source, orderBy, isDescending, pageNum, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {GetAgentSurveyAveragesRequest} [getAgentSurveyAveragesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAgentSurveysExportPost(getAgentSurveyAveragesRequest?: GetAgentSurveyAveragesRequest, options?: any): AxiosPromise<void> {
            return ReportsApiFp(configuration).reportsAgentSurveysExportPost(getAgentSurveyAveragesRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAgentSurveysFiltersGet(startDate?: string, endDate?: string, options?: any): AxiosPromise<GetAgentSurveyAverageFiltersResponse> {
            return ReportsApiFp(configuration).reportsAgentSurveysFiltersGet(startDate, endDate, options)(axios, basePath);
        },
        /**
         * 
         * @param {GetAgentSurveyAveragesRequest} [getAgentSurveyAveragesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsAgentSurveysPost(getAgentSurveyAveragesRequest?: GetAgentSurveyAveragesRequest, options?: any): AxiosPromise<GetAgentSurveyAveragesResult> {
            return ReportsApiFp(configuration).reportsAgentSurveysPost(getAgentSurveyAveragesRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {ExternalIdSourceType} source 
         * @param {string} orderBy 
         * @param {boolean} isDescending 
         * @param {number} pageNum 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsEnterpriseAdoptionExportGet(source: ExternalIdSourceType, orderBy: string, isDescending: boolean, pageNum: number, pageSize: number, options?: any): AxiosPromise<void> {
            return ReportsApiFp(configuration).reportsEnterpriseAdoptionExportGet(source, orderBy, isDescending, pageNum, pageSize, options)(axios, basePath);
        },
        /**
         * 
         * @param {ExternalIdSourceType} source 
         * @param {string} orderBy 
         * @param {boolean} isDescending 
         * @param {number} pageNum 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsEnterpriseAdoptionGet(source: ExternalIdSourceType, orderBy: string, isDescending: boolean, pageNum: number, pageSize: number, options?: any): AxiosPromise<GetAdoptionStatsResponse> {
            return ReportsApiFp(configuration).reportsEnterpriseAdoptionGet(source, orderBy, isDescending, pageNum, pageSize, options)(axios, basePath);
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @param {GetAgentSurveyAveragesRequest} [getAgentSurveyAveragesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsAgentSurveysExportPost(getAgentSurveyAveragesRequest?: GetAgentSurveyAveragesRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsAgentSurveysExportPost(getAgentSurveyAveragesRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsAgentSurveysFiltersGet(startDate?: string, endDate?: string, options?: any) {
        return ReportsApiFp(this.configuration).reportsAgentSurveysFiltersGet(startDate, endDate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {GetAgentSurveyAveragesRequest} [getAgentSurveyAveragesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsAgentSurveysPost(getAgentSurveyAveragesRequest?: GetAgentSurveyAveragesRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsAgentSurveysPost(getAgentSurveyAveragesRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ExternalIdSourceType} source 
     * @param {string} orderBy 
     * @param {boolean} isDescending 
     * @param {number} pageNum 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsEnterpriseAdoptionExportGet(source: ExternalIdSourceType, orderBy: string, isDescending: boolean, pageNum: number, pageSize: number, options?: any) {
        return ReportsApiFp(this.configuration).reportsEnterpriseAdoptionExportGet(source, orderBy, isDescending, pageNum, pageSize, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ExternalIdSourceType} source 
     * @param {string} orderBy 
     * @param {boolean} isDescending 
     * @param {number} pageNum 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsEnterpriseAdoptionGet(source: ExternalIdSourceType, orderBy: string, isDescending: boolean, pageNum: number, pageSize: number, options?: any) {
        return ReportsApiFp(this.configuration).reportsEnterpriseAdoptionGet(source, orderBy, isDescending, pageNum, pageSize, options)(this.axios, this.basePath);
    }

}
