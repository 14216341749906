import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Theme, Collapse, Grid } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { ProfileForm, RecruitingGoalForm } from '../components';
import { UserForm, UserForms } from '../../state/users';
import { RecruitingGoalForm as GoalForm, ManagerForms, managerService } from '../../state/managers';
import { useFormEvents } from '../../shared/forms';
import { FormStatus } from '../../state';
import { userService } from '../../state/users/user.service';
import { ChangePasswordRequest, UpdateProfileRequest } from '../../api/auth/model';
import { useActiveUserProfile } from '../hooks';
import { Typography, Alert } from 'shared/components';

import { useHistory } from 'react-router-dom';
import { useManager, withManagerId } from '../../shared';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(2),
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);

interface Props {
  managerId: string;
}

const Profile: React.FC<Props> = ({ managerId }) => {
  const user = useActiveUserProfile();
  const [open, setOpen] = useState(user?.mustChangePassword ?? false);
  const classes = useStyles();
  const history = useHistory();

  const { recruitingGoal } = useManager(managerId);

  //Only when we leave this page, will we update that a user's
  //no longer needing to change their password
  //Encountered a bit of a chicken and the egg scenario
  useEffect(() => {
    const unlisten = history.listen(() => {
      userService.patchProfile({
        mustChangePassword: false,
      } as UpdateProfileRequest);
    });
    return unlisten;
  }, [history]);

  const [profileEvents, resetProfileFormUI] = useFormEvents(UserForms.Profile);
  const [recruitingGoalEvents, resetGoalFormUI] = useFormEvents(ManagerForms.RecruitingGoal);
  useFormEvents(
    ManagerForms.NotificationSettings
  );

  const submitProfile = (form: UserForm) => {
    if (form.currentPassword !== '' && form.newPassword !== '')
      userService.changePassword(form as ChangePasswordRequest);
    if (form.email !== user.email) userService.updateProfile(form as UpdateProfileRequest);
  };

  const submitGoal = (form: GoalForm) => {
    managerService.updateRecruitingGoal(form);
  };

  if (!user) return null;

  return (
    <>
      {user.mustChangePassword && (
        <div className={classes.root}>
          <Collapse in={open}>
            <Alert
              title="Attention"
              onClose={() => {
                setOpen(false);
              }}
            >
              It looks like this is the first time you're logging in. Please consider changing your
              password, below.
            </Alert>
          </Collapse>
        </div>
      )}
      <Grid container item xs={12} spacing={2}>
        {!user.isKvCore && (
          <Grid item xs={12}>
            <Card>
              <CardHeader>
                <Typography>My Profile</Typography>
              </CardHeader>
              <CardContent>
                <ProfileForm
                  activeForm={user}
                  onSubmit={submitProfile}
                  onResetFeedback={resetProfileFormUI}
                  error={profileEvents.error}
                  success={profileEvents.status === FormStatus.Success}
                  pending={profileEvents.status === FormStatus.Pending}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <CardHeader>
              <Typography>Set Recruiting Goal</Typography>
            </CardHeader>
            <CardContent>
              <RecruitingGoalForm
                activeForm={recruitingGoal}
                onSubmit={submitGoal}
                onResetFeedback={resetGoalFormUI}
                error={recruitingGoalEvents.error}
                success={recruitingGoalEvents.status === FormStatus.Success}
                pending={recruitingGoalEvents.status === FormStatus.Pending}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export const ProfilePage = withManagerId(Profile);
