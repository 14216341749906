import React from 'react';
import { Container, Grid} from '@mui/material';
import { Button, ButtonLink, Typography, TextField } from 'shared/components';
import {
  useInput,
  required,
  FormProps,
  email as emailValidator,
  useForm,
  ErrorDisplay,
} from '../../shared/forms';
import { ForgotPaswordForm } from '../../state/users';
import { SuccessDisplay } from '../../shared/forms/SuccessDisplay';
import { Email, ArrowBack } from '@mui/icons-material';
import LogoImage from '../../assets/BT-Recruit-Horizontal-Dark.png';

export const ForgotPasswordForm: React.FC<FormProps<ForgotPaswordForm>> = ({
  error,
  onSubmit,
  onResetFeedback,
  success,
}) => {
  const email = useInput('', { validators: [required(), emailValidator()] });

  const form = useForm(() => {
    if (!form.valid) {
      email.setTouched(true);
      return;
    }

    onResetFeedback();
    onSubmit({
      email: email.value,
    });
  }, email);

  return (
    <Container fixed maxWidth="xs">
      <form {...form.bind}>
        <Grid container direction="column" rowSpacing={4}>
          <Grid item>
            <ErrorDisplay
              error={error}
              errorMessage="An error has occurred. Please make sure to enter your email address correctly, and try again."
            />
          </Grid>
          <Grid item>
            <SuccessDisplay
              display={success || false}
              successMessage="An email has been sent to the address provided with instructions on how to reset your password."
            />
          </Grid>
          <Grid item>
            <div>
              <img src={LogoImage} style={{width: '100%'}} alt="BoldTrail Recruit Logo" />
            </div>
          </Grid>
          <Grid item>
            <Typography variant="subtitle" align="center">
              To recover your account, please enter your email address.
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              name="email"
              label="Email"
              {...email.bind}
              autoComplete="email"
              type="email"
              fullWidth
            />
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button type="submit" startIcon={<Email fontSize="small" />} fullWidth>
                Send Recovery Email
              </Button>
            </Grid>
            <Grid item>
              <ButtonLink
                to="/"
                variant="secondary"
                startIcon={<ArrowBack fontSize="small" />}
                fullWidth
              >
                Back to login
              </ButtonLink>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
