import { OfficeModel } from "api/app";

export type ValueFormatter<T> = (strings: TemplateStringsArray, value: T) => string;
export interface FormatterCollection<T> {
  [name: string]: ValueFormatter<T>;
}

const decimalFormatter = new Intl.NumberFormat('en-US', { style: 'decimal' });
const percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent' });
const decimalPercentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // display currency without cents
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const NumberFormatters: FormatterCollection<number> = {
  currency: (strs, val) => `${strs[0]}${currencyFormatter.format(val)}${strs[1]}`,
  shortCurrency: (strs, val) => {
    const { magnitude, suffix } = getCurrencyMagnitude(val);
    return `${strs[0]}${currencyFormatter.format(val / magnitude)}${suffix}${strs[1]}`;
  },
  decimal: (strs, val) => `${strs[0]}${decimalFormatter.format(val)}${strs[1]}`,
  percent: (strs, val) => `${strs[0]}${percentFormatter.format(val)}${strs[1]}`,
  decimalPercent: (strs, val) => `${strs[0]}${decimalPercentFormatter.format(val)}${strs[1]}`,
};

type Magnitude = 1 | 1_000 | 1_000_000 | 1_000_000_000;
interface CurrencyMagnitude {
  magnitude: Magnitude;
  suffix: string;
}

const ONE = 1;
const THOUSAND: Magnitude = 1_000;
const MILLION: Magnitude = 1_000_000;
const BILLION: Magnitude = 1_000_000_000;

function getCurrencyMagnitude(value: number): CurrencyMagnitude {
  const val = Math.abs(value);

  if (val >= BILLION) return { magnitude: BILLION, suffix: 'B' };
  if (val >= MILLION) return { magnitude: MILLION, suffix: 'M' };
  if (val >= THOUSAND) return { magnitude: THOUSAND, suffix: 'K' };

  return { magnitude: ONE, suffix: '' };
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});
const monthYearFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
});
const narrowMonthFormatter = new Intl.DateTimeFormat('en-US', {
  month: 'narrow',
});

export const DateFormatters: FormatterCollection<Date | number | undefined> = {
  shortDate: (strs, val) => `${strs[0]}${dateFormatter.format(val)}${strs[1]}`,
  monthYear: (strs, val) => `${strs[0]}${monthYearFormatter.format(val)}${strs[1]}`,
  narrowMonth: (strs, val) => `${strs[0]}${narrowMonthFormatter.format(val)}${strs[1]}`,
};

export function OfficeAddress(office?: OfficeModel | undefined) {
  if (!office || !office.address) return 'N/A';
  return (office.address + ', ' + office.city + ', ' + office.state + ' ' + office.postalCode).trim();
}
