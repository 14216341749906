import { useEffect } from 'react';

import { recruitsService, recruitsQuery, Recruit, activitiesService } from '../state';
import { useObservable } from '../shared';

export function useRecruit(managerId: string | undefined) {
  const recruits = useObservable(recruitsQuery.recruits);

  useEffect(() => {
    if (!!managerId) {
        recruitsService.getManagerRecruits(managerId);
        activitiesService.getManagerRecruitActivities(managerId);
      }
  }, [managerId]);

  const map = new Map<string, Recruit>(recruits.map(f => [f.memberKey!, f]));

  function isRecruit(memberKey: string) {
    return map.has(memberKey);
  }

  function isRecruited(memberKey: string) {
    return isRecruit(memberKey) && !!map.get(memberKey)?.recruitedDate;
  }

  function toggleRecruit(memberKey: string) {
    var id = recruits.find(x => x.memberKey === memberKey)?.id;
    if (!managerId) return;
    if (isRecruit(memberKey)) {
      recruitsService.deleteRecruit(managerId, id!);
      activitiesService.removeRecruitActivities(id!);
    } else {
      recruitsService.createRecruit(managerId, memberKey);
    }
  }

  return { recruits, isRecruit, isRecruited, toggleRecruit };
}
