import { StoreConfig } from '@datorama/akita';
import {EnterpriseAdoptionStat} from 'api/app';
import { ResettableStore } from '../../util';

export interface EnterpriseAdoptionReportState {
  data: EnterpriseAdoptionStat[];
  count: number;
}

export const initialState: EnterpriseAdoptionReportState = {
  data: [],
  count: 0,
};

@StoreConfig({ name: 'enterpriseAdoptionReport', resettable: true })
export class EnterpriseAdoptionReportStore extends ResettableStore<EnterpriseAdoptionReportState> {
  constructor() {
    super(initialState);
  }
}

export const enterpriseAdoptionReportStore = new EnterpriseAdoptionReportStore();
