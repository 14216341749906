import { InputAdornment, Box, Card, CardHeader, CardContent, Grid } from '@mui/material';
import {
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from 'shared/components';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { NumberFormatters } from 'shared/utils/formatters';
import { FeeScheduleItem, FeeType, FeeCategory } from 'recruiting/pages/net-income-valuation.types';
import { Add, Delete } from '@mui/icons-material';
import { CustomCurrencyInputMasker } from '../../../shared/components/custom-currency-input-masker';
import themeService from 'theme/ThemeService';

interface RecruitingVariableFeeScheduleInterface {
  feeSchedule: FeeScheduleItem[];
  setFeeSchedule: React.Dispatch<React.SetStateAction<FeeScheduleItem[]>>;
  bubbleVariableCurrentFeeTotals: React.Dispatch<React.SetStateAction<number>>;
  bubbleVariableOursFeeTotals: React.Dispatch<React.SetStateAction<number>>;
}

const palette = themeService.getPalette();

const RecruitingVariableFeeScheduleTable: React.FC<RecruitingVariableFeeScheduleInterface> = ({
  feeSchedule,
  setFeeSchedule,
  bubbleVariableCurrentFeeTotals,
  bubbleVariableOursFeeTotals,
}) => {
  const [currentFeeTotals, setCurrentFeeTotals] = useState(0);
  const [oursFeeTotals, setOursFeeTotals] = useState(0);

  //add a fee
  const addFee = () => {
    const newFees = [...feeSchedule];
    const newId = Math.max(...newFees.map(item => item.type));
    newFees.push({ label: '', feeGroup: 'variable', type: newId + 1 });
    setFeeSchedule(newFees);
  };

  useEffect(() => {
    //Calculate current
    const currentFeesTotal = feeSchedule
      .map(fee => (fee.monthlyCostCurrent || 0) * (fee.customMultiplier || 12))
      .reduce((a, b) => a + b, 0);

    if (bubbleVariableCurrentFeeTotals) bubbleVariableCurrentFeeTotals(currentFeesTotal);

    //Calculate ours
    const oursFeesTotal = feeSchedule
      .map(fee => (fee.monthlyCostOurs || 0) * (fee.customMultiplier || 12))
      .reduce((a, b) => a + b, 0);

    if (bubbleVariableOursFeeTotals) bubbleVariableOursFeeTotals(oursFeesTotal);

    setOursFeeTotals(oursFeesTotal);
    setCurrentFeeTotals(currentFeesTotal);
  }, [feeSchedule, bubbleVariableCurrentFeeTotals, bubbleVariableOursFeeTotals]);

  return (
    <Box>
      <Card>
        <CardHeader title={'Variable Fees'} />

        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <TableContainer sx={{border: 'solid thin', borderRadius: '8px', borderColor: palette.neutral}}>
                  <Table size="small">
                    <TableHead>
                      <TableRow style={{ backgroundColor: palette.neutralLight }}>
                        <TableCell style={{ border: '0px none' }}></TableCell>
                        <TableCell style={{ border: '0px none' }}></TableCell>
                        <TableCell
                          align="center"
                          colSpan={2}
                          style={{
                            color: palette.neutralDark,
                            fontSize: '12px'
                          }}
                        >
                          <strong>CURRENT COMPANY</strong>
                        </TableCell>

                        <TableCell
                          align="center"
                          colSpan={2}
                          style={{
                            color: palette.neutralDark,
                            fontSize: '12px'
                          }}
                        >
                          <strong>OUR COMPANY</strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ background: 'white' }}></TableCell>
                        <TableCell style={{ background: 'white' }}></TableCell>
                        <TableCell style={{ background: 'white' }} align="center">
                          Monthly
                        </TableCell>
                        <TableCell style={{ background: 'white' }} align="center">
                          Yearly
                        </TableCell>

                        <TableCell style={{ background: 'white' }} align="center">
                          Monthly
                        </TableCell>
                        <TableCell style={{ background: 'white' }} align="center">
                          Yearly
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {feeSchedule
                        .sort((a, b) => a.type - b.type)
                        .map(row => {
                          return (
                            <VariableFeeRowComponent
                              key={row.type}
                              row={row}
                              setFeeSchedule={setFeeSchedule}
                              feeSchedule={feeSchedule}
                            />
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell style={{ border: '0px none' }}>
                          <Button onClick={() => addFee()} startIcon={<Add />}>
                            Fee
                          </Button>
                        </TableCell>
                        <TableCell
                          align="right"
                          colSpan={2}
                          style={{ border: '0px none', fontSize: '1rem', color: '#4D4D4D' }}
                        >
                          Total:
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: '0px none',
                            fontSize: '1rem',
                            color: '#4D4D4D',
                            fontWeight: 'bold',
                          }}
                        >{NumberFormatters.currency`${currentFeeTotals}`}</TableCell>
                        <TableCell style={{ border: '0px none' }}></TableCell>

                        <TableCell
                          align="center"
                          style={{
                            border: '0px none',
                            fontSize: '1rem',
                            color: '#4D4D4D',
                            fontWeight: 'bold',
                          }}
                        >{NumberFormatters.currency`${oursFeeTotals}`}</TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export const RecruitingVariableFeeScheduleTableComponent = React.memo(
  RecruitingVariableFeeScheduleTable
);

interface VariableFeeRowProps {
  row: FeeScheduleItem;
  setFeeSchedule: Function;
  feeSchedule: FeeScheduleItem[];
}
const VariableFeeRow: React.FC<VariableFeeRowProps> = ({ row, setFeeSchedule, feeSchedule }) => {
  //remove a fee
  const removeFee = (feeType: FeeType) => {
    setFeeSchedule(feeSchedule.filter(fee => fee.type !== feeType));
  };

  //update the fee name
  const handleFeeNameUpdate = (event: ChangeEvent<HTMLInputElement>, type: FeeType) => {
    const fee = { ...feeSchedule.find(f => f.type === type)! };
    fee.label = event.target.value;
    const newFees = [...feeSchedule.filter(f => f.type !== type), fee];

    setFeeSchedule(newFees);
  };

  //update the fee cost
  const handleFeeCostUpdate = (
    event: ChangeEvent<HTMLInputElement>,
    rowItem: FeeScheduleItem,
    feeCategory: FeeCategory,
    frequency: 'monthly' | 'yearly'
  ) => {
    const feeCategoryTotal =
      feeCategory === FeeCategory.Ours ? 'totalCostOurs' : 'totalCostCurrent';

    const feeCategoryMonthly =
      feeCategory === FeeCategory.Ours ? 'monthlyCostOurs' : 'monthlyCostCurrent';

    const fee = { ...feeSchedule.find(f => f.type === rowItem.type)! };

    //figured I needed to use .toFixed because of dividing back a yearly total into a monthly, which doesnt always round out well
    if (frequency !== 'yearly') {
      fee[feeCategoryMonthly] = Number(event.target.value);
      fee[feeCategoryTotal] = Number(
        ((Number(event.target.value) || 0) * (rowItem.customMultiplier || 12)).toFixed(2)
      );
    } else {
      fee[feeCategoryTotal] = Number(event.target.value);
      fee[feeCategoryMonthly] = Number(
        ((Number(event.target.value) || 0) / (rowItem.customMultiplier || 12)).toFixed(2)
      );
    }

    const newFees = [...feeSchedule.filter(f => f.type !== rowItem.type), fee];

    setFeeSchedule(newFees);
  };

  return (
    <TableRow tabIndex={-1} key={row.type}>
      <TableCell>
        {row.type === FeeType.TransactionFees ? null : (
          <Button onClick={() => removeFee(row.type)} variant="subtle">
            <Delete fontSize='small' />
          </Button>
        )}
      </TableCell>
      <TableCell>
        {row.type === FeeType.TransactionFees ? (
          row.label
        ) : (
          <TextField
            fullWidth
            value={row.label}
            onChange={event => handleFeeNameUpdate(event as ChangeEvent<HTMLInputElement>, row.type)}
          />
        )}
      </TableCell>
      <TableCell align="center">
        <TextField
          placeholder="0"
          value={row.monthlyCostCurrent}
          onChange={event =>
            handleFeeCostUpdate(
              event as ChangeEvent<HTMLInputElement>,
              row,
              FeeCategory.Current,
              'monthly'
            )
          }
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          _component={CustomCurrencyInputMasker as any}
        />
      </TableCell>
      <TableCell align="center">
        <TextField
          placeholder="0"
          value={row.totalCostCurrent}
          onChange={event =>
            handleFeeCostUpdate(
              event as ChangeEvent<HTMLInputElement>,
              row,
              FeeCategory.Current,
              'yearly'
            )
          }
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          _component={CustomCurrencyInputMasker as any}
        />
      </TableCell>

      <TableCell align="center">
        <TextField
          placeholder="0"
          value={row.monthlyCostOurs}
          onChange={event =>
            handleFeeCostUpdate(
              event as ChangeEvent<HTMLInputElement>,
              row,
              FeeCategory.Ours,
              'monthly'
            )
          }
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          _component={CustomCurrencyInputMasker as any}
        />
      </TableCell>
      <TableCell align="center">
        <TextField
          placeholder="0"
          value={row.totalCostOurs}
          onChange={event =>
            handleFeeCostUpdate(
              event as ChangeEvent<HTMLInputElement>,
              row,
              FeeCategory.Ours,
              'yearly'
            )
          }
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          _component={CustomCurrencyInputMasker as any}
        />
      </TableCell>
    </TableRow>
  );
};

const VariableFeeRowComponent = React.memo(VariableFeeRow);
