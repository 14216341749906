import React, { useState, useContext } from 'react';
import {
  Grid,
  DialogContentText,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from '@mui/material';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Datepicker, TextField } from 'shared/components';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isValid } from 'date-fns';
import { Agent, Activity, ActivityType, ActivityTypeValues } from '../../../state';
import { ThemeColors } from '../../../shared/theme';
import { CalendarDownload } from 'shared/components/activity-history/calendar-download';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSave: (model: Activity) => void;
  handleDelete: (id: number) => void;
  id: number | null;
  type: ActivityType;
  recordedAt: string | null;
  notes: string | null;
  setType: React.Dispatch<React.SetStateAction<ActivityType>>;
  setRecordedAt: React.Dispatch<React.SetStateAction<string>>;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  readOnly: boolean;
}

const useStyles = makeStyles(() => ({
  smallIcon: {
    fontSize: '18px',
  },
  warningText: {
    color: ThemeColors.Magenta,
  },
  deleteText: {
    color: ThemeColors.Magenta,
    fontSize: '80%',
  },
}));

export const AddEditActivityDialog: React.FC<Props> = ({
  open,
  handleClose,
  handleSave,
  handleDelete,
  id,
  type,
  recordedAt,
  notes,
  setType,
  setRecordedAt,
  setNotes,
  readOnly,
}) => {
  const styles = useStyles();
  const [warningMessage, setWarningMessage] = useState('');
  const [deleteSelected, setDeleteSelected] = useState(false);
  const agentContext = useContext(ActiveAgentContext);

  const cancel = () => {
    setWarningMessage('');
    setDeleteSelected(false);
    if (!deleteSelected) {
      handleClose();
    }
  };

  const deleteTapped = () => {
    setWarningMessage('Are you sure you want to delete this activity?');
    setDeleteSelected(true);
  };

  const tryComplete = () => {
    if (deleteSelected) {
      handleDelete(id!);
    } else {
      if (type == null) {
        setWarningMessage('Please select a type');
        return;
      }
      if (recordedAt == null || !isValid(new Date(recordedAt))) {
        setWarningMessage('Please select a date');
        return;
      }
      handleSave({
        id: id ?? undefined,
        type,
        recordedAt,
        notes,
      });
    }
    setDeleteSelected(false);
    setWarningMessage('');
  };

  const cancelButtonText = () => {
    return readOnly ? 'Close' : 'Cancel';
  };

  const titleText = () => {
    return readOnly ? '' : deleteSelected ? 'Delete' : id == null ? 'Add' : 'Edit';
  };

  return (
    <Dialog isOpen={open} onClose={cancel} fullWidth>
      <DialogTitle>
        {titleText()} Activity
      </DialogTitle>
      <DialogContent>
        {warningMessage ? (
          <DialogContentText className={styles.warningText}>{warningMessage}</DialogContentText>
        ) : null}
        {deleteSelected ? null : (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormControl fullWidth={true} variant="outlined" style={{marginTop: '7px'}}>
                <InputLabel
                  variant="outlined"
                  id="type-label"
                >
                  Type
                </InputLabel>
                <Select
                  labelId="type-label"
                  label="Type"
                  value={type}
                  onChange={event => setType(event.target.value as ActivityType)}

                >
                  {Object.values(ActivityTypeValues).map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Datepicker
                  label="Date"
                  format="MM/dd/yyyy"
                  readOnly={readOnly}
                  value={recordedAt ? new Date(recordedAt) : null}
                  onChange={(event?: Date) => {
                    if (event === undefined) {
                      setRecordedAt('');
                      return;
                    }

                    setRecordedAt(event.toISOString());
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item>
              <TextField
                id="Notes"
                placeholder="Notes"
                label="Notes"
                fullWidth
                type="textarea"
                rows={1}
                maxRows={4}
                value={notes ?? ''}
                disabled={readOnly}
                onChange={event => setNotes(event.target.value)}
              />
            </Grid>
            <Grid item>
              <CalendarDownload
                type={type}
                recordedAt={recordedAt}
                notes={notes}
                agent={agentContext.agent}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container gap={2} m={2}>
          {!readOnly && (
            <Grid item>
              <Button onClick={() => tryComplete()}>{deleteSelected ? 'Delete' : 'Save'}</Button>
            </Grid>
          )}
          {deleteSelected || id == null || readOnly ? null : (
            <Button variant="secondary" onClick={() => deleteTapped()}>Delete</Button>
          )}
          <Grid item>
            <Button variant="secondary" onClick={cancel}>
              {cancelButtonText()}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export const ActiveAgentContext = React.createContext({ agent: null as unknown as Agent });
