import { Store, StoreConfig } from '@datorama/akita';
import { CompanyModel } from 'api/app';

import {AuthState, EnterpriseInfo, UserInfo} from './session.models';

export interface SessionState {
  staySignedIn: boolean;
  auth: AuthState;
  user?: UserInfo;
  isActiveUser?: boolean;
  isKvCoreUser?: boolean;
  companyData?: CompanyModel;
  isImpersonating?: boolean;
  enterpriseInfo?: EnterpriseInfo;
}

const initialState: SessionState = {
  staySignedIn: false,
  auth: {
    isImpersonating: false,
    authenticated: false,
  },
};

@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(initialState);
  }
}

export const sessionStore = new SessionStore();
