import React, { useEffect, useState } from "react";
import { EditDisclaimerDialog, FeedDisclaimersTable } from "admin/components";
import { useFeeds } from "admin/hooks";
import { FeedModel } from "api/mls";
import { Button, DataTableColumn, icons, PageHeader } from "shared";
import { feedsService } from "state";
import {Typography} from "@mui/material";

export const FeedDisclaimersPage: React.FC = () => {

  const {feeds, loading} = useFeeds();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState<FeedModel | null>(null);
  const [disclaimer, setDisclaimer] = useState<string>("");
  const [tableData, setTableData] = useState<FeedModel[]>(feeds);

  useEffect(() => {
    setTableData(feeds);
  }, [feeds]);

  const editDialog = (feed: FeedModel) => {
    setSelectedFeed(feed);
    setDisclaimer(feed.disclaimer ?? "");
    setOpenDialog(true);
  };

  const onSubmit = () => {
    if (!selectedFeed) return;
    let newFeed = {...selectedFeed, disclaimer: disclaimer};
    feedsService.updateFeed(selectedFeed.id, newFeed);
    const index = tableData.findIndex(f => f.id === selectedFeed.id);
    let newArray = [...tableData];
    newArray.splice(index, 1, newFeed);
    setTableData(newArray);
    setSelectedFeed(null);
    setDisclaimer("");
    setOpenDialog(false);
  };

  const truncate = (value: string | null | undefined) => {
    if (!value || value.length === 0) {
      return "N/A";
    }
    if (value && value.length > 125) {
      return value.substring(0, 125) + "...";
    }
    return value;
  }

  const columns: DataTableColumn<FeedModel>[] = [
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">MLS FEED</Typography>,
      headerAlign: 'left',
      align: 'left',
      field: 'name',
      sortable: true,
      filterable: true,
      flex: 0.5,
      valueGetter: (value, row) => row.name
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">DISCLAIMER</Typography>,
      headerAlign: 'center',
      align: 'left',
      field: 'disclaimer',
      renderCell: ({row}) => truncate(row.disclaimer),
      sortable: false,
      filterable: true,
      flex: 2,
      valueGetter: (value, row) => row.name
    },
    {
      headerName: '',
      field: 'edit',
      sortable: false,
      filterable: false,
      flex: 0.5,
      renderCell: ({row}) => (
        <Button onClick={() => editDialog(row)} variant="secondary">Edit</Button>
      )
    }
  ];

  return (
    <>
      <PageHeader
        title="FEED DISCLAIMERS"
        icon={icons.admin}
      />
      <EditDisclaimerDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={onSubmit}
        feed={selectedFeed}
        disclaimer={disclaimer}
        setDisclaimer={setDisclaimer}
      />
      <FeedDisclaimersTable
        tableData={tableData}
        columns={columns}
        loading={loading}
      />
    </>
  );
};
