import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faSquarePollVertical,
  faUserGroup,
  faScrewdriverWrench,
  faGear,
  faCalculator,
  faCircleQuestion,
  faFile,
  faChevronRight,
  faEnvelope,
  faMagnifyingGlass,
  faCloudArrowDown,
  faPlus,
  faBuilding,
  faHandshake,
  faTrash,
  faUsd,
  faChartPie,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFilters
} from '@fortawesome/pro-solid-svg-icons';

export const icons = {
  poll: faSquarePollVertical as IconProp,
  dollar: faUsd as IconProp,
  users: faUserGroup as IconProp,
  tools: faScrewdriverWrench as IconProp,
  admin: faGear as IconProp,
  calculator: faCalculator as IconProp,
  question: faCircleQuestion as IconProp,
  file: faFile as IconProp,
  right: faChevronRight as IconProp,
  email: faEnvelope as IconProp,
  searchGlass: faMagnifyingGlass as IconProp,
  download: faCloudArrowDown as IconProp,
  plus: faPlus as IconProp,
  office: faBuilding as IconProp,
  cobroke: faHandshake as IconProp,
  trash: faTrash as IconProp,
  filter: faFilters as IconProp,
  pieChart: faChartPie as IconProp
};
