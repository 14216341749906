import { ExternalIdSourceType } from "api/app";

export interface GetAdoptionStatsRequest {
  source: ExternalIdSourceType,
  orderBy: string,
  isDescending: boolean,
  pageNum: number,
  pageSize: number
}

export enum OrderBy {
  Sid = "Sid",
  CompanyName = "CompanyName",
  TotalSeats = "TotalSeats",
  TotalUsedSeats = "TotalUsedSeats",
  TotalLogins = "TotalLogins",
  RecruitsWatched = "RecruitsWatched",
  RecruitsWatched30Days = "RecruitsWatched30Days",
  LastLoginDate = "LastLoginDate",
  ExternalIdGroup = "ExternalIdGroup"
}

export const initialPaginationState = {
  source: ExternalIdSourceType.Remax,
  orderBy: OrderBy.CompanyName,
  isDescending: true,
  pageNum: 0,
  pageSize: 10,
}
