import { useHistory, useLocation } from 'react-router-dom';
import { PageHeader, LoadingFade, icons } from '../../shared';
import { GetSearchOptionsResponse, OfficeModel, SearchType } from '../../api/mls';
import { useSearchOptions } from '../../state/search/search.hooks';
import { useEffect, useState } from 'react';
import { reportTypes } from 'state/search/reportType';
import { ResultType } from 'state/search';
import { RetentionSearch } from 'retention/components';
import { useOffices } from 'retention/hooks';
import { locationStore } from 'state';

export const HotListPage = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    data,
    isLoading,
    isRefetching,
    isSuccess,
    error,
    reportType,
    setReportType,
    setResultType,
    searchForm,
    setSearchForm,
  } = useSearchOptions();

  const [filterOptions, setFilterOptions] = useState<GetSearchOptionsResponse | undefined>(data);

  const {companies, offices} = useOffices();

  useEffect(() => {
    const brokerOptions = data?.brokers.map(o => o.officeKey);
    const brokers = companies.filter(o => brokerOptions?.includes(o.codes[0]))
      .map(c => {
        return {
          officeKey: c.codes[0],
          officeNumber: c.officeNumber,
          officeName: c.name
        } as OfficeModel
      });

    const branchOptions = data?.branches.map(o => o.officeKey);
    const branches = offices.filter(o => branchOptions?.includes(o.codes[0]))
      .map(o => {
        return {
          officeKey: o.codes[0],
          officeNumber: o.officeNumber,
          officeName: o.name,
        } as OfficeModel
      });

    setFilterOptions({...data, brokers: brokers, branches: branches} as GetSearchOptionsResponse);
  }, [offices, companies, data]);

  const submit = () => {
    locationStore.reset();
    locationStore.update({previousPath: location.pathname})
    history.push('/search/results', location.state);
  };

  useEffect(() => {
    setReportType(reportTypes.find(r => r.type === SearchType.HotList)!);
    setResultType(ResultType.Agent);
  }, [setReportType, setResultType]);

  return (
    <>
      <PageHeader title="HOT LIST - MY COMPANIES" icon={icons.dollar} />
      <LoadingFade
        isContentLoading={isLoading}
        content={() => (
          <>
            <RetentionSearch
              searchForm={searchForm}
              setForm={form => setSearchForm(form, undefined, true)}
              activeFilterOptions={filterOptions}
              reportType={reportType}
              onSubmit={submit}
              onResetFeedback={() => {}}
              error={error as any}
              success={isSuccess}
              pending={isRefetching}
            />
          </>
        )}
      />
    </>
  );
};
