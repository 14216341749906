import React from 'react';
import {icons, PageHeader } from 'shared';
import { EnterpriseAdoptionList } from '../components';

export const EnterpriseAdoptionReportPage: React.FC = () => {
  return (
    <>
      <PageHeader
        title="ENTERPRISE ADOPTION REPORT"
        icon={icons.pieChart}
      />
      <EnterpriseAdoptionList />
    </>
  );
}
