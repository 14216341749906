import { AgentSurveyAverage } from "api/app";

export enum OrderBy {
  MemberName = "MemberName",
  MemberEmail = "MemberEmail",
  MemberOffice = "MemberOffice",
  Average = "AverageRatio",
  Count = "Count",
}

export type AgentModel = AgentSurveyAverage;

export const initialPaginationState = {
  pageNum: 0,
  pageSize: 10,
  orderBy: OrderBy.Average,
  isDescending: true,
  memberKeys: [],
  officeKeys: []
}
