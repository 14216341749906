
				var addMethods = require("../../../../node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = ["createVolumeSummary","createVolumeSummaryByAgent","createVolumeSummaryByGroup"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "708a8a941dde33c4f175.worker.js", { name: "[fullhash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "708a8a941dde33c4f175.worker.js");
					addMethods(w, methods)
					
					return w
				}
			