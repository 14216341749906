import { ExternalIdSourceType } from 'api/app';
import { JwtToken, UserInfo as ApiUserInfo } from 'api/auth/auth-api';

export interface IAuthToken {
  accessToken: string;
  refreshToken?: string;
}

export interface Authenticated {
  authenticated: true;
  isImpersonating: boolean;
  token: IAuthToken;
}

export interface Unauthenticated {
  authenticated: false;
  isImpersonating: false;
}

export interface EnterpriseInfo {
  companyExternalId: string | null | undefined;
  companyGroup: string | null | undefined;
  externalIdSource: ExternalIdSourceType | null | undefined;
  userExternalId: string | null | undefined;
}

export type AuthState = Unauthenticated | Authenticated;

export type UserInfo = ApiUserInfo;

export function mapAuthState(token?: JwtToken): AuthState {
  if (!token || !token.access_token || token.access_token === "") {
    return {
      authenticated: false,
      isImpersonating: false,
    };
  }

  return {
    authenticated: true,
    isImpersonating: token.is_impersonating ?? false,
    token: {
      accessToken: token.access_token,
      refreshToken: token.refresh_token,
    },
  };
}
