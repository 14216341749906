import React, { useState } from 'react';

import {
  Card,
  Grid,
  CardHeader,
  CardContent, Typography,
} from '@mui/material';
import { Button } from 'shared/components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../shared';
import { searchService, CompanySearchResult, ResultType } from '../../state/search';
import { SortInput } from '../components/sort-input';
import {
  Header,
  ResultsList,
  ResultContainerProps,
  GroupHeader,
  GroupColumns,
} from './results-list';
import { DrillDownLink } from './drilldown-link';
import { CompanyVolumeSummaryTooltip } from '../../shared/components/company-volume-summary-tooltip';
import { useCompanyResults } from '../../state/search/search.hooks';
import { DataTableColumn } from 'shared/components/data-table'

export const CompanyResults: React.FC<ResultContainerProps> = ({
  searchForm,
  onSearch,
  isKvCoreUser,
}) => {
  const {
    data,
    isFetching,
    sortField,
    sortOrder,
    setSortOrder,
    page,
    setPage: setCurrentPage,
    pageSize: perPage,
    setPageSize: setPerPage,
  } = useCompanyResults();

  const [loading, setLoading] = useState(false);

  const handleExportClick = async () => {
    setLoading(true);

    try {
      await searchService.exportCompanies(searchForm, sortField, sortOrder);

      // Handle successful download or further actions here
    } catch (error) {
      // Handle error during download here
    }

    setLoading(false);
  };

  if (!data) return null;

  const { data: rows, total } = data;

  const columns: DataTableColumn<CompanySearchResult>[] = [
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">RANK</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'rank',
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COMPANY NAME</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      field: 'companyName',
      renderCell: ({ row }) => (
        <DrillDownLink
          onSearch={onSearch}
          formKey="broker"
          formValue={row.companyKey}
          resultType={ResultType.Office}
          activeStatus={null}
        >
          {row.companyName}
        </DrillDownLink>
      ),
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COMPANY</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'companyNumber',
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">ADDRESS</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'address',
      valueGetter: (value, row) => CompanyAddress(row)
    },
  ];

  return (
    <Card>
      <CardHeader
        title="Companies"
        action={
          <Grid container spacing={2}>
            <Grid item>
              <SortInput
                sortField={sortField}
                sortOrder={sortOrder}
                resultType={ResultType.Company}
                onChange={(newSortField, newSortOrder) => {
                  setSortOrder(newSortField, newSortOrder);
                }}
              />
            </Grid>

            <Grid item>
              <Button
                onClick={handleExportClick}
                pending={loading}
                startIcon={<FontAwesomeIcon icon={icons.download} size="lg" />}
              >
                Export Results
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <ResultsList
          loading={isFetching}
          getRowId={(row) => row.companyKey}
          columns={columns}
          rows={rows}
          showPagination
          paginationMode="server"
          rowCount={total}
          paginationModel={{
            page: page,
            pageSize: perPage,
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          onPaginationModelChange={({page, pageSize}) => {
            setPerPage(pageSize)
            setCurrentPage(page)
          }}
          summary={{
            field: 'sumary',
            renderHeader: () => (
              <GroupHeader label="TREND" colspans={[12]}>
                <Header>3yr</Header>
              </GroupHeader>
            ),
            renderCell: ({ row }) => (
              <GroupColumns colspans={[12]}>
                <CompanyVolumeSummaryTooltip companyKey={row.companyKey} officeName={row.companyName} />
              </GroupColumns>
            ),
          }}
        />
      </CardContent>
    </Card>
  );
};

function CompanyAddress(office: CompanySearchResult) {
  if (!office.address) return 'N/A';
  return (office.address + ', ' + office.city + ', ' + office.state + ' ' + office.postalCode).trim();
}
