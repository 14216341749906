import { Query } from "@datorama/akita";
import { EnterpriseAdoptionReportState, enterpriseAdoptionReportStore } from "./enterprise-adoption.store";

export class EnterpriseAdoptionReportQuery extends Query<EnterpriseAdoptionReportState> {
  data = this.select('data');
  count = this.select('count');
  loading = this.selectLoading();
}

export const enterpriseAdoptionReportQuery = new EnterpriseAdoptionReportQuery(enterpriseAdoptionReportStore);
