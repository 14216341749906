import { useEffect } from "react";
import { useObservable } from "shared";
import {officeQuery, officeService } from "state";

export function useOffices() {
  useEffect(() => {
    officeService.getOffices();
  }, []);

  return {
    companies: useObservable(officeQuery.brokerOffices),
    offices: useObservable(officeQuery.branchOffices)
  };
}
