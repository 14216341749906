import { StoreConfig } from '@datorama/akita';
import { ResettableStore } from 'state/util';

export interface LocationState {
  previousPath: string;
}

const initialState: LocationState = {
  previousPath: ""
};

@StoreConfig({ name: 'location', resettable: true })
export class LocationStore extends ResettableStore<LocationState> {
  constructor() {
    super(initialState);
  }
}

export const locationStore = new LocationStore();
