import React, { useState, useEffect, useCallback } from 'react';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import { dateFormat } from '../../state/search';
import { format, addDays, startOfToday } from 'date-fns';
import { Datepicker } from 'shared/components/datepicker';
import { Typography } from 'shared/components';
import { ThemeColors } from '../../shared';

export enum QuickFilter {
  Last7,
  Last30,
  Last60,
  Custom,
}

interface DateRangeProps {
  title?: string;
  enabledFilters: QuickFilter[];
  disabled?: boolean;
  defaultFilter?: QuickFilter;
  defaultStart?: string;
  defaultEnd?: string;
  setDates(
    startDate: string | undefined,
    endDate: string | undefined,
    selectedFilter?: QuickFilter
  ): void;
}

export function FilterDateRange({
  title,
  enabledFilters,
  disabled = false,
  setDates,
  defaultFilter,
  defaultStart,
  defaultEnd,
}: DateRangeProps): React.ReactElement {
  const [filterSelection, setFilterSelection] = useState<null | QuickFilter>(defaultFilter ?? null);
  const [startDate, setStartDate] = useState<string | undefined>(defaultStart);
  const [endDate, setEndDate] = useState<string | undefined>(defaultEnd);

  const getLabel = (filter: QuickFilter): string => {
    if (filter === QuickFilter.Custom) return 'Custom';
    else if (filter === QuickFilter.Last60) return 'Last 60 Days';
    else if (filter === QuickFilter.Last30) return 'Last 30 Days';
    else if (filter === QuickFilter.Last7) return 'Last 7 Days';

    return '';
  };

  // adding setDates to deps will cause infinite render loop
  useEffect(() => {
    if (filterSelection !== null) setDates(startDate, endDate, filterSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDate,
    endDate,
    filterSelection,
  ]);

  const onSelection = useCallback((filter: QuickFilter): void => {
    setEndDate(format(startOfToday(), dateFormat));

    if (filter === QuickFilter.Custom) setStartDate(undefined);
    else if (filter === QuickFilter.Last7) {
      setStartDate(format(addDays(startOfToday(), -7), dateFormat));
    } else if (filter === QuickFilter.Last30) {
      setStartDate(format(addDays(startOfToday(), -30), dateFormat));
    } else if (filter === QuickFilter.Last60) {
      setStartDate(format(addDays(startOfToday(), -60), dateFormat));
    }
    setFilterSelection(filter);
  }, []);

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        {title ? (
          <Grid item>
            <Typography variant="subtitle" align="center">
              {title}
            </Typography>
          </Grid>
        ) : null}
        <Grid item md={12}>
          {enabledFilters.map(filter => (
            <React.Fragment key={filter}>
              <QuickFilterButton
                label={getLabel(filter)}
                filter={filter}
                active={filterSelection === filter}
                disabled={disabled}
                onSelection={onSelection}
              />
            </React.Fragment>
          ))}
        </Grid>
      </Grid>

      {filterSelection === QuickFilter.Custom ? (
        <Grid className="custom-date-picker" container spacing={2}>
          <Grid item md={12}></Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Datepicker
              format="MM/dd/yyyy"
              label="Start Date"
              value={startDate === undefined ? null : startDate}
              onChange={(date?: Date) => {
                if (date === undefined) {
                  setStartDate('');
                  return;
                }
                setStartDate(format(date, dateFormat));
              }}
              sx={{ width: '100%' }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Datepicker
              format="MM/dd/yyyy"
              label="End Date"
              value={endDate === undefined ? null : endDate}
              onChange={(date?: Date) => {
                if (date === undefined) {
                  setEndDate('');
                  return;
                }
                setEndDate(format(date, dateFormat));
              }}
              sx={{ width: '100%' }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

interface QuickFilterButtonProps {
  label: string;
  filter: QuickFilter;
  active: boolean;
  disabled?: boolean;
  onSelection(filter: QuickFilter): void;
}

const useQuickFilterButtonStyles = makeStyles(() => ({
  button: () => ({
    flexGrow: 1,
    fontWeight: 'bold',
    borderWidth: 3,
    fontSize: '8pt',
    height: 30,
    marginRight: 8,
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      color: 'white',
      backgroundColor: ThemeColors.DarkTeal,
    },
  }),
  active: () => ({
    borderColor: ThemeColors.DarkTeal,
    backgroundColor: 'white',
    '&:hover': {
      borderColor: 'white',
    },
  }),
}));

const QuickFilterButton: React.FC<QuickFilterButtonProps> = ({
  label,
  filter,
  active,
  disabled = false,
  onSelection,
}) => {
  const classes = useQuickFilterButtonStyles();

  const onClick = () => {
    onSelection(filter);
  };

  return (
    <Button
      className={classNames(classes.button, { [classes.active]: active })}
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
