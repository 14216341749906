import React from 'react';
import { Survey } from '../../state/surveys';
import { LoadingFade, PageHeader } from '../../shared';
import { UpdateSurveyActiveButton } from './update-survey-active-button';
import { SubmissionsList } from './submissions-list';
import { useQuestions, useSubmissions } from '../hooks';
import { Grid } from '@mui/material';
import { SurveyInfo } from './survey-info';
import { QuestionsList } from './questions-list';

interface Props {
  survey: Survey;
}

export function SurveyDetails({ survey }: Props) {
  const { questions, loading: questionsLoading } = useQuestions(survey.id!);
  const { submissions: unfilteredSubmissions, loading: submissionsLoading } = useSubmissions(
    survey.id!
  );

  const submissions = unfilteredSubmissions.filter(s => s.submissionDate);

  const getScoreAverage = () => {
    const totalPoints = submissions.reduce((a, b) => (b.totalPoints ? a + b.totalPoints : a), 0);
    const submissionsCompleted = submissions.filter(s => !!s.submissionDate);
    return totalPoints / submissionsCompleted.length;
  };

  return (
    <LoadingFade isContentLoading={submissionsLoading}>
      <PageHeader
        title={
          <Grid container direction="row">
            <Grid item marginRight=".5em">
              {survey.title}
            </Grid>
            <Grid item>
              <UpdateSurveyActiveButton surveyId={survey.id} active={survey.active} />
            </Grid>
          </Grid>
        }
        backButton
      />
      <Grid container direction="column" spacing={2}>
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <SurveyInfo
              survey={survey}
              possiblePoints={submissions[0]?.possiblePoints}
              totalPoints={getScoreAverage()}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            {survey.id ? (
              <QuestionsList questions={questions} loading={questionsLoading} />
            ) : undefined}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            {survey.id ? (
              <SubmissionsList submissions={submissions} loading={submissionsLoading} />
            ) : undefined}
          </Grid>
        </Grid>
      </Grid>
    </LoadingFade>
  );
}
