import { applyTransaction } from '@datorama/akita';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as Zsg from 'api/app';
import { mlsService, MlsService } from '../mls';
import { FavoritesStore, favoritesStore } from './favorites.store';

export class FavoritesService {
  constructor(
    private readonly store: FavoritesStore,
    private readonly favoritesApi: Zsg.ManagersApi,
    private readonly mls: MlsService
  ) {}

  getManagerFavorites(managerId: string) {
    from(this.favoritesApi.managersIdFavoritesGet(managerId))
      .pipe(
        map(({ data }) => data.data.map(m => ({ ...m, managerId: managerId }))),
        switchMap(favorites =>
          this.mls
            .loadAgentVolumes(
              favorites.map(f => f.memberKey),
              true
            )
            .pipe(map(() => favorites))
        )
      )
      .subscribe(favorites =>
        applyTransaction(() => {
          this.store.update(({ ui }) => ({ ui: { ...ui, managerId } }));
          this.store.upsertMany(favorites);
        })
      );
  }

  deleteFavorite(managerId: string, memberKey: string) {
    from(this.favoritesApi.managersIdFavoritesMemberKeyDelete(managerId, memberKey)).subscribe(() =>
      applyTransaction(() => {
        this.store.remove(memberKey);
      })
    );
  }

  createFavorite(managerId: string, memberKey: string) {
    from(this.favoritesApi.managersIdFavoritesMemberKeyPut(managerId, memberKey))
      .pipe(
        map(response => {
          return { ...response.data.data, managerId } || {};
        })
      )
      .subscribe(fav => {
        this.store.upsert(fav.memberKey, fav);
      });
  }
}

export const favoritesService = new FavoritesService(
  favoritesStore,
  new Zsg.ManagersApi(),
  mlsService
);
