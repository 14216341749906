import { useCallback, useEffect, useState } from 'react';
import { Card, Box, CardContent, Grid, LinearProgress } from '@mui/material';
import { format } from 'date-fns';
import { PageHeader, useObservable } from '../../shared';
import { ButtonLink, Typography } from 'shared/components';
import {
  FilterDisplay,
  OfficeResults,
  AgentResults,
  CompanyResults,
  OfficeInfo,
} from 'search/components';
import { ResultType } from '../../state/search';
import { useSearchOptions } from '../../state/search/search.hooks';
import { ResultContainerProps } from 'search/components/results-list';
import { useIsFetching } from 'react-query';
import { authService, locationQuery } from '../../state';

export const SearchResultsPage = () => {
  const { data: filterOptions, searchForm, setSearchForm, resultType } = useSearchOptions();
  const [kvCoreUser, setKvCoreUser] = useState(false);
  const isFetching = useIsFetching(['search', 'results']);

  const onSearch = useCallback<ResultContainerProps['onSearch']>(
    (form, resultType) => setSearchForm(form, resultType),
    [setSearchForm]
  );

  const { branches, brokers } = !!filterOptions ? filterOptions : { branches: [], brokers: [] };

  const { startDate, endDate } = searchForm;

  function formatDate(date?: string) {
    return date ? format(new Date(date), 'MMM d, yyyy') : null;
  }

  const previousPath = useObservable(locationQuery.previousPath);

  useEffect(() => {
    authService.kvCoreSync().subscribe((data: boolean) => {
      setKvCoreUser(data);
    });
  }, [kvCoreUser, setKvCoreUser]);

  return (
    <>
      <PageHeader title="Search Results" />
      <Card>
        {isFetching > 0 && <LinearProgress />}
        <CardContent>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Filters</Typography>
              {startDate ? (
                <>
                  From {formatDate(startDate)} To {formatDate(endDate)}
                </>
              ) : undefined}
            </Grid>

            <Grid item>
              <ButtonLink
                to={location => ({ pathname: previousPath, state: location.state })}
                variant="secondary"
              >
                <Typography variant="body">{previousPath?.includes('hot-list') ? 'Back To Hot List' : 'Back To Search Filters'}</Typography>
              </ButtonLink>
            </Grid>
            {!!branches && !!brokers && (
              <Grid item xs={12}>
                <FilterDisplay
                  form={searchForm}
                  offices={[...branches, ...brokers]}
                  onSearch={onSearch}
                ></FilterDisplay>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {searchForm.branch?.length === 1 && (
        <Box marginTop={2}>
          <OfficeInfo
            officeKey={searchForm.branch[0]}
            startDate={searchForm.startDate}
            endDate={searchForm.endDate}
          />
        </Box>
      )}

      <Box marginTop={2}>
        {resultType === ResultType.Agent && (
          <AgentResults searchForm={searchForm} onSearch={onSearch} isKvCoreUser={kvCoreUser} />
        )}
        {resultType === ResultType.Office && (
          <OfficeResults searchForm={searchForm} onSearch={onSearch} isKvCoreUser={kvCoreUser} />
        )}
        {resultType === ResultType.Company && (
          <CompanyResults searchForm={searchForm} onSearch={onSearch} isKvCoreUser={kvCoreUser} />
        )}
      </Box>
    </>
  );
};
