import { useEffect } from "react";
import { useObservable } from "shared";
import {sessionQuery, sessionService } from "state";

export function useEnterpriseInfo() {
  const userId = useObservable(sessionQuery.currentUser)?.sub;
  useEffect(() => {
    if (userId) {
      console.log("Getting enterprise info");
      sessionService.getUserEnterpriseInfo(userId);
    }
  }, [userId]);

  return useObservable(sessionQuery.enterpriseInfo);
}
