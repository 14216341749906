import { useState } from 'react';
import { SurveyAnswer, SurveyQuestion } from '../../state/surveys';
import { useForm } from '../../shared/forms';
import { Grid, Rating } from '@mui/material';
import { Button, Typography, TextField } from 'shared/components';
import { useQuestionStyles } from 'surveys/utils';

interface Props {
  questions: Array<SurveyQuestion>;
  onSubmit: (answers: Array<SurveyAnswer>) => void;
}

export function QuestionSubmissionList({ questions, onSubmit }: Props) {
  questions.sort((q, qTwo) => {
    if (!q.questionOrder || !qTwo.questionOrder) return 0;
    return q.questionOrder - qTwo.questionOrder;
  });

  const [answers, setAnswers] = useState<Array<SurveyAnswer>>([
    ...questions.map(q => {
      return q.questionTypeId !== 3
        ? {
            questionId: q.id || 0,
          }
        : {
            questionId: q.id || 0,
            answerText: 'false',
          };
    }),
  ]);

  const form = useForm(() => {
    onSubmit(answers);
  });

  const setAnswer = (answer: SurveyAnswer) => {
    const answerIndex = answers.findIndex(a => a.questionId === answer.questionId);
    let edit = answers;
    edit[answerIndex] = answer;
    setAnswers([...edit]);
  };

  return (
    <form {...form.bind}>
      {questions.map(q => (
        <QuestionRow
          question={q}
          answer={answers.find(a => a.questionId === q.id)!}
          setAnswer={setAnswer}
          key={q.id}
        />
      ))}
      <Grid item>
        <Button type="submit">
          Submit
        </Button>
      </Grid>
    </form>
  );
}

interface QuestionRowProps {
  question: SurveyQuestion;
  answer: SurveyAnswer;
  setAnswer: (answer: SurveyAnswer) => void;
}

function QuestionRow({ question, answer, setAnswer }: QuestionRowProps) {
  const styles = useQuestionStyles();

  return (
    <Grid container direction="row" className={styles.answerQuestionGrid}>
      <Grid item direction="column" width="100%" className={styles.childAnswerGrid}>
        <Grid sm={12}>
          <Typography>{question.questionText}</Typography>
        </Grid>
        <AnswerCell
          questionType={question.questionTypeId || 0}
          answer={answer}
          setAnswer={setAnswer}
        />
      </Grid>
    </Grid>
  );
}

interface AnswerCellProps {
  questionType: number;
  answer: SurveyAnswer;
  setAnswer: (answer: SurveyAnswer) => void;
}

function AnswerCell({ questionType, answer, setAnswer }: AnswerCellProps) {
  const setAnswerRating = (rating: number) => {
    setAnswer({
      ...answer,
      answerRating: rating,
    });
  };

  const setAnswerText = (text: string) => {
    setAnswer({
      ...answer,
      answerText: text,
    });
  };

  switch (questionType) {
    case 1:
      return (
        <Rating
          name={`${answer.questionId}`}
          value={answer.answerRating || 0}
          onChange={(_, newValue) => setAnswerRating(newValue || 0)}
        />
      );
    case 2:
      return (
        <TextField
          placeholder="Enter text here"
          name={`${answer.questionId}`}
          value={answer.answerText || ''}
          onChange={event => setAnswerText(event.target.value)}
          fullWidth
          required
          type="textarea"
        />
      );
    default:
      return <></>;
  }
}
