import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { Activity } from './activities.model';
import { DateRangeOptions } from '../mls/date-filter';

export interface ActivitiesState extends EntityState<Activity, Activity['id']>, ActiveState {
  ui: { recruitId: number | null; managerId: string | null; dateRange: DateRangeOptions };
}

@StoreConfig({ name: 'activities', resettable: true, idKey: 'id' })
export class ActivitiesStore extends EntityStore<ActivitiesState> {
  constructor() {
    super({ ui: { recruitId: null, managerId: null, dateRange: DateRangeOptions.Last365Days} });
  }
}

export const activitiesStore = new ActivitiesStore();
