import React, { useEffect } from 'react';
import { officeService, officeQuery } from '../../state';
import { FormControl, Select, MenuItem, Grid } from '@mui/material';
import { useObservable } from 'shared/utils/useObservable';

export const OfficePicker: React.FC = () => {
  useEffect(() => {
    officeService.getOffices();
  }, []);

  const selectedBrokerId = useObservable(officeQuery.selectedBrokerId);
  let selectedBranchId = useObservable(officeQuery.selectedBranchId);

  const brokers = useObservable(officeQuery.brokerOffices);
  const branches = useObservable(officeQuery.branchOffices);

  if (!selectedBranchId && branches.length === 1) {
    // if there is only one branch to select, default it as we've hidden the "All Offices" option
    selectedBranchId = branches[0].officeId;
  }

  useEffect(() => {
    if (brokers.length === 1) {
      // if there is only one broker to select, default it as we've hidden the "Brokers" option
      officeService.setActiveBroker(brokers[0].officeId);
    }
  }, [selectedBrokerId, brokers]);

  return (
    <Grid container spacing={1}>
      {brokers.length > 1 && (
        <Grid item>
          <FormControl>
            <Select
              variant="outlined"
              value={selectedBrokerId ?? 'All'}
              onChange={event => officeService.setActiveBroker(event.target.value as any)}
            >
              <MenuItem value="All">All Regions</MenuItem>
              {brokers.map(o => (
                <MenuItem key={o.officeId} value={o.officeId}>
                  {o.name} {!!o.officeNumber && <>({o.officeNumber})</>} - {o.address ? o.address + ', ' + o.city : 'N/A'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item>
        <FormControl>
          <Select
            variant="outlined"
            value={selectedBranchId ?? 'All'}
            onChange={event =>
              officeService.setActiveBranch(selectedBrokerId!, event.target.value as any)
            }
            disabled={!selectedBrokerId}
          >
            {branches.length !== 1 && <MenuItem value="All">All Offices</MenuItem>}
            {branches.map(o => (
              <MenuItem key={o.officeId} value={o.officeId}>
                {o.name} {!!o.officeNumber && <>({o.officeNumber})</>} - {o.address ? o.address + ', ' + o.city : 'N/A'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
