import React, { useState } from 'react';
import {
  IconButton,
  Popover,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Box,
} from '@mui/material';
import { Star } from '@mui/icons-material';
import { FavoriteAgent } from 'shared/components/favorite-agent';
import { ValueComparison } from 'shared/components/value-comparison';
import { Typography } from 'shared/components/typography';
import { useFavorites } from 'shared/utils/useFavorites';
import { Link } from '../components/link';
import {useCurrentUserId, useFavoriteVolumes } from '../utils';
import { withManagerId } from '../utils/withManagerId';
import {ClassNameMap} from "@mui/styles";
import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

interface Props {
  styles: ClassNameMap;
}

const Favorites: React.FC<Props> = ({styles}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const managerId = useCurrentUserId();
  const { favorites, toggleFavorite } = useFavorites(managerId);
  const volume = useFavoriteVolumes(managerId);

  const [unfavoriteQueue, updateUnfavoriteQueue] = useState<string[]>([]);
  const onToggleFavorite = (memberKey: string) => {
    if (unfavoriteQueue.includes(memberKey))
      updateUnfavoriteQueue(unfavoriteQueue.filter(m => m !== memberKey));
    else updateUnfavoriteQueue(unfavoriteQueue.concat(memberKey));
  };
  const isFavorite = (memberKey: string) => !unfavoriteQueue.includes(memberKey);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    updateUnfavoriteQueue([]);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    unfavoriteQueue.forEach(m => toggleFavorite(m));
    updateUnfavoriteQueue([]);
  };

  if (!toggleFavorite || !volume) return null;

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={event => handleClick(event as React.MouseEvent<HTMLButtonElement>)}
        size="small"
        className={styles.button}
      >
        <Star fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Box bgcolor={palette.neutralLight} style={{ width: '100%' }}>
              <Typography align="center" variant="h4">
                Favorites
              </Typography>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <List>
              {favorites
                .map(favorite => volume.get(favorite.memberKey))
                .filter(vol => !!vol)
                .map(vol => vol!)
                .map(({ agent, totalVolume }) => (
                  <React.Fragment key={agent.memberKey}>
                    <ListItem>
                      <ListItemIcon>
                        <FavoriteAgent
                          memberKey={agent.memberKey ?? ''}
                          isFavorite={isFavorite(agent.memberKey ?? '')}
                          toggleFavorite={onToggleFavorite}
                        />
                      </ListItemIcon>
                      <ListItemText primary={agent.fullName} secondary={agent.officeName} />
                      <ListItemSecondaryAction>
                        <ValueComparison {...totalVolume} colorCurrent align="right" />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider component="li" />
                  </React.Fragment>
                ))}
              {favorites.length <= 0 && (
                <ListItem>
                  <ListItemText
                    primary="You don't have any favorites"
                    secondary={
                      <>
                        {'You can '}

                        <Link
                          to="/search"
                          onClick={(e: React.SyntheticEvent) => {
                            handleClose();
                          }}
                        >
                          search
                        </Link>
                        {' for an Agent and add them to your favorites list'}
                      </>
                    }
                  />
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export const FavoritesMenu = withManagerId(Favorites);
