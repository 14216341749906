import { Query } from "@datorama/akita";
import { AgentSurveyReportState, agentSurveyReportStore } from "./agent-survey.store";

export class AgentSurveyReportQuery extends Query<AgentSurveyReportState> {
  agents = this.select('agents');
  total = this.select('total');
  filters = this.select('filters');
  loading = this.selectLoading();
}

export const agentSurveyReportQuery = new AgentSurveyReportQuery(agentSurveyReportStore);
