import { UsersApi } from '../../../api';
import { UsersStore, usersStore } from './users.store';
import { applyTransaction } from '@datorama/akita';
import {from} from "rxjs";
import {map} from "rxjs/operators";

export class UsersService {
  constructor(
    private readonly store: UsersStore,
    private readonly usersApi: UsersApi
  ) {}

  getUsers(filterValue?: string) {
    if (!filterValue || filterValue === '') {
      this.store.remove();
    } else {
      from(this.usersApi.usersListGet(filterValue))
      .pipe(map(x => x.data.data))
      .subscribe(users => {
        applyTransaction(() => {
          this.store.remove();
          if (users && users.length > 0) {
            this.store.upsertMany(users);
          }
        })
      });
    }
  }
}

export const usersService = new UsersService(usersStore, new UsersApi());
