import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import {HotListPage, OverviewPage } from './pages';

export const RetentionRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/agents`} exact component={OverviewPage} />
    <Route path={`${match.path}/hot-list`} exact component={HotListPage} />
    <Redirect to={match.path} />
  </Switch>
);
