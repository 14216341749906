import { ExternalIdSourceType } from 'api/app';
import { useEffect } from 'react';
import { RemaxCorporateGroups } from './constants';
import { useEnterpriseInfo } from './hooks';

declare global {
  interface Window {
    initEmbeddedMessaging: () => void;
    embeddedservice_bootstrap: {
      settings: any;
      init: (a: string, b: string, c: string, d: {scrt2URL: string}) => void;
      prechatAPI: {
        setHiddenPrechatFields: (a: {MCID: string | null | undefined}) => void;
      }
    };
  }
}

export default function RemaxChat() {
  const enterpriseInfo = useEnterpriseInfo();

  useEffect(() => {
    const setPreChatFields = () => {
      window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({MCID: enterpriseInfo?.userExternalId});
    }

    const initEmbeddedMessaging = () => {
      try {
        if (!window.embeddedservice_bootstrap) return;

        window.embeddedservice_bootstrap.settings.language = 'en_US';

        window.addEventListener("onEmbeddedMessagingReady", setPreChatFields);

        window.embeddedservice_bootstrap.init(
          '00D0b000000Cieh',
          'IRE_Chat',
          'https://remax.my.site.com/ESWIREChat1694022628525',
          {
            scrt2URL: 'https://remax.my.salesforce-scrt.com'
          }
        );
      } catch (err) {
        console.error('Error in initEmbeddedMessaging: ', err);
      }
    }

    window.initEmbeddedMessaging = initEmbeddedMessaging;

    const removeElements = () => {
      document.getElementById("embedded-messaging")?.remove();
      document.getElementById("embeddedMessagingSiteContextFrame")?.remove();
      document.getElementById("inert-script")?.remove();
      document.getElementById("embeddedMessagingFilePreviewFrame")?.remove();
      document.getElementById("remax-chat")?.remove();
      window.removeEventListener("onEmbeddedMessagingReady", setPreChatFields);
    };

    const loadChat = enterpriseInfo &&
      enterpriseInfo.userExternalId &&
      enterpriseInfo.externalIdSource === ExternalIdSourceType.Remax &&
      RemaxCorporateGroups.includes(enterpriseInfo.companyGroup ?? '');

    if (loadChat) {
      // don't load script if already exists in DOM
      if (!document.getElementById("remax-chat")) {
        let scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.id = 'remax-chat';
        scriptElement.src = 'https://remax.my.site.com/ESWIREChat1694022628525/assets/js/bootstrap.min.js';
        scriptElement.onload = () => {
          setTimeout(() => {
            window.initEmbeddedMessaging();
          }, 100);
        };
        scriptElement.onerror = (error) => {
          console.error('Error loading script:', error);
        };
        document.head.appendChild(scriptElement);
      }
    } else {
      removeElements();
    }

    return () => removeElements();

  }, [enterpriseInfo]);

  return null;
}
