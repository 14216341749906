import { applyTransaction, setLoading } from "@datorama/akita";
import {GetAgentSurveyAveragesRequest, ReportsApi } from "api/app";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import { saveAs } from 'file-saver';
import {AgentSurveyReportState, agentSurveyReportStore, AgentSurveyReportStore, initialState } from "./agent-survey.store";

export class AgentSurveyReportService {
  constructor(
    private readonly store: AgentSurveyReportStore,
    private readonly api: ReportsApi
  ) {}

  getAgentSurveyAverages(params: GetAgentSurveyAveragesRequest) {
    this.store.update(state => ({
      ...state,
      agents: initialState.agents,
      total: initialState.total
    }));

    from(this.api.reportsAgentSurveysPost(params))
      .pipe(
        setLoading(this.store),
        map(response => response.data)
      ).subscribe(data =>
        applyTransaction(() => {
          this.store.update({
            agents: data.data,
            total: data.total,
          });
        })
      );
  }

  getAgentSurveyReportFilters(startDate: string | undefined, endDate: string | undefined) {
    this.store.update(state => ({
      ...state,
      filters: initialState.filters
    }));

    from(this.api.reportsAgentSurveysFiltersGet(startDate, endDate))
      .pipe(
        setLoading(this.store),
        map(response => response.data)
      ).subscribe(data =>
      applyTransaction(() => {
        this.store.update({
          filters: {
            offices: data.offices,
            agents: data.agents
          }
        } as unknown as AgentSurveyReportState);
      })
    );
  }

  exportAgentSurveyReport(params: GetAgentSurveyAveragesRequest): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      from(
        this.api.reportsAgentSurveysExportPost(
          params,
          { responseType: 'blob' }
        )
      ).subscribe(
        response => {
          saveAs(response.data as any, 'AgentSurveyReport.csv');
          resolve(); // Resolve the promise when the download is complete
        },
        error => {
          reject(error); // Reject the promise if there's an error
        }
      );
    });
  }

}

export const agentSurveyReportService = new AgentSurveyReportService(
  agentSurveyReportStore,
  new ReportsApi()
);
