import React, {useCallback, useEffect } from 'react';
import {
  Grid,
  Card,
} from '@mui/material';
import { Button, Typography } from 'shared/components';
import { withStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FeedModel, OfficeModel, SalesChange } from '../../api/mls';
import {
  FormProps,
  useInput,
  useForm,
  ErrorDisplay,
  useBooleanInput,
} from '../../shared/forms';
import {
  SearchForm,
  FilterOptions,
  dateFormat,
  getOfficeDisplayName,
  emptyForm,
} from '../../state/search';
import { format, startOfToday, addMonths, addDays, addYears } from 'date-fns';
import { ReportType, Filter } from '../../state/search/reportType';
import { FilterGroup } from '../../search/components/filter-group';
import { Picker } from '../../search/components/filter-picker';
import { FilterHotList, HotListType } from '../../search/components/filter-hotlist';
import {
  startDate as startDateValidator,
  endDate as endDateValidator,
  feedSelected,
  required,
} from '../../shared/forms/validators';

interface Props extends FormProps<SearchForm> {
  searchForm: SearchForm;
  setForm(form: Partial<SearchForm>, reloadOptions?: boolean): void;
  activeFilterOptions?: FilterOptions;
  reportType: ReportType;
}

export const RetentionSearch: React.FC<Props> = ({
   searchForm,
   setForm,
   activeFilterOptions = {
     feeds: [],
     boards: [],
     brokers: [],
     branches: []
   },
   reportType,
   pending,
   error,
   onSubmit,
   onResetFeedback,
}) => {
  const {
    feeds,
    boards,
    brokers,
    branches,
  } = activeFilterOptions;

  const startDate = useInput(searchForm.startDate, {
    validators: [startDateValidator(searchForm.endDate)],
    onChange: value => setForm({ startDate: value }),
  });
  const endDate = useInput(searchForm.endDate, {
    validators: [endDateValidator(searchForm.startDate)],
    onChange: value => setForm({ endDate: value }),
  });

  const newlyLicensedStartDate = useInput(searchForm.newlyLicensedStartDate, {
    validators: [],
    onChange: value => setForm({ newlyLicensedStartDate: value }),
  });
  const newlyLicensedEndDate = useInput(searchForm.newlyLicensedEndDate, {
    validators: [],
    onChange: value => setForm({ newlyLicensedEndDate: value }),
  });

  const newlyMovedStartDate = useInput(searchForm.newlyMovedStartDate, {
    validators: [],
    onChange: value => setForm({ newlyMovedStartDate: value }),
  });
  const newlyMovedEndDate = useInput(searchForm.newlyMovedEndDate, {
    validators: [],
    onChange: value => setForm({ newlyMovedEndDate: value }),
  });

  const initialFeedsValue = (!searchForm?.feed || searchForm.feed.length <= 0) && feeds.length === 1
    ? feeds.map(f => String(f.id))
    : searchForm.feed;
  const feed = useInput(initialFeedsValue, {
    onChange: value => setForm({ feed: value }, true),
    validators: [feedSelected()],
  });

  const board = useInput(searchForm.board, {
    validators: [],
    onChange: value => setForm({ board: value }, true),
  });

  const broker = useInput(searchForm.broker, {
    validators: [required()],
    onChange: value => handleBrokerValueChange(value, searchForm.branch),
  });

  const branch = useInput(searchForm.branch, {
    validators: [],
    onChange: value => handleBrokerValueChange(searchForm.broker, value),
  });

  const hasActiveOrPendingListings = useBooleanInput(searchForm.hasActiveOrPendingListings, {
    validators: [],
    onChange: value => setForm({ hasActiveOrPendingListings: convertToBoolean(value) }),
  });

  const daysSinceLastClose = useInput(searchForm.daysSinceLastClose, {
    validators: [],
    onChange: value => setForm({ daysSinceLastClose: value }),
  });

  const percentageChange = useInput(searchForm.percentageChange, {
    validators: [],
    onChange: value => setForm({ percentageChange: value }),
  });

  const salesChange = useInput(searchForm.salesChange, {
    validators: [],
    onChange: value => setForm({ salesChange: value }),
  });

  const hotListType = useInput(searchForm.hotListType, {
    validators: [],
    onChange: value => setForm({ hotListType: value }),
  });

  const form = useForm(
    () => {
      if (!form.valid) return;

      onResetFeedback();
      onSubmit({
        startDate: startDate.value,
        endDate: endDate.value,
        newlyLicensedStartDate: newlyLicensedStartDate.value,
        newlyLicensedEndDate: newlyLicensedEndDate.value,
        newlyMovedStartDate: newlyMovedStartDate.value,
        newlyMovedEndDate: newlyMovedEndDate.value,
        feed: feed.value,
        board: board.value,
        broker: broker.value,
        branch: branch.value,
        hasActiveOrPendingListings: convertToBoolean(hasActiveOrPendingListings.value),
        daysSinceLastClose: daysSinceLastClose.value,
        percentageChange: percentageChange.value,
        salesChange: salesChange.value,
      });
    },
    startDate,
    endDate,
    newlyLicensedStartDate,
    newlyLicensedEndDate,
    newlyMovedStartDate,
    newlyMovedEndDate,
    feed,
    board,
    broker,
    branch,
    daysSinceLastClose,
    percentageChange,
    salesChange
  );
  const Picker = reportTypePicker(reportType);

  const reset = (reportTypeChanged: boolean) => {
    startDate.setValue(format(addDays(addYears(startOfToday(), -1), 1), dateFormat));
    endDate.setValue(format(startOfToday(), dateFormat));
    newlyLicensedStartDate.setValue(undefined);
    newlyLicensedEndDate.setValue(undefined);
    newlyMovedStartDate.setValue(undefined);
    newlyMovedEndDate.setValue(undefined);
    if (!reportTypeChanged) {
      feed.setValue(feeds.length === 1 ? feeds.map(f => String(f.id)) : []);
    }
    board.setValue([]);
    broker.setValue([]);
    branch.setValue([]);
    hotListType.setValue(undefined);
    hasActiveOrPendingListings.setValue(undefined);
    daysSinceLastClose.setValue(undefined);
    salesChange.setValue(undefined);
    percentageChange.setValue(undefined);
    newlyLicensedStartDate.setValue(undefined);
    newlyLicensedEndDate.setValue(undefined);
    newlyMovedStartDate.setValue(undefined);
    newlyMovedEndDate.setValue(undefined);
    setForm(emptyForm);
  };

  const hotListOnChange = useCallback((filterValue: HotListType | null) => {
    hasActiveOrPendingListings.setValue(undefined);
    daysSinceLastClose.setValue(undefined);
    salesChange.setValue(undefined);
    percentageChange.setValue(undefined);
    newlyLicensedStartDate.setValue(undefined);
    newlyLicensedEndDate.setValue(undefined);
    newlyMovedStartDate.setValue(undefined);
    newlyMovedEndDate.setValue(undefined);
    startDate.setValue(format(addDays(addYears(startOfToday(), -1), 1), dateFormat));
    hotListType.setValue(filterValue ?? undefined);

    switch (filterValue) {
      case HotListType.Inactive:
        hasActiveOrPendingListings.setValue(String(false));
        daysSinceLastClose.setValue(60);
        salesChange.setValue(SalesChange.Decrease);
        percentageChange.setValue(20);
        break;
      case HotListType.New:
        newlyLicensedStartDate.setValue(format(addMonths(startOfToday(), -12), dateFormat));
        newlyLicensedEndDate.setValue(format(addMonths(startOfToday(), -10), dateFormat));
        break;
      case HotListType.Moved:
        newlyMovedStartDate.setValue(format(addMonths(startOfToday(), -12), dateFormat));
        newlyMovedEndDate.setValue(format(addMonths(startOfToday(), -10), dateFormat));
        hasActiveOrPendingListings.setValue(String(true));
        salesChange.setValue(SalesChange.Decrease);
        percentageChange.setValue(10);
        break;
    }
  }, [
    daysSinceLastClose,
    hasActiveOrPendingListings,
    hotListType,
    newlyLicensedEndDate,
    newlyLicensedStartDate,
    newlyMovedEndDate,
    newlyMovedStartDate,
    percentageChange,
    salesChange,
    startDate
  ]);

  // set hot list initial values on initial render.
  // adding hotListOnChange to deps will cause infinite render loop
  useEffect(() => {
    hotListOnChange(HotListType.Inactive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBrokerValueChange = (
    brokerValue: string[] | undefined,
    branchValue: string[] | undefined
  ) => {
    const remainingOffices = branches
      .filter(o => branchValue?.includes(o.officeKey) ?? false)
      .filter(o => brokerValue?.includes(o.mainOfficeKey!) ?? false)
      .map(o => o.officeKey);
    setForm({ broker: brokerValue, branch: remainingOffices }, true);
  };

  const disablePicker = pending || feed.value?.length === 0;

  return (
    <form {...form.bind}>
      <ErrorDisplay error={error} />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Card style={{ padding: '0', marginBottom: '1rem' }}>
          <FilterGroup title="MLS / Board" filters={Filter.Board} reportType={reportType}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Picker
                  label="MLS Feed"
                  input={feed}
                  options={feeds}
                  optionValue={(o: FeedModel) => String(o.id)}
                  optionText={(o: FeedModel) => o.name}
                  error={feed.errors.length > 0}
                  errorText={feed.errors[0]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Picker label="MLS Board" input={board} options={boards} disabled={disablePicker}/>
              </Grid>
            </Grid>
          </FilterGroup>
          <FilterGroup
            title="Company Info"
            filters={[Filter.Company, Filter.Office]}
            reportType={reportType}
          >
            <Grid container spacing={2}>
              {reportType.filters.includes(Filter.Company) && (
                <Grid item xs={12} md={6}>
                  <Picker
                    label="Company"
                    input={broker}
                    options={brokers}
                    optionValue={(o: OfficeModel) => o.officeKey}
                    optionText={(o: OfficeModel) => getOfficeDisplayName(o)}
                    disabled={disablePicker}
                  />
                  {broker.value?.length === 0
                      && (
                          <Typography variant="caption">
                            Must select at least one company
                          </Typography>
                      )}
                </Grid>
              )}
              {reportType.filters.includes(Filter.Office) && (
                <Grid item xs={12} md={6}>
                  <Picker
                    label="Office"
                    input={branch}
                    options={branches}
                    optionValue={(o: OfficeModel) => o.officeKey}
                    optionText={(o: OfficeModel) => getOfficeDisplayName(o)}
                    disabled={branches.length === 0 || disablePicker}
                  />
                  {branches.length === 0
                    && feed.value
                    && feed.value.length > 0
                    && (
                      <Typography variant="caption">
                        Select a company to filter by its offices
                      </Typography>
                    )}
                </Grid>
              )}
            </Grid>
          </FilterGroup>
          <FilterGroup title="Hot List Options" filters={Filter.HotList} reportType={reportType}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FilterHotList
                  enabledFilters={[HotListType.Inactive, HotListType.New, HotListType.Moved]}
                  defaultFilter={HotListType.Inactive}
                  setHotList={hotListOnChange}
                />
              </Grid>
            </Grid>
          </FilterGroup>
          <Grid container p={4} spacing={2} justifyContent='flex-end'>
            <Grid item>
              <Button
                variant="secondary"
                onClick={() => reset(false)}
                type='button'
              >
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button disabled={!form.valid || disablePicker}>
                Search
              </Button>
            </Grid>
          </Grid>
        </Card>
      </LocalizationProvider>
    </form>
  );
};

const reportTypePicker = ({ color }: ReportType) =>
  withStyles({
    tag: {
      color: 'white',
      backgroundColor: color,
    },
  })(Picker);

function convertToBoolean(input: string | undefined): boolean | undefined {
  if (!input) return undefined;
  try {
    return JSON.parse(input.toLowerCase());
  } catch {
    return undefined;
  }
}
