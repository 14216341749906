import { useEffect } from 'react';
import { useActiveUserProfile } from './account/hooks';
//import { useCompany} from './admin/hooks';
import {
  useCurrentUser  ,
  useObservable
} from './shared';
import {
  companiesQuery,
  companiesService
} from './state/admin';

export default function Delighted() {
  const profile = useActiveUserProfile();
  const userDetails = useCurrentUser(); 
  
  useEffect(() => {
    if (userDetails)
    {
      companiesService.getCompany(userDetails.companyId!);
    }
  }, [userDetails]);

  const company = useObservable(companiesQuery.activeCompany);

  useEffect(() => {
    if (!profile) 
      return;
      
    if (!company)
      return;
    
    if (!userDetails)
      return;

    const now = new Date();
    const isoTimestamp = now.toISOString();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.delightedNps4.survey({ 
        email: userDetails.email, 
        createdAt: isoTimestamp,
        name: userDetails.name,
        properties: {
          company: company.name!,
          state: "",
          plan: "",
          role: profile.roles[0]
        }

       });
  }, [profile, userDetails, company]);
  return null;
}
