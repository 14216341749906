import { SurveyQuestion } from '../../state/surveys';
import { useQuestionTypes } from '../hooks';
import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { Button } from 'shared/components';
import { DataTable, DataTableColumn } from 'shared/components/data-table';
import { Link } from 'react-router-dom';

interface Props {
  questions: Array<SurveyQuestion>;
  loading: boolean;
}

export function QuestionsList({ questions, loading }: Props) {
  const { questionTypes, loading: questionTypesLoading } = useQuestionTypes();

  const columns: DataTableColumn<SurveyQuestion>[] = [
    {
      headerName: '#',
      field: 'questionOrder',
      sortable: true,
      flex: undefined,
    },
    {
      headerName: 'Question',
      field: 'questionText',
      sortable: true,
      flex: 2,
    },
    {
      headerName: 'Question Type',
      field: 'questionTypeId',
      renderCell: ({ row }) => questionTypes.find(qt => qt.id === row.questionTypeId)?.typeName,
      sortable: true,
      flex: 0.5,
    },
  ];

  return (
    <Card>
      <CardHeader
        title="Survey Questions"
        action={
          <Grid>
            <Link to={`/survey/update/${questions[0]?.surveyId}`}>
              <Button>Edit Survey</Button>
            </Link>
          </Grid>
        }
      />
      <CardContent>
        <DataTable
          columns={columns}
          rows={questions}
          progressPending={loading && questionTypesLoading}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'questionOrder',
                  sort: 'asc',
                },
              ],
            },
          }}
        />
      </CardContent>
    </Card>
  );
}
