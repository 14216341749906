import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAtom } from 'jotai';
import { ReportType, reportTypes } from './reportType';
import {
  AgentSearchResult,
  emptyForm,
  CompanySearchResult,
  OfficeSearchResult,
  ResultType,
  SearchForm,
  SearchResultType,
  SortField,
  SortFieldType,
  SortOrder,
  SortOrderType,
} from './search.model';
import {
  SalesVolume,
  SearchApi,
  SearchRequest,
  SearchSortField,
  SearchType,
} from '../../api/mls';
import { Page } from '../page';
import { atomHistory, REPLACE_STATE } from './history';
import { Mls } from '../../api';

interface SearchState {
  searchForm: SearchForm;
  sortField: SortFieldType;
  sortOrder: SortOrderType;
  resultType: ResultType;
  searchType: SearchType;
  pagination: PaginationState;
}

interface PaginationState {
  page: number;
  pageSize: number;
}

const initialState: SearchState = {
  sortField: SortField.TotalVolume,
  sortOrder: SortOrder.Descending,
  searchForm: emptyForm,
  resultType: ResultType.Agent,
  searchType: reportTypes[0].type,
  pagination: { page: 0, pageSize: 10 },
};

const cacheTime = 1000 * 60 * 60 * 3; // 3hr cache
const searchApi = new SearchApi();
const officeApi = new Mls.OfficesApi();

const searchAtom = atomHistory(initialState);

function useSearchState() {
  const history = useHistory();
  const [{ pagination, ...search }, setSearch] = useAtom(searchAtom(history));
  let { searchForm: currentForm } = search;
  const reportType = reportTypes.find(r => r.type === search.searchType)!;

  return {
    search,
    pagination,
    reportType,
    setSearchForm(searchForm: Partial<SearchForm>, resultType?: ResultType, replace?: boolean) {
      console.debug('SEARCH', 'setSearchForm', searchForm, resultType);
      const { resultType: priorType } = search;
      currentForm = { ...currentForm, ...searchForm };
      setSearch({
        ...search,
        ...{ [REPLACE_STATE]: replace },
        searchForm: currentForm,
        resultType: resultType ?? priorType,
        pagination: { ...pagination, page:0}
      });
    },

    setResultType(resultType: ResultType) {
      if (resultType === search.resultType) return; // no-op

      console.debug('SEARCH', 'setResultType', resultType);
      setSearch({
        ...search,
        resultType,
        sortOrder: SortOrder.Descending,
        sortField: SortField.TotalVolume,
        pagination: { ...pagination},
      });
    },

    setReportType(reportType: ReportType) {
      if (reportType.type === search.searchType) return; //no-op

      console.debug('SEARCH', 'setReportType', reportType);
      setSearch({
        ...search,
        searchType: reportType.type,
        pagination: { ...pagination},
      });
    },

    setSortOrder(sortField: SortFieldType, sortOrder: SortOrderType): void {
      if (sortField === search.sortField && sortOrder === search.sortOrder) return; //no-op

      console.debug('SEARCH', 'setSortOrder', sortField, sortOrder);
      setSearch({
        ...search,
        sortField: sortField,
        sortOrder: sortOrder,
        pagination: { ...pagination},
      });
    },

    setPage(pageNum: number, pageSize?: number): void {
      if (pageNum === pagination.page && (pageSize ?? pagination.pageSize) === pagination.pageSize)
        return; //no-op

      console.debug('SEARCH', 'setPage', pageNum, pageSize);
      setSearch({
        ...search,
        pagination: {
          page: pageNum,
          pageSize: pageSize ?? pagination.pageSize,
        },
      });
    },

    setPageSize(pageSize: number): void {
      if (pageSize === pagination.pageSize) return; //no-op

      console.debug('SEARCH', 'setPageSize', pageSize);
      setSearch({
        ...search,
        pagination: {
          page: 0,
          pageSize,
        },
      });
    },
  };
}

export function useSearchOptions() {
  const { search, pagination, setPage, setPageSize, ...rest } = useSearchState();
  const { searchForm } = search;
  const { data, ...query } = useQuery(
    ['search', 'options', searchForm],
    () => fetchOptions(searchForm),
    {
      keepPreviousData: true,
      cacheTime,
      staleTime: cacheTime,
    }
  );

  return { ...search, ...rest, ...query, data: data };
}

function useSearchResults<T>(queryFn: (state: SearchState) => Promise<Page<T>>) {
  const { search, pagination, ...rest } = useSearchState();

  const query = useQuery(
    ['search', 'results', search, pagination],
    () => queryFn({ pagination, ...search }),
    {
      keepPreviousData: true,
      cacheTime,
      staleTime: cacheTime,
    }
  );

  return {
    ...search,
    ...pagination,
    ...query,
    ...rest,
  };
}

export function useAgentResults() {
  return useSearchResults(fetchAgents);
}

export function useOfficeResults() {
  return useSearchResults(fetchOffices);
}

export function useCompanyResults() {
  return useSearchResults(fetchCompanies);
}

async function fetchOptions(searchForm: SearchForm) {
  const { data } = await searchApi.mlsSearchOptionsPost({ ...mapSearchForm(searchForm) });

  return data;
}

async function fetchAgents(state: SearchState): Promise<Page<AgentSearchResult>> {
  const request = mapSearchRequest(state);

  const { data } = await searchApi.mlsSearchAgentsPost(request);

  return mapSearchResponse(request, data);
}

async function fetchOffices(state: SearchState): Promise<Page<OfficeSearchResult>> {
  const request = mapSearchRequest(state);

  const { data } = await searchApi.mlsSearchOfficesPost(request);

  return mapSearchResponse(request, data);
}

async function fetchCompanies(state: SearchState): Promise<Page<CompanySearchResult>> {
  const request = mapSearchRequest(state);

  const { data } = await searchApi.mlsSearchCompaniesPost(request);

  return mapSearchResponse(request, data);
}

function mapSearchRequest({ pagination, ...search }: SearchState): SearchRequest {
  const { searchForm, sortField, sortOrder, searchType } = search;
  const { pageSize: limit, page: pageNum } = pagination;
  const { feed, ...rest } = mapSearchForm(searchForm);
  const offset = pageNum * limit;
  const salesVolume: SalesVolume = {
    percentChange: search.searchForm.percentageChange,
    salesChange: search.searchForm.salesChange,
  };

  return {
    sortField,
    sortOrder,
    searchType,
    limit,
    offset,
    salesVolume,
    feed,
    ...rest,
  };
}

function mapSearchResponse<T extends Omit<SearchResultType<{}>, 'rank'>>(
  request: SearchRequest,
  { data, total }: { data: T[]; total?: number }
): Page<SearchResultType<T>> {
  return {
    data: data.map((r, i) => ({ ...r, rank: 1 + (request.offset ?? 0) + i })),
    total: total ?? 0,
  };
}

function mapSearchForm(form: SearchForm) {
  const { feed, ...rest } = form;

  return { feed: feed?.map(Number), ...rest };
}

export function useOfficeDetails(
  officeKey: string,
  startDate: string | undefined,
  endDate: string | undefined
) {
  const { searchForm } = useSearchOptions();

  const { data: office } = useQuery(['search', 'office', officeKey], async () => {
    const { data } = await officeApi.mlsOfficesPost({ officeKeys: [officeKey] });
    return data.data[0];
  });

  const { data: volume } = useQuery(
    ['search', 'office', officeKey, { startDate, endDate }],
    async () => {
      const { data } = await searchApi.mlsSearchOfficesPost({
        sortField: SearchSortField.CurrentOffice,
        sortOrder: SortOrder.Ascending,
        limit: 1,
        offset: 0,
        startDate: startDate,
        endDate: endDate,
        ...mapSearchForm({ feed: searchForm.feed, branch: [officeKey] }),
      });
      return data.data[0];
    }
  );

  if (!office || !volume) return null;

  return { ...office, ...volume };
}
