import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import {GridPaginationModel} from '@mui/x-data-grid';
import {EnterpriseAdoptionStat, ExternalIdSourceType} from 'api/app';
import React, {useState} from 'react';
import {DataTableColumn, Typography, Button, DataTable, icons} from 'shared';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {
  GetAdoptionStatsRequest,
  OrderBy,
  initialPaginationState,
  enterpriseAdoptionReportService
} from '../../../state/reports/enterprise-adoption';
import {useAdoptionStats} from '../hooks';
import { format } from 'date-fns-tz';

export const EnterpriseAdoptionList: React.FC = () => {

  const [paginationState, setPaginationState] = useState<GetAdoptionStatsRequest>(initialPaginationState);
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const {data, count, loading} = useAdoptionStats(paginationState);


  const onPaginationStateChange = (model: GridPaginationModel) => {
    setPaginationState({
      ...paginationState,
      pageNum: model.page,
      pageSize: model.pageSize,
    } as GetAdoptionStatsRequest);
  }

  const onExport = async () => {
    setExportLoading(true);
    try {
      await enterpriseAdoptionReportService.exportEnterpriseAdoptionReport(paginationState);
    } catch (error) {
      console.log(error);
    }
    setExportLoading(false);
  };

  const columns: DataTableColumn<EnterpriseAdoptionStat>[] = [
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COMPANY SID</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'sid',
      renderCell: ({row}) => row.sid ?? 'N/A',
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.sid ?? 'N/A',
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COMPANY NAME</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'companyName',
      renderCell: ({row}) => row.companyName ?? 'N/A',
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.companyName ?? 'N/A',
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COMPANY GROUP</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'externalIdGroup',
      flex: 0.5,
      renderCell: ({row}) => row.externalIdGroup ?? 'N/A',
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.externalIdGroup ?? 'N/A',
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">TOTAL SEATS</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'totalSeats',
      renderCell: ({row}) => row.totalSeats,
      flex: 0.5,
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.totalSeats,
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">TOTAL USED SEATS</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'totalUsedSeats',
      renderCell: ({row}) => row.totalUsedSeats,
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.totalUsedSeats,
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">TOTAL LOGINS (30 DAYS)</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'totalLogins',
      renderCell: ({row}) => row.totalLogins,
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.totalLogins,
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">LAST LOGIN DATE</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'lastLoginDate',
      renderCell: ({row}) => row.lastLoginDate ? format(new Date(row.lastLoginDate), 'MM-dd-yyyy HH:mm:ss zzz') : 'N/A',
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.lastLoginDate ? new Date(row.lastLoginDate) : null,
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">RECRUITS WATCHED (ALL TIME)</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'recruitsWatched',
      renderCell: ({row}) => row.recruitsWatched,
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.recruitsWatched,
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">RECRUITS WATCHED (30 DAYS)</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'recruitsWatched30Days',
      renderCell: ({row}) => row.recruitsWatched30Days,
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.recruitsWatched30Days,
      type: 'number'
    },
  ]

  return (
    <Card>
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container direction="column">
            <Grid item style={{marginBottom: '10px'}}>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <FormControl variant="standard">
                    <InputLabel>Enterprise</InputLabel>
                    <Select
                      value={paginationState.source}
                      onChange={(event) =>
                        setPaginationState({
                          ...paginationState,
                          source: event.target.value as ExternalIdSourceType,
                        })
                      }
                    >
                      {Object.values(ExternalIdSourceType).map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard">
                    <InputLabel>Sort By</InputLabel>
                    <Select
                      value={paginationState.orderBy}
                      onChange={(event) =>
                        setPaginationState({
                          ...paginationState,
                          orderBy: event.target.value as OrderBy,
                        })
                      }
                    >
                      <MenuItem value={OrderBy.Sid}>Company SID</MenuItem>
                      <MenuItem value={OrderBy.CompanyName}>Company Name</MenuItem>
                      <MenuItem value={OrderBy.ExternalIdGroup}>Company Group</MenuItem>
                      <MenuItem value={OrderBy.TotalSeats}>Total Seats</MenuItem>
                      <MenuItem value={OrderBy.TotalUsedSeats}>Total Used Seats</MenuItem>
                      <MenuItem value={OrderBy.TotalLogins}>Total Logins (30 Days)</MenuItem>
                      <MenuItem value={OrderBy.LastLoginDate}>Latest Login Date</MenuItem>
                      <MenuItem value={OrderBy.RecruitsWatched}>Recruits Watched (All Time)</MenuItem>
                      <MenuItem value={OrderBy.RecruitsWatched30Days}>Recruits Watched (30 days)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard">
                    <InputLabel>Sort Order</InputLabel>
                    <Select
                      value={paginationState.isDescending}
                      onChange={(event) =>
                        setPaginationState({
                          ...paginationState,
                          isDescending: event.target.value === 'true',
                        })
                      }
                    >
                      <MenuItem value="false">Ascending</MenuItem>
                      <MenuItem value="true">Descending</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    onClick={onExport}
                    pending={exportLoading}
                    disabled={!paginationState.source}
                    startIcon={<FontAwesomeIcon icon={icons.download} size="lg"/>}
                  >
                    Export Results
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <DataTable
                getRowId={(row) => row.companyId!}
                rows={data}
                rowCount={count}
                columns={columns}
                progressPending={loading}
                showPagination
                noDataComponent={<Typography>Select an enterprise to load results.</Typography>}
                paginationMode="server"
                paginationModel={{
                  page: paginationState.pageNum,
                  pageSize: paginationState.pageSize
                } as GridPaginationModel}
                onPaginationModelChange={onPaginationStateChange}
                pageSizeOptions={[10, 25, 50, 100]}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </CardContent>
    </Card>
  );
}
