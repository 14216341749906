import React, {useEffect, useState } from 'react';
import {
  FormProps,
  ErrorDisplay,
  useForm,
  useInput,
  required,
} from '../../shared/forms';
import { EditUserForm as EditUserFormModel } from '../../state/users';
import { Checkbox, FormControlLabel, Grid, Box } from '@mui/material';
import { Button, Typography, TextField } from 'shared/components';
import { OfficeModel } from '../../api/app';
import { Picker } from '../../search/components/filter-picker';
import { useHistory } from 'react-router-dom';
import { SuccessDisplay } from '../../shared/forms/SuccessDisplay';
import { Alert } from 'shared/components/alert';
import { FormEventResponse } from '../../state';
import { ResetPasswordForm } from 'admin/components/reset-password.form';

interface Props extends FormProps<EditUserFormModel> {
  activeForm?: EditUserFormModel;
  offices: OfficeModel[];
  resetEvents?: FormEventResponse;
  handleResetPassword?: () => void;
}

export const EditUserForm: React.FC<Props> = ({
  activeForm,
  offices,
  pending,
  success,
  error,
  onSubmit,
  onResetFeedback,
  resetEvents,
  handleResetPassword,
}) => {
  const history = useHistory();

  const name = useInput<string>(activeForm?.name ?? '', { validators: [required()] });
  const email = useInput<string>(activeForm?.email ?? '', { validators: [required()] });
  const officeIds = useInput<string[] | undefined>(
    activeForm?.officeIds?.map(id => id.toString()) ?? undefined
  );
  const [owner, setOwner] = useState<boolean>(activeForm?.owner ?? false);
  const [disableForm, setDisableForm] = useState<boolean>(false);

  useEffect(() => {
    if (success && !activeForm) {
      setDisableForm(true);
    }
  }, [success, activeForm]);

  const form = useForm(
    () => {
      if (!form.valid) return;

      onResetFeedback();
      onSubmit({
        name: name.value,
        email: email.value,
        officeIds: officeIds.value?.map(id => parseInt(id)) ?? [],
        owner: owner,
      });
    },
    email,
    officeIds
  );

  const toggleOwner = (owner: boolean) => {
    setOwner(owner);
    if (!owner) officeIds.setValue([]);
    else officeIds.setValue(offices.map(o => o.officeId.toString()));
  };

  // Function generates display name for offices
  // Getting the office code would require a separate API request
  // and identifying offices by market region is sufficient
  // ex. 'Howard Hanna - Penfield, Victor'
  const getDisplayName = (o: OfficeModel) => {
    let display = `${o.name} (${o.officeNumber})`;
    /*
    Previously, we appended the cities to the name to determine which office was which
    Office Number is a better unique identifier
    if (!!o.cities && o.cities.length > 0) {
      display += ' - ';
      for (let i = 0; i < o.cities.length; i++) {
        display += o.cities[i];
        if (i < o.cities.length - 1) display += ', ';
      }
    }*/
    return display;
  };

  const back = () => {
    history.goBack();
  };

  return (
    <form {...form.bind}>
      {!!error ? (
        <Box marginBottom={4}>
          <ErrorDisplay error={error} />
        </Box>
      ) : null}

      {success ? (
        <Box marginBottom={4}>
          <SuccessDisplay
            display={!!success}
            successMessage={
              !!activeForm
                ? "The manager's data has been saved."
                : 'The manager has been created. An email has been sent to their address to log into their new account.'
            }
          />
        </Box>
      ) : null}

      <Grid container item xs={12} spacing={6}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{!!activeForm ? 'Edit' : 'Add'} User</Typography>
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <TextField name="email" {...email.bind} label="Email" fullWidth disabled={disableForm} />
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField name="name" {...name.bind} label="Name" fullWidth disabled={disableForm} />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={owner}
                    onChange={event => toggleOwner(event.target.checked)}
                    name="Owner"
                    color="primary"
                    disabled={disableForm}
                  />
                }
                label="Owner"
              />
            </Grid>

            <Grid item xs={12}>
              <Picker
                disabled={owner || disableForm}
                label="Offices"
                input={officeIds}
                options={offices}
                optionValue={(o: OfficeModel) => o.officeId.toString()}
                optionText={(o: OfficeModel) => getDisplayName(o)}
              />
            </Grid>

            <Grid item xs={12}>
              <Alert title="Note">
                <ol>
                  <li>
                    All users are automatically given access to the MLS feeds that their offices
                    belong to.
                  </li>
                  <li>
                    No need to select offices for Owners. They can see all offices in their company.
                  </li>
                </ol>
              </Alert>
            </Grid>
            <Grid item xs={12}>
              {!!activeForm && !!handleResetPassword && !!resetEvents && (
                <ResetPasswordForm
                  handleResetPassword={handleResetPassword}
                  status={resetEvents.status}
                  error={resetEvents.error}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item>
            <Button disabled={!form.valid || disableForm} pending={pending}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button type='button' onClick={() => back()} variant='secondary'>
              Back
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
