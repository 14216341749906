import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, createFilterOptions, Checkbox, Chip } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { FormControl as IFormControl } from '../../shared/forms';
import { TextField } from 'shared/components';
import {ExpandMore, Cancel} from "@mui/icons-material";
import themeService from 'theme/ThemeService';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
    },
    missing: {
      color: 'white',
      backgroundColor: 'red !important',
    },
  })
);

interface PickerProps<T = string> {
  label: string;
  input: IFormControl<string[] | undefined>;
  classes?: ComponentProps<typeof Autocomplete>['classes'];
  disabled?: boolean;
  onChange?: (value: string[]) => void;
  freeSolo?: boolean;
  options?: T[] | null | undefined;
  optionLimit?: number;
  optionValue?(item: T): string;
  optionText?(item: T): string;
  error?: boolean;
  errorText?: string | undefined;
}

function pascalWordBreaks(s: string) {
  return s.replace(/[a-z][A-Z]/g, m => `${m.slice(0, 1)} ${m.slice(1)}`);
}

const palette = themeService.getPalette();

export function Picker<T = string>({
  label,
  input,
  classes,
  disabled = false,
  onChange = () => {},
  optionLimit = 25,
  freeSolo = false,
  error = false,
  errorText,
  ...props
}: PickerProps<T>): React.ReactElement {
  const styles = useStyles();
  const [hasInput, setHasInput] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [optionMap, optionKeys] = useMemo(() => {
    let opts: [string, string][];

    const optionValue = props.optionValue ?? (x => String(x));
    const optionText = props.optionText ?? (x => pascalWordBreaks(String(x)));

    if (!props.options) {
      opts = [];
    } else {
      opts = props.options.map(value => [optionValue(value), optionText(value)]);
    }

    return [new Map<string, string>(opts), opts.map(([key]) => key)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options]);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    let inputValue = input.value ?? [];
    if (!freeSolo) {
      inputValue = inputValue.filter(o => optionKeys.includes(o));
    }
    setSelectedValues(inputValue);
  }, [optionKeys, input.value, freeSolo]);

  const updateInput = useCallback(
    (value: string[]) => {
      input.setValue(value);
      onChange(value);
    },
    [input, onChange]
  );

  let options: string[], noOptionsText: string | undefined;

  const onAutocompleteChange = (value: string[], reason: any) => {
    setSelectedValues(value);
    if (freeSolo) setInputValue('');
    if (
      !(hasInput || !optionLimit || optionKeys.length <= optionLimit) &&
      reason === 'remove-option'
    )
      updateInput(value);
  };

  const onBlur = () => {
    // todo: compare input.value array to selectedValues to determine if they are different
    // if different then call updateInput, otherwise do nothing
    updateInput(selectedValues ?? []);
  };

  const filterOptionsObject = createFilterOptions<string>({
    // https://material-ui.com/components/autocomplete/#custom-filter
    // set limit to 25 by default, which shows only the first 25 options that match
    limit: optionLimit,
  });

  if (hasInput || !optionLimit || optionKeys.length <= optionLimit) {
    options = optionKeys;
    noOptionsText = undefined;
  } else {
    options = [];
    noOptionsText = `Start typing to filter  ${optionKeys.length.toLocaleString()} items...`;
  }

  return (
    <Autocomplete
      fullWidth
      multiple
      disableCloseOnSelect
      value={selectedValues}
      onBlur={_ => onBlur()}
      onChange={(_, value, reason) => onAutocompleteChange(value, reason)}
      options={options}
      noOptionsText={noOptionsText}
      filterOptions={filterOptionsObject}
      getOptionLabel={(o: string) => optionMap.get(o) ?? o}
      classes={{ inputRoot: styles.input, ...classes }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={error}
          helperText={errorText}
          label={label}
          // sx={{
          //   '& .MuiInputBase-root': {
          //     minHeight: '40px',
          //     height: 'auto',
          //   },
          //   '& .MuiInputLabel-outlined': {
          //     transform: 'translate(14px, 12px) scale(1)',
          //     '&.MuiInputLabel-shrink': {
          //       transform: 'translate(14px, -6px) scale(0.75)',
          //     },
          //   },
          //   '& .MuiAutocomplete-inputRoot': {
          //     paddingTop: '4px !important',
          //     paddingBottom: '4px !important',
          //   },
          //   '& .MuiChip-root': {
          //     height: '24px',
          //   },
          // }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} /> {optionMap.get(option)}
        </li>
      )}
      disabled={disabled}
      style={disabled ? {backgroundColor: "#F2F2F2"} : undefined}
      freeSolo={freeSolo}
      autoSelect={freeSolo}
      inputValue={inputValue}
      onInputChange={(e, value, reason) => {
        if (e && e.type === 'blur') {
          setInputValue('');
        } else if (reason !== 'reset') {
          // will retain the text search and list after making a selection
          setInputValue(value);
          setHasInput(!!value);
        }
      }}
      renderTags={(value, getTagProps) =>
        value.map((o, index) => (
          <Chip
            classes={{
              root: !!props.options && !optionMap.has(o) ? styles.missing : undefined,
            }}
            label={optionMap.get(o) ?? o}
            {...getTagProps({ index })}
            onDelete={e => {
              getTagProps({ index }).onDelete(e);
              updateInput(value.filter(f => f !== o));
            }}
            style={{backgroundColor: palette.primary, color: palette.neutralLightest}}
            deleteIcon={
              <Cancel
                style={{
                  color: palette.neutralLightest,
                  maxHeight: '75%',
                }}
              />
            }
          />
        ))
      }
      popupIcon={<ExpandMore />}
    />
  );
}
