import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import {
  AgentHeader,
  AgentInfo,
  AgentTransactions,
  useAgentTransactions,
  useGetAgent,
} from '../components';
import {
  asErrorBoundry,
  useObservable,
  useFavorites,
  useAgentVolume,
  MarketStats,
} from '../../shared';
import { mlsQuery } from '../../state';
import { useRecruit } from 'recruiting/useRecruit';
import { withManagerId } from '../../shared/utils/withManagerId';
import { AgentVolumeSummaryAnnualTrends } from '../../shared/components/agent-volume-summary-annual-trends';
import { ActiveAgentContext } from '../../shared/components/activity-history';
import { OfficeModel } from 'api/app';

interface Props {
  managerId: string;
}

const AgentDashboard: React.FC<Props> = ({ managerId }) => {
  const { memberKey } = useParams<{ memberKey: string }>();
  useGetAgent(memberKey);
  const dateRange = useObservable(mlsQuery.activeDateRange);
  const { agent, office, transactions, volume } = useAgentTransactions(memberKey, dateRange);

  const { isFavorite, toggleFavorite } = useFavorites(managerId);
  const { isRecruit, isRecruited, toggleRecruit } = useRecruit(managerId);

  const summary = useAgentVolume(agent?.memberKey);

  const officeKey = agent?.officeKey;

  const agentOffice = useMemo(() => {
    if (!office) return undefined;
    return {
      officeKey: office.officeKey,
      officeNumber: office.officeNumber,
      address: office.address,
      city: office.city,
      state: office.state,
      postalCode: office.postalCode
    } as unknown as OfficeModel;
  }, [office]);

  if (!agent) return null;

  return (
    <React.Fragment>
      <ActiveAgentContext.Provider value={{ agent: agent }}>
        <AgentHeader
          dateRange={dateRange}
          isFavorite={isFavorite}
          toggleFavorite={toggleFavorite}
          isRecruit={isRecruit}
          isRecruited={isRecruited}
          toggleRecruit={toggleRecruit}
        />
        <Grid container direction="column" spacing={2}>
          <Grid container spacing={2} item>
            <Grid item xs={12} sm={4} md={3}>
              <AgentInfo office={agentOffice} />
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              {volume && <MarketStats label="Agent" volume={volume} />}
            </Grid>
          </Grid>
          <Grid container spacing={2} item>
            <Grid item xs={12}>
              {summary && <AgentVolumeSummaryAnnualTrends summary={summary} />}
            </Grid>
          </Grid>
          <Grid container spacing={2} item>
            <Grid item xs={12}>
              <AgentTransactions {...transactions} officeKey={officeKey} />
            </Grid>
          </Grid>
        </Grid>
      </ActiveAgentContext.Provider>
    </React.Fragment>
  );
};

export const AgentDashboardPage = withManagerId(asErrorBoundry(AgentDashboard));
